import React from "react";

const StatusBadge = ({ status }) => {
  return (
    <span
      className={`badge badge-lg ${status === "pending" && "badge-success"} ${
        status === "rejected" && "badge-danger"
      } ${status === "paid" && "badge-info"}  ${
        status === "accepted" && "badge-warning"
      }`}
    >
      {status}
    </span>
  );
};

export default StatusBadge;
