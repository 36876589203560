import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';

export interface TypePayslip {
  id: number;
  date_of_distribution: string;
  date_issued: string;
}

const Payslip: React.FC<TypePayslip> = ({
  id,
  date_of_distribution,
  date_issued,
}) => {
  return (
    <div>
      <span style={{ paddingInline: '8px' }}>
        <FontAwesomeIcon icon={faFilePdf} size="sm" />
      </span>
      Id:{id}, Distribution:
      {moment(date_of_distribution).format('DD MMMM YYYY')}, Issue:
      {moment(date_issued).format('DD MMMM YYYY')}
    </div>
  );
};

export default Payslip;
