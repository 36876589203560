import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
import {
  Button,
  Card,
  CardTitle,
  CardText,
  Container,
  Form,
  Row,
  Label,
  Col,
  Input,
  FormGroup,
} from 'reactstrap';

/**
 * @summary Render WeeklyPayRollForm component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const WeeklyPayRollForm = (props) => {
  const { handleInputChange, weeklyPayrollPayload, triedSubmitting } = props;

  return (
    <>
      <Container>
        <div>
          <Card body outline color="secondary">
            <CardTitle tag="h5">Weekly - day based</CardTitle>
            <CardText>
              <Form>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="payCycleName">Name</Label>
                      <Input
                        type="text"
                        bsSize="sm"
                        name="payCycleName"
                        id="payCycleName"
                        placeholder="Pay Cycle Name e.g. CompanyX - Weekly"
                        onChange={(e) => handleInputChange(e)}
                        invalid={
                          triedSubmitting &&
                          !weeklyPayrollPayload?.payCycleName?.trim()
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="exampleZip">Pay Day</Label>
                      <Input
                        type="select"
                        name="payDay"
                        bsSize="sm"
                        id="payDay"
                        onChange={(e) => handleInputChange(e)}
                        invalid={
                          triedSubmitting && !weeklyPayrollPayload?.payDay
                        }
                      >
                        <option value="" />
                        <option value="0">Monday</option>
                        <option value="1">Tuesday</option>
                        <option value="2">Wednesday</option>
                        <option value="3">Thursday</option>
                        <option value="4">Friday</option>
                        <option value="5">Saturday</option>
                        <option value="6">Sunday</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="advanceReconDate">Advance Recon Date</Label>
                      <Input
                        bsSize="sm"
                        type="number"
                        name="advanceReconDate"
                        id="advanceReconDate"
                        placeholder="advance recon date"
                        onChange={(e) => handleInputChange(e)}
                        invalid={
                          triedSubmitting &&
                          !weeklyPayrollPayload?.advanceReconDate
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Button size="sm" color="primary">
                        Save
                      </Button>{' '}
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardText>
          </Card>
          <br />
        </div>{' '}
        <br />
      </Container>
    </>
  );
};

WeeklyPayRollForm.defaultProps = {};

WeeklyPayRollForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
};

export default WeeklyPayRollForm;
