/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Table, Badge } from 'reactstrap';

import moment from 'moment';

import axios from 'axios';
import Pagination from 'components/Pagination';
import { environment } from '../../../enviroment/enviroment';
import AdvancePaymentFileUploadModal from './AdvancePaymentFileUploadModal';

/**
 * @summary AdvancePaymentFiles component
 *
 * @param {Props} props
 * @component
 */
const AdvancePaymentFiles = (props) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [advancePayments, setAdvancePayments] = useState([]);

  const [numberOfPages, setNumberOfPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const toggleUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  const getAdvancePayments = (pageNumber) => {
    const DEFAULT_PAGE_SIZE = 10;

    axios
      .get(
        `${environment.baseUrl}/advancepaymentfileprocessingjob?pagination=true&page_size=${DEFAULT_PAGE_SIZE}&page=${pageNumber}`,
      )
      .then((res) => {
        setAdvancePayments(res.data.results || res.data);
        setNumberOfPages(res.data.pages || 1);
        setCurrentPage(pageNumber);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const INITIAL_PAGE_NUMBER = 1;
    getAdvancePayments(INITIAL_PAGE_NUMBER);
  }, [showUploadModal]);

  return (
    <>
      {isLoading ? (
        <Row className="my-3">
          <Col className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <div className="header bg-primary pb-6">
            <Container fluid>
              <div className="header-body">
                <Row className="align-items-center py-4">
                  <Col lg="6">
                    <h6 className="h2 text-white d-inline-block mb-0">
                      Advance Payment Files
                    </h6>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container fluid>
            <div className="card mt--6">
              <div className="card-body d-flex justify-content-between">
                <div style={{ width: '200px' }} />
                <div>
                  <Button
                    color="secondary"
                    onClick={() => toggleUploadModal()}
                    className="btn btn-secondary"
                  >
                    Upload Advance Payment File
                  </Button>
                </div>
              </div>
            </div>
          </Container>
          <Container fluid>
            <div className="card">
              <div className="card-body">
                <Table striped>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>File Name</th>
                      <th>Uploaded By</th>
                      <th>Date Created</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!advancePayments &&
                      advancePayments.map((advancePayment) => {
                        return (
                          <tr key={advancePayment.id}>
                            <td>{advancePayment.id}</td>
                            <td>
                              {`${advancePayment.advance_payment_file.slice(
                                0,
                                20,
                              )}...`}
                            </td>
                            <td>{advancePayment.uploaded_by}</td>
                            <td>
                              {moment(advancePayment.date_created).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </td>
                            <td>
                              <h6>
                                <Badge color="info">
                                  {advancePayment.status}
                                </Badge>
                              </h6>
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  window.open(
                                    `${advancePayment.advance_payment_file}`,
                                  )
                                }
                              >
                                Download CSV
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {advancePayments && (
                  <center>
                    <Pagination
                      page={currentPage}
                      pages={numberOfPages}
                      setPage={getAdvancePayments}
                    />
                  </center>
                )}
              </div>
            </div>
          </Container>
          {showUploadModal && (
            <AdvancePaymentFileUploadModal
              showAdvancePaymentFileUploadModalModal={setShowUploadModal}
            />
          )}
        </>
      )}
    </>
  );
};

AdvancePaymentFiles.defaultProps = {};

AdvancePaymentFiles.propTypes = {};

export default AdvancePaymentFiles;
