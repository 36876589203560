import React from "react";
import Header from "../../../theme/components/Headers/Header";
import axios from "axios";
import { environment } from "../../../enviroment/enviroment";
import { Card, CardHeader, Container, Row, Col } from "reactstrap";
import moment from "moment";
// core components

class MessageDetail extends React.Component {
  state = { loading: true };

  componentDidMount() {
    this.getMessage();
  }

  getMessage() {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .get(
        `${environment.baseUrl}/messages/${this.props.match.params.msgId}/`,
        { headers }
      )
      .then((res) => {
        this.setState({
          ...res.data,
          loading: false,
        });
      });
  }

  render() {
    const { employee, loading, message, date_created } = this.state;
    return (
      <>
        <Header />
        <Container fluid>
          <Row>
            <Col>
              {loading ? (
                <Row className={"my-3"}>
                  <Col className={"d-flex justify-content-center"}>
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "white",
                      borderBottom: "0.5pt solid #f7fafc",
                    }}
                  >
                    <div>Message detail</div>
                    <p>
                      Message sent to:{" "}
                      {employee &&
                        `${employee.first_name} ${employee.last_name}`}
                    </p>
                    <p>
                      <strong>Message:</strong> {message}
                    </p>
                    <p>
                      <strong>Date sent:</strong>
                      {moment(date_created).format("MMMM Do YYYY, h:mm:ss a")}
                    </p>
                  </CardHeader>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MessageDetail;
