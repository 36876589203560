import React, { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { environment } from 'enviroment/enviroment';
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import Swal from 'sweetalert2';
import apiResponseHandler from 'utils/apiResponseHandler';
import ProfileTable from './Components/ProfileTable';
import { Link } from 'react-router-dom';
import { useEnabledFeatureFlag } from 'utils/feature-flagging/FeatureFlagProvider';
import FeatureFlags from 'utils/feature-flagging/FeatureFlags';
import MaintenancePage from 'pages/Admin/System/MaintenancePage';
import styled from 'styled-components';
import debounce from 'lodash.debounce';

const StepAvatar = styled.span<{ isActive: boolean; isDone: boolean }>`
  background: ${(props) =>
    props.isActive ? '#5e72e4' : props.isDone ? '#adb5e5' : ''};
  color: ${(props) => (props.isActive ? '#fff' : '')};
  border: ${(props) =>
    props.isActive || props.isDone ? 'none' : ' 1px solid rgba(0, 0, 0, 0.05)'};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StepText = styled.span<{ isActive: boolean }>`
  font-size: 18px;
  margin-top: 8px;
  font-weight: ${(props) => (props.isActive ? 500 : 400)};
`;

const Step = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

let cancelToken: CancelTokenSource;

const MergeProfiles = () => {
  const flagToolsMergeProfilesTemp = useEnabledFeatureFlag(
    FeatureFlags.ADMIN_PORTAL_TOOLS_MERGE_PROFILES_FEATURE_FLAG_TEMP,
  );

  const [searchData, setSearchData] = useState({
    select1: [],
    select2: [],
  });
  const [profileToKeepId, setProfileToKeepId] = useState<number | undefined>(
    parseInt(localStorage.getItem('merge-profile-id1') || ''),
  );
  const [profileToMergeId, setProfileToMergeId] = useState<number | undefined>(
    parseInt(localStorage.getItem('merge-profile-id2') || ''),
  );
  const [steps, setSteps] = useState(
    parseInt(localStorage.getItem('merge-profile-step') || '1'),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [cannotMerge, setCannotMerge] = useState<boolean>(false);
  const [data, setData] = useState({
    employee_to_keep: {
      detail: {},
      payslips: [],
    },
    employee_to_merge: {
      detail: {},
      payslips: [],
    },
    merge_preview: {
      detail: {},
      payslips: [],
    },
    diff_from_original: [],
    warnings: [],
  });

  useEffect(() => {
    localStorage.setItem('merge-profile-step', steps.toString());
  }, [steps]);

  useEffect(() => {
    setData(JSON.parse(localStorage.getItem('merge-profile-data') || '{}'));
  }, []);

  const getEmployees = debounce((params, active: number) => {
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    axios
      .get(`${environment.baseUrl}/employees/`, {
        params: {
          ...params,
        },
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        setSearchData((prev) => ({
          ...prev,
          [`select${active}`]: res.data,
        }));
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error Searching employee.', error);
      });
  }, 500);

  const onChange = (event: any) => {
    if (event.target.name === 'keep')
      if (Number.isNaN(parseInt(event.currentTarget.value)))
        setProfileToKeepId(undefined);
      else {
        setProfileToKeepId(event.currentTarget.value);
        getEmployees({ search: event.currentTarget.value }, 1);
      }
    else if (Number.isNaN(parseInt(event.currentTarget.value)))
      setProfileToMergeId(undefined);
    else {
      setProfileToMergeId(event.currentTarget.value);
      getEmployees({ search: event.currentTarget.value }, 2);
    }
  };

  const onRequestPreview = () => {
    setLoading(true);
    axios
      .post(`${environment.baseUrl}/employees/merge_employee_preview/`, {
        employee_to_keep_id: `${profileToKeepId}`,
        employee_to_merge_id: `${profileToMergeId}`,
      })
      .then((res) => {
        setData(res.data);
        localStorage.setItem('merge-profile-data', JSON.stringify(res.data));
        localStorage.setItem(
          'merge-profile-id1',
          profileToKeepId?.toString() || '',
        );
        localStorage.setItem(
          'merge-profile-id2',
          profileToMergeId?.toString() || '',
        );
        setLoading(false);
        setCannotMerge(false);
        setSteps((prev) => prev + 1);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error Merging Profiles', error);
        setLoading(false);
        setCannotMerge(true);
      });
  };

  const onRequestCommit = () => {
    setLoading(true);
    Swal.fire({
      title: 'Merging Profiles?',
      text: `Are you sure you want to merge the profiles ${profileToKeepId} and ${profileToMergeId}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2dce89',
      confirmButtonText: 'Yes, merge them!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${environment.baseUrl}/employees/merge_employee_commit/`, {
            employee_to_keep_id: `${profileToKeepId}`,
            employee_to_merge_id: `${profileToMergeId}`,
          })
          .then((_res) => {
            Swal.fire({
              title: `Profiles ${profileToKeepId} and ${profileToMergeId} Merged`,
              icon: 'success',
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 10000,
              timerProgressBar: true,
            });

            setSteps(1);
            localStorage.removeItem('merge-profile-data');
            setLoading(false);
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error Commiting Merge', error);
            setSteps(1);
            localStorage.removeItem('merge-profile-data');
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
  };
  const onSearch = (searchTerm: number, inputName: string) => {
    if (inputName === 'keep') {
      setProfileToKeepId(searchTerm);
    } else if (inputName === 'merge') {
      setProfileToMergeId(searchTerm);
    }
  };

  return flagToolsMergeProfilesTemp ? (
    <>
      <div className="header bg-primary pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6">
                <h6 className="h2 text-white d-inline-block mb-0">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to="/admin/tools">Tools</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Merge Profiles</BreadcrumbItem>
                  </Breadcrumb>
                </h6>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="card mt--6">
          <div className="card-body">
            <div className="d-flex justify-content-around text-center">
              <Step>
                <StepAvatar isActive={steps === 1} isDone={steps > 1}>
                  1
                </StepAvatar>
                <StepText isActive={steps === 1}>Select Profiles</StepText>
              </Step>

              <Step>
                <StepAvatar isActive={steps === 2} isDone={steps > 2}>
                  2
                </StepAvatar>
                <StepText isActive={steps === 2}>Preview Profiles</StepText>
              </Step>
              <Step>
                <StepAvatar isActive={steps === 3} isDone={steps > 3}>
                  3
                </StepAvatar>
                <StepText isActive={steps === 3}>Merge Profiles</StepText>
              </Step>
            </div>
          </div>
        </div>
      </Container>

      {steps === 1 && (
        <Container fluid>
          <div className="card mt-4">
            <div className="card-body">
              <div
                className="d-flex justify-content-around "
                style={{ alignItems: 'end', flexWrap: 'wrap' }}
              >
                <div style={{ width: '250px', height: '120px' }}>
                  <Label for="keep-profile">Profile To Keep</Label>
                  <Input
                    type="number"
                    id="keep-profile"
                    name="keep"
                    className="btn btn-secondary"
                    value={profileToKeepId}
                    onChange={onChange}
                    disabled={loading}
                    autoFocus={true}
                    style={{
                      borderBottomRightRadius: '0px',
                      borderBottomLeftRadius: '0px',
                    }}
                  />
                  <div
                    style={{
                      marginTop: '8px',
                      backgroundColor: 'rgb(241 242 243)',
                      textAlign: 'center',
                      position: 'absolute',
                      width: '250px',
                      borderBottomRightRadius: '0.375rem',
                      borderBottomLeftRadius: '0.375rem',
                      zIndex: 10000,
                    }}
                  >
                    {searchData.select1
                      .slice(0, 10)
                      .filter((item: { id: number }) => {
                        const itemId = item.id.toString();
                        const searchTerm = profileToKeepId?.toString() || '-';
                        return itemId.startsWith(searchTerm);
                      })
                      .map(
                        (item: {
                          id: number;
                          first_name: string;
                          last_name: string;
                        }) => (
                          <div
                            key={item.id}
                            onClick={() => {
                              onSearch(item.id, 'keep');
                            }}
                            style={{
                              padding: '10px 0',
                              borderBottom: '1px #fff solid',
                              backgroundColor:
                                profileToKeepId === item.id
                                  ? '#fff'
                                  : 'inherit',
                            }}
                          >
                            {`${item.first_name}  ${item.last_name}`}
                          </div>
                        ),
                      )}
                  </div>
                </div>

                <div style={{ width: '250px', height: '120px' }}>
                  <Label for="merge-profile">Profile To Merge</Label>
                  <Input
                    type="number"
                    id="merge-profile"
                    name="merge"
                    className="btn btn-secondary"
                    value={profileToMergeId}
                    onChange={onChange}
                    disabled={loading || profileToKeepId === undefined}
                    style={{
                      borderBottomRightRadius: '0px',
                      borderBottomLeftRadius: '0px',
                    }}
                  />

                  <div
                    style={{
                      marginTop: '8px',
                      backgroundColor: 'rgb(241 242 243)',
                      textAlign: 'center',
                      position: 'absolute',
                      width: '250px',
                      borderBottomRightRadius: '0.375rem',
                      borderBottomLeftRadius: '0.375rem',
                      zIndex: 10000,
                    }}
                  >
                    {searchData.select2
                      .slice(0, 10)
                      .filter((item: { id: number }) => {
                        const itemId = item.id.toString();
                        const searchTerm = profileToMergeId?.toString() || '-';
                        return itemId.startsWith(searchTerm);
                      })
                      .map(
                        (item: {
                          id: number;
                          first_name: string;
                          last_name: string;
                        }) => (
                          <div
                            key={item.id}
                            onClick={() => {
                              onSearch(item.id, 'merge');
                            }}
                            style={{
                              padding: '10px 0',
                              borderBottom: '1px #fff solid',
                              backgroundColor:
                                profileToMergeId === item.id
                                  ? '#fff'
                                  : 'inherit',
                            }}
                          >
                            {`${item.first_name}  ${item.last_name}`}
                          </div>
                        ),
                      )}
                  </div>
                </div>
                <div style={{ width: '250px' }}>
                  <div style={{ height: '32px' }}></div>
                  <button
                    className="btn btn-primary"
                    onClick={onRequestPreview}
                    disabled={
                      loading ||
                      (profileToMergeId && profileToKeepId) === undefined
                    }
                  >
                    Continue
                  </button>
                </div>
              </div>
              {loading && (
                <Row className="my-3" style={{ zIndex: 100 }}>
                  <Col className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Container>
      )}

      {steps === 2 && (
        <Container fluid>
          <div className="card mt-4">
            <div className="card-body p-5">
              <button
                className="btn btn-secondary"
                onClick={() => setSteps((prev) => prev - 1)}
              >
                Back
              </button>
              {!cannotMerge &&
              Object.keys(data.employee_to_keep.detail || {}).length !== 0 ? (
                <>
                  <Container fluid className="mt-4">
                    <div className="card">
                      <h3 className="text-center pt-5">Profiles Details</h3>
                      <div
                        className="card-body"
                        style={{
                          height: '65vh',
                          overflowY: 'scroll',
                        }}
                      >
                        <div className="d-flex justify-content-around">
                          <ProfileTable
                            title={'Profile To Keep'}
                            detail={data.employee_to_keep.detail}
                            payslips={data.employee_to_keep.payslips}
                          />
                          <ProfileTable
                            title={'Profile To Merge'}
                            detail={data.employee_to_merge.detail}
                            payslips={data.employee_to_merge.payslips}
                          />
                        </div>
                      </div>
                    </div>
                  </Container>
                </>
              ) : (
                <></>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  paddingTop: '28px',
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={() => setSteps((prev) => prev + 1)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Container>
      )}

      {steps === 3 && (
        <Container fluid>
          <div className="card mt-4">
            <div className="card-body p-5">
              <button
                className="btn btn-secondary"
                onClick={() => setSteps((prev) => prev - 1)}
              >
                Back
              </button>

              {data.diff_from_original &&
              data.diff_from_original.length !== 0 ? (
                <div className="card mt-4">
                  <h3 className="text-center pt-5">Profile Changes</h3>
                  <div
                    className="card-body"
                    style={{
                      height: '45vh',
                      overflowY: 'scroll',
                    }}
                  >
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">Change</th>
                          <th scope="col">Previous</th>
                          <th scope="col">Current</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.diff_from_original.map((diff, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                fontWeight: 600,
                                color: '#2dce89',
                              }}
                            >
                              {diff[1]}
                            </td>
                            <td>{`${diff[2][0]}`}</td>
                            <td>{`${diff[2][1]}`}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="card mt-4">
                <h3 className="text-center pt-5 pb-4">
                  Merged Profile Preview
                </h3>
                <div
                  className="card-body"
                  style={{
                    height: '65vh',
                    overflowY: 'scroll',
                  }}
                >
                  <ProfileTable
                    title={'Merged Profile'}
                    detail={data.merge_preview.detail}
                    payslips={data.merge_preview.payslips}
                  />
                </div>
              </div>

              <div className="mt-4">
                {data.warnings.length !== 0 && (
                  <Alert color="warning">
                    {data.warnings &&
                      data.warnings.map((warn, index) => {
                        return <li key={index}>{warn}</li>;
                      })}
                  </Alert>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingTop: '28px',
                  }}
                >
                  <button className="btn btn-success" onClick={onRequestCommit}>
                    Merge Profiles
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  ) : (
    <MaintenancePage />
  );
};

export default MergeProfiles;
