/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';

import Swal from 'sweetalert2';
import apiResponseHandler from 'utils/apiResponseHandler';

import {
  Button,
  Col,
  Input,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';

import { environment } from '../../../enviroment/enviroment';

let cancelToken;
const createData = {
  override_date: null,
  run_date: null,
  subscription_batch_configuration: null,
  created_by: null,
};

/**
 * @summary AdvanceBatchConfigurationOverrideModal component
 *
 * @param {Props} props
 * @component
 */
const SubscriptionBatchConfigurationOverrideModal = (props) => {
  const [state, setState] = useState({
    batchOverrides: [],
    subscription_name: 'Mukuru subscription',
    company_name: 'Mukuru',
    addNewOverride: false,
  });
  const {
    toggleConfigurationOverridesModal,
    activeBatchConfig,
    showOverrideModal,
  } = props;

  const getOverrides = () => {
    setState({ loading: true });
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    cancelToken = axios.CancelToken.source();
    axios
      .get(`${environment.baseUrl}/subscription_batch_overrides/`, {
        headers,
        params: {
          subscription_batch_configuration: activeBatchConfig.id,
        },
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        setState({
          ...state,
          batchOverrides: res.data,
          toggleAddNewBatchModal: false,
        });
      });
  };

  const setAddNewOverride = (enabled) => {
    setState({ ...state, addNewOverride: enabled });
  };

  const deleteOverride = (batchOverrideID) => {
    axios
      .delete(
        `${environment.baseUrl}/subscription_batch_overrides/${batchOverrideID}/`,
      )
      .then(() => {
        Swal.fire({
          title: 'Subscription Override was deleted',
          icon: 'success',
        });
        getOverrides();
      })
      .catch(error => {
        Swal.fire({
          title: 'Subscription Override was not deleted',
          icon: 'warning',
        });
        //TODO
        apiResponseHandler.handleApiError('Advance Override was not deleted', error);
      });
  };

  const canOverrideBeDeleted = (batchOverride) => {
    const today = new Date();

    return (
      today < moment(batchOverride.run_date, 'YYYY-MM-DD') &&
      today < moment(batchOverride.override_date, 'YYYY-MM-DD')
    );
  };

  const createNewOverride = () => {
    const currentUserId = JSON.parse(localStorage.getItem('USER')).id;
    createData.created_by = currentUserId;
    createData.subscription_batch_configuration = activeBatchConfig.id;

    const url = `${environment.baseUrl}/subscription_batch_overrides/`;

    axios
      .post(url, createData)
      .then(() => {
        Swal.fire({
          title: 'Subscription Batch Configuration successfully submitted.',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });
        getOverrides();
        setAddNewOverride(false);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  useEffect(() => {
    getOverrides();
  }, []);

  return (
    <>
      <Modal isOpen={showOverrideModal} size="lg">
        <ModalHeader>
          Subscription Overrides for : {activeBatchConfig.subscription.name} (
          {activeBatchConfig.subscription.employer.company_name})
        </ModalHeader>
        <ModalBody>
          <Col className="col-3 ml-auto">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setAddNewOverride(true)}
            >
              Create Override
            </button>
          </Col>
          {Array.isArray(state.batchOverrides) ? (
            <Table striped>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Override Date</th>
                  <th>Run Date</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {state.addNewOverride && (
                  <tr>
                    <td />
                    <td>
                      <Input
                        type="date"
                        name="overrideDate"
                        id="overrideDate"
                        placeholder="Override Date"
                        onChange={(e) => {
                          createData.override_date = e.target.value;
                        }}
                        required
                      />
                    </td>
                    <td>
                      <Input
                        type="date"
                        name="runDate"
                        id="runDate"
                        placeholder="Run Date"
                        onChange={(e) => {
                          createData.run_date = e.target.value;
                        }}
                        required
                      />
                    </td>
                    <td align="left">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => createNewOverride()}
                      >
                        Create
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => setAddNewOverride(false)}
                      >
                        Cancel
                      </button>
                    </td>
                  </tr>
                )}
                {state.batchOverrides.map((batchOverride) => {
                  return (
                    <tr key={batchOverride.id}>
                      <td>{batchOverride.id}</td>
                      <td>{batchOverride.override_date}</td>
                      <td>{batchOverride.run_date}</td>
                      <td>
                        {canOverrideBeDeleted(batchOverride) && (
                          <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={() => deleteOverride(batchOverride.id)}
                          >
                            Delete
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}{' '}
              </tbody>
            </Table>
          ) : (
            <p>This configuration has no overrides</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleConfigurationOverridesModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

SubscriptionBatchConfigurationOverrideModal.defaultProps = {
  activeBatchConfig: null,
};

SubscriptionBatchConfigurationOverrideModal.propTypes = {
  toggleConfigurationOverridesModal: PropTypes.func.isRequired,
  activeBatchConfig: PropTypes.shape({
    id: PropTypes.string,
  }),
  showOverrideModal: PropTypes.bool.isRequired,
};

export default SubscriptionBatchConfigurationOverrideModal;
