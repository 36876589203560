import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';

import './theme/assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './theme/assets/scss/argon-dashboard-react.scss';

import AdminLayout from './theme/layouts/Admin.js';
import AuthLayout from './theme/layouts/Auth.js';
import EmployerLayout from './theme/layouts/Employer.js';
import history from './helper/history';
import GlobalStore from './state/GlobalStore';
import { Context } from './state/GlobalStore';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const PrivateRoute = ({ user, ...props }) =>
  user ? <Route {...props} /> : <Redirect to="/auth/login" />;

function App() {
  return (
    <div className="App">
      <GlobalStore>
        <Router history={history}>
          <Context.Consumer>
            {({ state }) => (
              <Switch>
                {console.log(state.user)}
                <PrivateRoute
                  user={state.user}
                  path="/admin"
                  render={(props) => <AdminLayout {...props} />}
                />
                <PrivateRoute
                  user={state.user}
                  path="/employeradmin"
                  render={(props) => <EmployerLayout {...props} />}
                />
                <Route
                  path="/auth"
                  render={(props) => <AuthLayout {...props} />}
                />
                <PrivateRoute
                  user={state.user}
                  render={() => <Redirect to="/admin/employers" />}
                />
              </Switch>
            )}
          </Context.Consumer>
        </Router>
      </GlobalStore>
    </div>
  );
}

export default App;
