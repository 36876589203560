/* eslint-disable import/extensions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import axios from 'axios';

import {
  Alert,
  Row,
  Col,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Swal from 'sweetalert2';

import { environment } from '../../../enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';

/**
 * @summary AddEducationQuestion component
 *
 * @param {Props} props
 * @component
 */
class AddEducationQuestionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      containerState: 'STATE_NEW',
      template: '',
      platform: 'whatsapp',
      category: 'Custom',
      isActive: false,
      type: 'multiple choice - single selection',
      answers: [],
      formError: false,
      errors: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAnswerInputChange = this.handleAnswerInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleAnswerInputChange(i, event) {
    const { target } = event;

    if (target.name === 'answer') {
      const answers = [...this.state.answers];
      answers[i] = event.target.value;
      this.setState({ answers });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const currentUserId = JSON.parse(localStorage.getItem('USER')).id;
    const answersList = [];

    const {
      answers,
      platform,
      type,
      template,
      isActive,
      category,
    } = this.state;

    answers &&
      answers.map((answer) =>
        answersList.push({
          template: answer,
          order_weight: '1',
          added_by: currentUserId,
          platform,
        }),
      );

    if (this.validateFormInput()) {
      const payload = {
        question_type: type,
        template,
        platform,
        is_active: isActive,
        category,
        added_by: currentUserId,
        answers: answersList,
      };

      axios
        .post(`${environment.baseUrl}/questions/`, payload)
        .then((res) => {
          Swal.fire({
            title: 'Question successfully added',
            icon: 'success',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 10000,
            timerProgressBar: true,
          });
          this.setState({ ...this.state, containerState: 'STATE_OK' });
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error adding question', error);
          this.setState({ ...this.state, containerState: 'STATE_ERROR' });
        });
    }
  }

  validateFormInput() {
    const { platform, type, template, isActive, category } = this.state;

    if (template === '' || template === undefined) {
      this.setState({ formError: true });
      const errors = [...this.state.errors];
      errors.push('Question template cannot be blank.');
      this.setState({ errors });
      return false;
    }
    if (platform === '' || platform === undefined) {
      this.setState({ formError: true });
      const errors = [...this.state.errors];
      errors.push('Please select a target platform.');
      this.setState({ errors });
      return false;
    }

    if (category === '' || category === undefined) {
      this.setState({ formError: true });
      const errors = [...this.state.errors];
      errors.push('Please select a question category.');
      this.setState({ errors });
      return false;
    }

    if (type === '' || type === undefined) {
      this.setState({ formError: true });
      const errors = [...this.state.errors];
      errors.push('Please select a question type.');
      this.setState({ errors });

      return false;
    }

    if (isActive === '' || isActive === undefined) {
      this.setState({ formError: true });
      const errors = [...this.state.errors];
      errors.push('Please check the Is Active checkbox.');
      this.setState({ errors });
      return false;
    }

    this.setState({ formError: false });
    return true;
  }

  removeClick(i) {
    const answers = [...this.state.answers];
    answers.splice(i, 1);
    this.setState({ answers });
  }

  addClick() {
    this.setState((prevState) => ({ answers: [...prevState.answers, ''] }));
  }

  createUI() {
    return this.state.answers.map((el, i) => (
      <div key={`answer_${i}`}>
        <FormGroup inline>
          <Label for="exampleText">
            <Button
              color="danger"
              size="sm"
              value="remove"
              onClick={this.removeClick.bind(this, i)}
            >
              {' '}
              Remove
            </Button>
          </Label>
          <Input
            type="textarea"
            name="answer"
            data-key={i}
            id="answer"
            value={el || ''}
            onChange={this.handleAnswerInputChange.bind(this, i)}
          />
        </FormGroup>
      </div>
    ));
  }

  render() {
    const {
      platform,
      type,
      template,
      category,
      formError,
      errors,
    } = this.state;

    if (this.state.containerState === 'STATE_OK') {
      this.props.history.push('/admin/questions/education');
      return null;
    }

    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Add Question
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <br />

        <Form onSubmit={this.handleSubmit}>
          {' '}
          <Container>
            {formError && errors && (
              <Alert color="danger">
                {errors.map((error) => {
                  return <li>{error}</li>;
                })}
              </Alert>
            )}

            <h4>New Question</h4>

            <FormGroup>
              <Label for="questionTemplate">Template</Label>
              <Input
                type="textarea"
                ows="6"
                name="template"
                id="questionTemplate"
                value={template}
                onChange={this.handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="questionTypeSelect">Type</Label>
              <Input
                type="select"
                name="type"
                value={type}
                id="questionTypeSelect"
                onChange={this.handleInputChange}
              >
                <option value="multiple choice - single selection">
                  multiple choice - single selection
                </option>
                <option value="multiple choice - multiple selection">
                  multiple choice - multiple selection
                </option>
                <option value="open ended">open ended</option>
                <option value="rating question">rating question</option>
                <option value="lickert scale">lickert scale</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="platformSelect">Platform</Label>
              <Input
                type="select"
                name="platform"
                value={platform}
                id="platformSelect"
                onChange={this.handleInputChange}
              >
                <option value="whatsapp">Whatsapp</option>
                <option value="ussd">USSD</option>
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="categorySelect">Category</Label>
              <Input
                type="select"
                name="category"
                id="categorySelect"
                value={category}
                onChange={this.handleInputChange}
              >
                <option value="Organisation for Economic Co-operation and Development">
                  Organisation for Economic Co-operation and Development
                </option>
                <option value="Custom">Custom</option>
              </Input>
            </FormGroup>

            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="isActive"
                  id="isActiveCheckbox"
                  onChange={this.handleInputChange}
                />{' '}
                Active
              </Label>
            </FormGroup>
          </Container>
          <Container>
            <h4>Answers</h4>
            {this.createUI()}
            <FormGroup>
              <Label for="actionButtons" />
              <Button
                color="primary"
                size="sm"
                onClick={this.addClick.bind(this)}
              >
                Add Answer
              </Button>{' '}
              <Button color="info" size="sm">
                Save
              </Button>
            </FormGroup>
          </Container>
        </Form>
      </>
    );
  }
}

AddEducationQuestionView.defaultProps = {};

AddEducationQuestionView.propTypes = {};

export default AddEducationQuestionView;
