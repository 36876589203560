import React, { useContext } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import axios from 'axios';
import { environment } from '../../../../enviroment/enviroment';
import Swal from 'sweetalert2';
import { ErrorMessage, Field, Formik } from 'formik';
import { Context } from '../../../../state/GlobalStore';
import apiResponseHandler from 'utils/apiResponseHandler';

const SupportModal = ({ supportTicket, onClose, props }) => {
  const { fetchStats } = useContext(Context);

  const updateSupportTicket = async (id, status, note = null) => {
    return axios
      .patch(`${environment.baseUrl}/supporttickets/${id}/`, {
        status: status,
        note: note,
      })
      .then((res) => fetchStats());
  };

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0,
        zIndex: 10,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(100,100,100,0.4)',
      }}
    >
      <Card
        style={{
          width: '800px',
          maxHeight: '100vh',
          margin: 'auto',
          backgroundColor: 'white',
        }}
      >
        <CardHeader
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <span style={{ cursor: 'pointer' }} onClick={() => onClose(false)}>
            X
          </span>
        </CardHeader>
        <CardBody style={{ overflowY: 'scroll' }}>
          <div>
            <strong>Message</strong>
          </div>
          <div>{supportTicket.text_content}</div>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <strong>Employee name</strong>
            </div>
            <div>
              {supportTicket.employee.first_name}{' '}
              {supportTicket.employee.last_name}
            </div>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
            className={'mt-2'}
          >
            <div>
              <strong>Employee mobile number</strong>
            </div>
            <div>+{supportTicket.employee.mobile_number}</div>
          </div>
          <Formik
            initialValues={{
              status: supportTicket.status,
              note: supportTicket.note,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.status || values.status === 'new') {
                errors.status = 'Please change this status';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              updateSupportTicket(supportTicket.id, values.status, values.note)
                .then((res) => {
                  Swal.fire({
                    title: 'Support ticket status change successfully',
                    icon: 'success',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  onClose();
                })
                .catch((error) => {
                  apiResponseHandler.handleApiError('Error', error);
                  setSubmitting(false);
                });
            }}
          >
            {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
              <form onSubmit={handleSubmit}>
                <hr />
                <Row className={'mt-3'}>
                  <Col>
                    <h4>Ticket status change</h4>
                  </Col>
                </Row>
                <Row className={'mb-3'}>
                  <Col md={'12'}>
                    <Field
                      name={'status'}
                      className={'form-control'}
                      placeholder={'Status'}
                      as={'select'}
                    >
                      <option disabled selected value="new">
                        New
                      </option>
                      <option value="in_progress">In progress</option>
                      <option value="resolved">Resolved</option>
                    </Field>
                    <ErrorMessage
                      name={'status'}
                      className={'text-danger'}
                      component={'small'}
                    />
                  </Col>
                  <Col md={'12'} className={'mt-3'}>
                    <Field
                      name={'note'}
                      as={'textarea'}
                      className={'form-control'}
                    />
                    <ErrorMessage
                      name={'note'}
                      className={'text-danger'}
                      component={'small'}
                    />
                  </Col>
                </Row>
                <Row className={'mt-3'}>
                  <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                      className={'btn btn-primary'}
                      disabled={isSubmitting}
                      type={'submit'}
                    >
                      Save
                    </button>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default SupportModal;
