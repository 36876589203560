/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Button, Card, CardHeader, Table } from 'reactstrap';
import { formatDateTime } from 'utils/formatters';
import axios from 'axios';
import AddNewBranchModal from './AddNewBranchModal';
import EditBranchModal from './EditBranchModal';

import { environment } from '../../../enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';
/**
 * @summary Render Branches component
 *
 * @param {object} props The components' props
 * @returns {Component}
 */
const Branches = (props) => {
  const [state, setState] = useState({
    toggleAddBranchModal: false,
    toggleEditBranchModal: false,
    activeBranch: {},
  });

  const { branches, employerId, getEmployerData } = props;

  const handleShowAddBranchModal = () => {
    setState({ ...state, toggleAddBranchModal: !state.toggleAddBranchModal });
  };

  const handleShowEditBranchModal = (branch) => {
    setState({
      ...state,
      toggleEditBranchModal: !state.toggleEditBranchModal,
      activeBranch: branch ? branch.branch : null,
    });
  };

  const handleDeleteBranch = (branch) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this branch?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${environment.baseUrl}/branches/${branch.branch.id}/`)
          .then(() => {
            Swal.fire({
              title: 'Branch was deleted',
              icon: 'success',
            });
            getEmployerData();
          })
          .catch(error => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  return (
    <>
      {!!branches && (
        <Card className="shadow">
          <CardHeader>
            <strong>Branches</strong>
            <Button
              color="primary"
              onClick={() => handleShowAddBranchModal()}
              className="btn btn-primary btn-sm float-right"
            >
              New Branch
            </Button>
          </CardHeader>

            <Table striped size={'sm'}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Branch Code</th>
                  <th>Date Created</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {branches.map((branch) => {
                  return (
                    <tr key={branch.id}>
                      <th scope="row">{branch.id}</th>
                      <td>{branch.name}</td>
                      <td>{branch.code}</td>
                      <td>{formatDateTime(branch.date_created)}</td>
                      <td align={'right'}>
                        <button
                          className="btn btn-primary btn-sm "
                          value={branch}
                          onClick={() => handleShowEditBranchModal({ branch })}
                        >
                          Edit
                        </button>

                        <button
                          className="btn btn-danger btn-sm "
                          onClick={() => handleDeleteBranch({ branch })}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
        </Card>
      )}

      {!!state.toggleAddBranchModal && (
        <AddNewBranchModal
          toggleAddBranchModal={state.toggleAddBranchModal}
          handleShowAddBranchModal={handleShowAddBranchModal}
          employerId={employerId}
          getEmployerData={getEmployerData}
        />
      )}

      {!!state.toggleEditBranchModal && (
        <EditBranchModal
          toggleEditBranchModal={state.toggleEditBranchModal}
          handleShowEditBranchModal={handleShowEditBranchModal}
          employerId={employerId}
          branch={state.activeBranch}
          getEmployerData={getEmployerData}
        />
      )}
    </>
  );
};

Branches.defaultProps = {
  branches: [],
};

Branches.propTypes = {
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
  employerId: PropTypes.string.isRequired,
  getEmployerData: PropTypes.func.isRequired,
};

export default Branches;
