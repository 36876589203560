/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import axios from 'axios';

import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import Swal from 'sweetalert2';

import { formatDateTimeForInput, formatISODateTime } from 'utils/formatters';
import { environment } from '../../../enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';

/**
 * @summary AddEducationQuestion component
 *
 * @param {Props} props
 * @component
 */
// eslint-disable-next-line react/prefer-stateless-function
class EditEmployerPayrollForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triedSubmitting: false,
      errors: [],
      showCustomCutOffDateFields: false,
      payRollData: {
        payrollId: props.payrollId ? props.payrollId : null,
        payrollName: props.payrollName ? props.payrollName : null,
        isDefault: props.isDefault ? props.isDefault : null,
        enableCustomCutOffDate: props.enableCustomCutOffDate
          ? props.enableCustomCutOffDate
          : null,
        cutOffDays: props.cutOffDays ? props.cutOffDays : null,
        advanceReconDate: props.advanceReconDate
          ? props.advanceReconDate
          : null,
        advanceCutOffDate: props.advanceCutOffDate
          ? props.advanceCutOffDate
          : null,
        blockAdvances: props.blockAdvances ? props.blockAdvances : null,
        blockAdvancesStartDate: props.blockAdvancesStartDate
          ? props.blockAdvancesStartDate
          : null,
        blockAdvancesEndDate: props.blockAdvancesEndDate
          ? props.blockAdvancesEndDate
          : null,
        blockReasonComment: props.blockReasonComment
          ? props.blockReasonComment
          : null,
      },
    };
  }

  /**
   * @summary toggle the default flag on a payroll
   *
   * @param event
   *
   */
  handleIsDefaultCheck = (event) => {
    this.setState(
      this.setState({
        payRollData: {
          ...this.state.payRollData,
          isDefault: event.target.checked,
        },
      }),
    );
  };

  /**
   * @summary show the block advances fields based on the
   * block advances checkbox
   *
   * @param event
   *
   */
  handleShowBlockAdvanceFieldsCheck = (event) => {
    this.setState(
      this.setState({
        payRollData: {
          ...this.state.payRollData,
          blockAdvances: event.target.checked,
        },
      }),
    );
  };

  /**
   * @summary show the custom cutoff fields based on the
   * custom cutoff checkbox
   *
   * @param event
   *
   */
  handleShowCustomCutOffDateFieldsCheck = (event) => {
    this.setState({ showCustomCutOffDateFields: !!event.target.checked }, () =>
      this.setState({
        payRollData: {
          ...this.state.payRollData,
          enableCustomCutOffDate: this.state.showCustomCutOffDateFields,
        },
      }),
    );
  };

  /**
   * @summary handle form update
   *
   */
  handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    this.setState({
      payRollData: {
        ...this.state.payRollData,
        [name]: value,
      },
    });
  };

  validatePayrollCreationPayload = (payload) => {
    const errors = [];
    if (!payload.name || payload.name.trim() === '') {
      errors.push('Please enter a Name for the Payroll');
    }

    if (!payload.cut_off_days || payload.cut_off_days < 0) {
      errors.push('Please enter Cut off days ');
    }

    if (!payload.advance_recon_date || payload.advance_recon_date < 0) {
      errors.push('Please enter Advance Recon Date');
    }

    if (payload.enable_custom_cutoff_date && !payload.advance_cut_off_date) {
      errors.push(
        'Please select choose a date for the custom advance cut off date.',
      );
    }

    if (
      payload.block_advances &&
      (!payload.block_advances_start_date || !payload.block_advances_end_date)
    ) {
      errors.push(
        'Please select start and/or end dates for period to block advances.',
      );
    }

    this.setState({
      errors,
    });

    return errors.length < 1;
  };

  /**
   * @summary update payroll with new data
   *
   *
   */
  handleSubmit = (event) => {
    this.setState({ ...this.state, triedSubmitting: true });
    event.preventDefault();
    const {
      payrollId,
      isDefault,
      payrollName,
      cutOffDays,
      advanceCutOffDate,
      advanceReconDate,
      enableCustomCutOffDate,
      blockAdvances,
      blockAdvancesStartDate,
      blockAdvancesEndDate,
      blockReasonComment,
    } = this.state.payRollData;
    const { toggle } = this.props;

    const payload = {
      name: payrollName,
      is_default: isDefault,
      cut_off_days: cutOffDays,
      enable_custom_cutoff_date: enableCustomCutOffDate,
      advance_cut_off_date: formatISODateTime(advanceCutOffDate),
      block_advances: blockAdvances,
      block_advances_start_date: formatISODateTime(blockAdvancesStartDate),
      block_advances_end_date: formatISODateTime(blockAdvancesEndDate),
      block_reason_comment: blockReasonComment,
      advance_recon_date: advanceReconDate,
    };

    // remove nulls from object (probably move to utils)
    const payloadNoNulls = Object.entries(payload).reduce(
      // eslint-disable-next-line no-return-assign
      (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
      {},
    );

    // if custom cut off is off, clear advance cutoff date
    if (payloadNoNulls.enable_custom_cutoff_date === false) {
      payloadNoNulls.advance_cut_off_date = null;
    }

    // if block advances is off, clear block advances dates
    if (payloadNoNulls.block_advances === false) {
      payloadNoNulls.block_advances_start_date = null;
      payloadNoNulls.block_advances_end_date = null;
    }

    if (this.validatePayrollCreationPayload(payloadNoNulls)) {
      const url = `${environment.baseUrl}/pay_cycles/${payrollId}/`;

      axios
        .patch(url, payloadNoNulls)
        .then(() => {
          Swal.fire({
            title: 'Payroll details saved',
            icon: 'success',
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
          toggle();
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
          // this.setState({ closeModal: false });
        });
    }
  };

  render() {
    const {
      toggle,
      showEditModal,
      payrollName,
      payrollId,
      cutOffDays,
      advanceCutOffDate,
      blockAdvancesStartDate,
      blockAdvancesEndDate,
      blockReasonComment,
      advanceReconDate,
    } = this.props;
    return (
      <>
        <Modal isOpen={showEditModal} toggle={toggle}>
          <Form onSubmit={this.handleSubmit}>
            <ModalHeader>Payroll ID : {payrollId}</ModalHeader>
            <ModalBody>
              {this.state.errors && this.state.errors.length > 0 && (
                <Alert color="danger">
                  {this.state.errors.map((error) => {
                    return <li key={error}>{error}</li>;
                  })}
                </Alert>
              )}
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="checkbox"
                    name="isDefault"
                    checked={this.state.payRollData.isDefault}
                    onChange={(event) => {
                      this.handleIsDefaultCheck(event);
                    }}
                  />
                  Mark as default
                </Label>
              </FormGroup>
              <br />
              <FormGroup>
                <Label for="payrollName">Name</Label>
                <Input
                  type="text"
                  bsSize="sm"
                  name="payrollName"
                  id="payrollName"
                  defaultValue={payrollName}
                  onChange={this.handleInputChange}
                  invalid={
                    this.state.triedSubmitting &&
                    !this.state.payRollData.payrollName
                  }
                />
              </FormGroup>

              <FormGroup check inline>
                <Label>
                  <Input
                    type="checkbox"
                    name="blockAdvances"
                    checked={this.state.payRollData.blockAdvances}
                    onChange={(event) => {
                      this.handleShowBlockAdvanceFieldsCheck(event);
                    }}
                  />{' '}
                  Block Advances
                </Label>
              </FormGroup>

              {!!this.state.payRollData.blockAdvances && (
                <>
                  <FormGroup>
                    <Label for="blockAdvancesStartDate">
                      Block Advances Start Date
                    </Label>

                    <Input
                      type="datetime-local"
                      name="blockAdvancesStartDate"
                      bsSize="sm"
                      id="blockAdvancesStartDate"
                      defaultValue={formatDateTimeForInput(
                        blockAdvancesStartDate,
                      )}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="blockAdvancesEndDate">
                      Block Advances End Date
                    </Label>{' '}
                    <Input
                      type="datetime-local"
                      name="blockAdvancesEndDate"
                      defaultValue={formatDateTimeForInput(
                        blockAdvancesEndDate,
                      )}
                      bsSize="sm"
                      id="blockAdvancesEndDate"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleText">Reason</Label>
                    <Input
                      type="textarea"
                      name="text"
                      defaultValue={blockReasonComment}
                      id="exampleText"
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </>
              )}

              <FormGroup>
                <Label for="cutOffDays">Cut off days</Label>
                <Input
                  type="number"
                  bsSize="sm"
                  name="cutOffDays"
                  defaultValue={cutOffDays}
                  id="cutOffDays"
                  onChange={this.handleInputChange}
                  invalid={
                    this.state.triedSubmitting &&
                    !this.state.payRollData.cutOffDays
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label for="advanceReconDate">Advance Recon Date</Label>
                <Input
                  type="number"
                  bsSize="sm"
                  name="advanceReconDate"
                  defaultValue={advanceReconDate}
                  id="advanceReconDate"
                  onChange={this.handleInputChange}
                  invalid={
                    this.state.triedSubmitting &&
                    !this.state.payRollData.advanceReconDate
                  }
                />
              </FormGroup>

              <FormGroup check inline>
                <Label check>
                  <Input
                    type="checkbox"
                    name="enableCustomCutOffDate"
                    checked={this.state.payRollData.enableCustomCutOffDate}
                    onChange={(event) => {
                      this.handleShowCustomCutOffDateFieldsCheck(event);
                    }}
                  />{' '}
                  Enable custom cutoff date
                </Label>
              </FormGroup>

              {(!!this.state.showCustomCutOffDateFields ||
                this.state.payRollData.enableCustomCutOffDate) && (
                <>
                  <FormGroup>
                    <Label for="advanceCutOffDate">Custom cut off date</Label>

                    <Input
                      type="datetime-local"
                      name="advanceCutOffDate"
                      value={formatDateTimeForInput(advanceCutOffDate)}
                      bsSize="sm"
                      id="advanceCutOffDate"
                      onChange={this.handleInputChange}
                      invalid={
                        this.state.triedSubmitting &&
                        !this.state.payRollData.advanceCutOffDate
                      }
                    />
                  </FormGroup>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Update</Button>{' '}
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
}

export default EditEmployerPayrollForm;
