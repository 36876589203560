/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import axios from 'axios';
import { Col, InputGroupAddon, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import removeKeysWithNullValues from 'utils';
// eslint-disable-next-line import/no-unresolved
import apiResponseHandler from 'utils/apiResponseHandler';
import { environment } from '../../../enviroment/enviroment';

class EmployeeEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      save_data: this.props.data,
      fee_model: this.props.data.subscription
        ? 'subscription'
        : 'transaction_fee',
      employers: [],
      subscriptions: [],
      transactionFees: [],
      reason_category: null,
      reason_text: null,
    };
  }

  reasonCategories = [
    'employer_request_terminated',
    'employer_request_resigned',
    'employer_request_maternity_leave',
    'employer_request_no_explanation',
    'employer_request_banking_details_updated',
    'employer_request_other',
    'employee_request_mobile_number_update',
    'employee_request_unsubscribe',
    'employee_request_change_risk_metric',
    'employee_request_other',
    'other',
  ];

  componentDidMount() {
    this.getEmployers();
    this.getSubscriptions();
    this.getTransactionFees();
    this.getEmployerPayrolls();
  }

  getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers?status=active`).then((res) => {
      this.setState({ employers: res.data });
    });
  };

  /**
   * Get employer payrolls when an employee is changing companies.
   *
   *
   */
  getEmployerPayrolls = () => {
    const url = `${environment.baseUrl}/employers/${this.props.data.employer.id}/`;

    axios.get(url).then((res) => {
      this.setState({
        save_data: {
          ...this.state.save_data,
          employer: res.data,
        },
      });
    });
  };

  getSubscriptions = () => {
    const url = `${environment.baseUrl}/subscriptions/`;

    axios
      .get(url, { params: { employer_id: this.props.data.employer.id } })
      .then((res) => {
        this.setState({ subscriptions: res.data });
      });
  };

  getTransactionFees = () => {
    const url = `${environment.baseUrl}/transactionfees/`;

    axios
      .get(url, { params: { employer_id: this.props.data.employer.id } })
      .then((res) => {
        this.setState({ transactionFees: res.data });
      });
  };

  handleEmployerChange = (event) => {
    event.preventDefault();
    const selectedEmployer = this.state.employers[event.target.value];
    this.props.data.employer = selectedEmployer;

    this.setState({
      save_data: {
        ...this.state.save_data,
        employer: selectedEmployer,
        employee_branch_id: null,
        employee_branch: null,
        employee_pay_cycle_id: null,
      },
    });
    this.getEmployerPayrolls();
  };

  handleInputChange = (event) => {
    event.preventDefault();
    this.setState({
      save_data: {
        ...this.state.save_data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleReasonChange = (event) => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  updateEmployeeData = (data, employeeId) => {
    const url = `${environment.baseUrl}/employees/${employeeId}/`;
    axios
      .patch(url, data)
      .then((_res) => {
        Swal.fire({
          title: 'Employee profile saved',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.props.getEmployeeData();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  saveData = () => {
    const { save_data, reason_category, reason_text } = this.state;

    const payload = removeKeysWithNullValues(save_data);

    payload.reason_category = reason_category;
    payload.reason_text = reason_text;

    if (payload.risky === 'false') {
      payload.risky_reasons = null;
    }

    if (payload.employer) {
      payload.employer = payload.employer.id;

      // Ensure that a new paycycle is set if employer is changed
      if (save_data.employee_pay_cycle_id === null) {
        Swal.fire({
          icon: 'warning',
          title: 'Employee profile was not saved',
          text:
            'Employee changes not saved! Please set a new paycycle for this employee.',
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });

        return;
      }

      // Ensure that a new branch is set if employer is changed
      if (save_data.employee_branch_id === null) {
        Swal.fire({
          icon: 'warning',
          title: 'Employee profile was not saved',
          text:
            'Employee changes not saved! Please set a new employer branch for this employee.',
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });

        return;
      }
    }

    if (payload.employee_branch_id) {
      payload.employee_branch = payload.employee_branch_id;
    } else {
      delete payload.employee_branch;
    }

    if (payload.employee_pay_cycle_id) {
      payload.employee_pay_cycle = payload.employee_pay_cycle_id;
    } else {
      delete payload.employee_pay_cycle;
    }

    const url = `${environment.baseUrl}/employees/${save_data.id}/`;

    axios
      .patch(url, payload)
      .then((_res) => {
        Swal.fire({
          title: 'Employee profile saved',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.props.getEmployeeData();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  render() {
    const { employers, save_data, subscriptions, transactionFees } = this.state;
    const fieldWidth = 7;
    const labelWidth = 5;
    return (
      <div
        className="p-3"
        style={{ fontSize: '0.8rem', maxWidth: '670px', margin: 'auto' }}
      >
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="smartwage_status">
              Smartwage Status
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <select
              className="form-control"
              name="smartwage_status"
              onChange={this.handleInputChange}
              required
            >
              <option
                value="active"
                selected={this.state.save_data.smartwage_status === 'active'}
              >
                Active
              </option>
              <option
                value="suspended"
                selected={this.state.save_data.smartwage_status === 'suspended'}
              >
                Suspended
              </option>
              <option
                value="deleted"
                selected={this.state.save_data.smartwage_status === 'deleted'}
              >
                Deleted
              </option>
            </select>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="smartwage_status">
              Risky Profile
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <select
              className="form-control"
              name="risky"
              onChange={this.handleInputChange}
              required
            >
              <option
                value="true"
                selected={this.state.save_data.risky === true}
              >
                Yes
              </option>
              <option
                value="false"
                selected={this.state.save_data.risky === false}
              >
                No
              </option>
            </select>
          </Col>
        </Row>
        <hr className="mb-3" />
        <Row className="mb-3">
          <Col md={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="employer">
              Employer
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <select
              className="form-control"
              name="employer"
              onChange={this.handleEmployerChange}
              id=""
            >
              {employers.map((employer, index) => (
                <option
                  value={index}
                  selected={this.state.save_data.employer.id === employer.id}
                >
                  {employer.company_name}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="employee_branch_id">
              Branch
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <select
              className="form-control"
              name="employee_branch_id"
              onChange={this.handleInputChange}
              id=""
            >
              <option />
              {!!save_data.employer &&
                !!save_data.employer.branches &&
                save_data.employer.branches.map((branch) => (
                  <option
                    value={branch.id}
                    selected={
                      this.state.save_data.employee_branch
                        ? this.state.save_data.employee_branch.id === branch.id
                        : false
                    }
                  >
                    {branch.name}
                  </option>
                ))}
            </select>
          </Col>
        </Row>
        <Row>
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="employee_no">
              Employee no.
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="text"
              name="employee_no"
              value={this.state.save_data.employee_no}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Employee Number"
            />
          </Col>
        </Row>
        <hr />
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label
              style={{ fontWeight: '800' }}
              htmlFor="employee_pay_cycle_id"
            >
              Salary
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <div className="input-group">
              <InputGroupAddon addonType="prepend">R</InputGroupAddon>
              <input
                type="number"
                name="salary"
                value={this.state.save_data.salary}
                onChange={this.handleInputChange}
                className="form-control"
                placeholder="Salary"
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label
              style={{ fontWeight: '800' }}
              htmlFor="employee_pay_cycle_id"
            >
              Employer pay cycle
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <select
              className="form-control"
              name="employee_pay_cycle_id"
              onChange={this.handleInputChange}
              required
            >
              <option />
              {!!save_data.employer &&
                save_data.employer.pay_cycles &&
                save_data.employer.pay_cycles.map((payCycle) => {
                  if (
                    payCycle.id ===
                    (save_data.employee_pay_cycle
                      ? save_data.employee_pay_cycle.id
                      : null)
                  ) {
                    return (
                      <option selected value={payCycle.id}>
                        {payCycle.name}
                      </option>
                    );
                  }
                  return (
                    <option value={payCycle.id} key={payCycle.id}>
                      {payCycle.name}
                    </option>
                  );
                })}
            </select>
          </Col>
        </Row>
        <hr />
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="subscription">
              Fee model
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <select
              name="fee_model"
              onChange={(e) =>
                this.setState({
                  fee_model: e.target.value,
                  save_data: {
                    ...this.state.save_data,
                    subscription: null,
                    transaction_fee: null,
                  },
                })
              }
              className="form-control"
            >
              <option
                value="subscription"
                selected={this.state.fee_model === 'subscription'}
              >
                Subscription
              </option>
              <option
                value="transaction_fee"
                selected={this.state.fee_model === 'transaction_fee'}
              >
                Transaction Fee
              </option>
            </select>
          </Col>
        </Row>
        {this.state.fee_model === 'subscription' && (
          <Row className="mb-3">
            <Col lg={labelWidth}>
              <label style={{ fontWeight: '800' }} htmlFor="subscription">
                Subscription
              </label>
            </Col>
            <Col lg={fieldWidth}>
              <select
                name="subscription"
                onChange={this.handleInputChange}
                className="form-control"
              >
                <option value="">None</option>
                {subscriptions.map((subscription) => (
                  <option
                    value={subscription.id}
                    selected={
                      this.state.save_data.subscription === subscription.id
                    }
                  >
                    {subscription.name}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
        )}
        {this.state.fee_model === 'transaction_fee' && (
          <Row className="mb-3">
            <Col lg={labelWidth}>
              <label style={{ fontWeight: '800' }} htmlFor="subscription">
                Transaction fee
              </label>
            </Col>
            <Col lg={fieldWidth}>
              <select
                name="transaction_fee"
                onChange={this.handleInputChange}
                className="form-control"
              >
                <option value="">None</option>
                {transactionFees.map((transactionFee) => (
                  <option
                    value={transactionFee.id}
                    selected={
                      this.state.save_data.transaction_fee === transactionFee.id
                    }
                  >
                    {`(${transactionFee.id}) ${transactionFee.fee_type} - R${transactionFee.fee_amount} + ${transactionFee.fee_percentage}%`}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
        )}
        <hr />
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="bank_name">
              Bank name
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="text"
              name="bank_name"
              value={this.state.save_data.bank_name}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Bank name"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="bank_branch_code">
              Branch code
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="text"
              name="bank_branch_code"
              value={this.state.save_data.bank_branch_code}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Bank Branch Code"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="bank_account_number">
              Account number
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="text"
              name="bank_account_number"
              value={this.state.save_data.bank_account_number}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Bank Account Number"
            />
          </Col>
        </Row>
        <hr />
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="fee_value">
              Risk metric
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="number"
              name="risk_metric"
              value={this.state.save_data.risk_metric}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Risk metric"
            />
          </Col>
        </Row>
        <hr />
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="first_name">
              First name
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="text"
              name="first_name"
              value={this.state.save_data.first_name}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="First Name"
              required
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="last_name">
              Last name
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="text"
              name="last_name"
              value={this.state.save_data.last_name}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Last Name"
              required
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="id_number">
              ID Number
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="text"
              name="id_number"
              value={this.state.save_data.id_number}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Id Number"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="mobile_number">
              Mobile number
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="number"
              name="mobile_number"
              value={this.state.save_data.mobile_number}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder=" Mobile"
              required
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="email">
              Email
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="email"
              name="email"
              value={this.state.save_data.email}
              onChange={this.handleInputChange}
              className="form-control"
              placeholder="Email"
              required
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="reason_category">
              Category
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <select
              className="form-control"
              name="reason_category"
              onChange={this.handleReasonChange}
              required
            >
              <option selected disabled>
                Select Category
              </option>

              {this.reasonCategories.map((reasonCategory) => (
                <option
                  value={reasonCategory}
                  selected={this.state.reason_category === reasonCategory}
                >
                  {reasonCategory.toUpperCase().replace(/_/g, ' ')}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="reason_text">
              Reason
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <input
              type="text"
              value={this.state.reason_text}
              name="reason_text"
              className="form-control"
              placeholder="Reason"
              onChange={this.handleReasonChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={labelWidth}>
            <label style={{ fontWeight: '800' }} htmlFor="reason_text">
              Accept Ts and Cs
            </label>
          </Col>
          <Col lg={fieldWidth}>
            <select
              className="form-control"
              name="term_cond"
              onChange={this.handleInputChange}
              required
            >
              <option selected disabled>
                Select
              </option>
              <option
                value="true"
                selected={this.state.save_data.term_cond === true}
              >
                Yes
              </option>
              <option
                value="false"
                selected={this.state.save_data.term_cond === false}
              >
                No
              </option>
            </select>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <button
              disabled={!this.state.reason_category}
              onClick={this.saveData}
              className="btn btn-primary btn-block"
            >
              Save changes
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EmployeeEditForm;
