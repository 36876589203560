/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table } from 'reactstrap';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved
import { environment } from '../../../../enviroment/enviroment';

const Subscriptions = ({ employeeId }) => {
  const [employeeSubscriptions, setEmployeeSubscriptions] = useState([]);
  const [subscription, setSubscription] = useState(null);

  const getEmployeeSubscriptions = () => {
    axios
      .get(`${environment.baseUrl}/employeesubscriptions/`, {
        params: { employee_id: employeeId },
      })
      .then((res) => {
        setEmployeeSubscriptions(res.data);
      });
  };

  useEffect(() => {
    getEmployeeSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Subscription ID</th>
            <th scope="col">Date unsubscribed</th>
            <th scope="col">Lock in</th>
            <th scope="col">Date created</th>
          </tr>
        </thead>
        <tbody>
          {employeeSubscriptions.map((subscription) => (
            <tr>
              <td>{subscription.id}</td>
              <td>
                {subscription.date_unsubscribed
                  ? moment(subscription.date_unsubscribed).format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )
                  : 'Not unsubscribed'}
              </td>
              <td>{subscription.lock_in_duration / (24 * 60 * 60)} days</td>
              <td>
                {moment(subscription.date_created).format(
                  'MMMM Do YYYY, h:mm:ss a',
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Subscriptions;
