import React from "react";
import axios from "axios";
import { environment } from "../../../enviroment/enviroment";
import { Card, CardHeader, Container, Row, Col, CardBody, FormGroup, Label, Input, } from "reactstrap";
import moment from "moment";
import Pagination from "./MessagePagination";
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';

let cancelToken;

class MessagesList extends React.Component {
  state = {
    messages: [],
    loadingSessions: false,
    loadingMessages: false,
    sessions: [],
    selectedSession: null,
    query: "",
    page: 1,
    source: "whatsapp"
  };

  componentDidMount() {
    this.getMessageSessions({'source': 'whatsapp'});
  }

  getMessageSessions = debounce((params) => {
    this.setState({ loadingSessions: true });
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    axios
      .get(`${environment.baseUrl}/messagesessions/`, {
        params: {
          page: this.state.page,
          pagination: true,
          ...params,
        },
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        this.setState({
          sessions: res.data.results,
          pages: res.data.pages,
          loadingSessions: false,
        });
      });
  }, 500)

  getMessages = debounce(async (params) => {
    this.setState({ loadingMessages: true });
    if (params.session) {
      this.setState({ selectedSession: params.session });
    }
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    axios
      .get(`${environment.baseUrl}/messages/`, {
        params: {
          ...params,
        },
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        res.data.sort(
          (a, b) => new Date(a.date_created) - new Date(b.date_created)
        );
        this.setState({
          messages: res.data,
          loadingMessages: false,
        });
      });
  }, 500);

  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      query: value,
    });
    this.getMessageSessions({ search: value });
  };

  setPage = (page) => {
    this.setState({ page: page, messages: [] }, () =>
      this.getMessageSessions()
    );
  };

  getSourceMessages = (event) => {
    const { value } = event.target;
    this.setState({ source: value });
    this.setState({ page: 1, messages: [] }, () =>
      this.getMessageSessions({'source':value})
    );
  };

  render() {
    const {
      messages,
      loadingSessions,
      loadingMessages,
      selectedSession,
      sessions,
      page,
      pages,
      query,
      source
    } = this.state;
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg={"6"}>
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Messages
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <Col className={"mt-3"}>
              <Card className="shadow" style={{ height: "90vh" }}>
                <CardHeader style={{justifyContent: 'space-between'}}>
                  <Row>
                    <Col lg={"6"}>
                      <div onChange={this.getSourceMessages}>
                        <FormGroup check inline>
                          <Label>
                            <Input type="radio" name="messageSourceType" value="whatsapp" checked={source==='whatsapp'}/> Whatsapp
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label>
                              <Input type="radio" name="messageSourceType" value="ussd" /> Ussd
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label>
                              <Input type="radio" name="messageSourceType" value="education" /> Education
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>
                    <Col lg={"6"}>
                      <div>
                        <button className={'btn btn-primary'} onClick={() =>  this.props.history.push('/admin/smslogs')}>SMS Logs</button>
                        <button className={'btn btn-primary'} onClick={() =>  this.props.history.push('/admin/questions/education')}>Education questions</button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={"4"}>
                      <div>
                        <input
                          type="text"
                          value={query}
                          className="form-control"
                          placeholder="Search sessions"
                          onChange={this.onChange}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className={"p-0"} style={{ minHeight: 0 }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: 4,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {loadingSessions ? (
                        <Row className={"my-3"}>
                          <Col className={"d-flex justify-content-center"}>
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <div style={{ overflowY: "scroll" }}>
                          {sessions.map((session) => (
                            <div
                              className={"d-flex p-3 border-bottom"}
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  selectedSession === session.id && "#e66fff",
                                color:
                                  selectedSession === session.id && "white",
                              }}
                              onClick={() =>
                                this.getMessages({ session: session.id})
                              }
                            >
                              <Col>
                                {session.employee &&
                                  <Link to={`/admin/employees/${session.employee.id}`}>{session.employee.first_name} {session.employee.last_name}</Link>}
                              </Col>
                              <Col
                                style={{
                                  fontSize: 12,
                                  display: "flex",
                                  flexDirection: 'column',
                                  justifyContent: "center",
                                  alignItems: "flex-end",
                                }}
                              >
                                {moment(session.date_updated).format(
                                  "h:mm a, L"
                                )}
                                {session.source === 'whatsapp' ? <span className={'badge badge-success'}>Whatsapp</span> :
                                 session.source === 'ussd' ? <span className={'badge badge-info'}>USSD</span> : <span className={'badge badge-primary'}>Education</span> }

                              </Col>
                            </div>
                          ))}
                        </div>
                      )}
                      {!loadingSessions && (
                        <div className={"border-right border-top pt-2"}>
                          <Pagination
                            page={page}
                            pages={pages}
                            setPage={this.setPage}
                          />
                        </div>
                      )}
                    </div>
                    <div style={{ flex: 8, overflowY: "scroll" }}>
                      {loadingMessages ? (
                        <Col className={"d-flex justify-content-center"}>
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </Col>
                      ) : (
                        <div
                          className={"mt-3 w-100"}
                          style={{ maxWidth: "800px", margin: "auto" }}
                        >
                          {messages.map((message) => {
                            return (
                              <Row className={"w-100 mb-3"}>
                                <Col
                                  md={{
                                    size: 8,
                                    offset:
                                      message.delivery_type === "sent" ? 4 : 0,
                                  }}
                                >
                                  <Card>
                                    <CardBody
                                      style={{
                                        backgroundColor:
                                          message.delivery_type === "sent"
                                            ? "#aff3a7"
                                            : "#eaeaea",
                                        borderRadius: 6,
                                        paddingBottom: 28,
                                        whiteSpace: "pre-line",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          bottom: 5,
                                          right: 8,
                                          fontSize: 12,
                                          color: "darkgray",
                                        }}
                                      >
                                        {moment(message.date_created).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )}
                                      </span>
                                      {message.message}
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MessagesList;
