import React from "react";
import Select from 'react-select'

class UserFeatureSelected extends React.Component{

    render(){
        return (
            <Select
            isMulti
            name="features"
            isSearchable={true}
            onChange={(features) =>
                {
                    let selected = [];
                    if(features != null){
                        selected = [...this.props.employerFeatures].filter(e => features.map(f => f.value).includes(e.feature.id));
                    }
                    this.props.updatedUserFeatures(selected);
                }
            }
            value={this.props.userFeatures.map(userFeature => {
                return {
                    label: userFeature.feature.name,
                    value: userFeature.feature.id
                }
            })}
            options={this.props.employerFeatures.map(employerFeature => {
                return {
                    label: employerFeature.feature.name,
                    value: employerFeature.feature.id
                }
            })}
            />
        )
    }
}

export default UserFeatureSelected;