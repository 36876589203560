/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import { environment } from '../../../enviroment/enviroment';

/**
 * @summary AdvanceBatchItemsModal component
 *
 * @param {Props} props
 * @component
 */
const AdvanceBatchItemsModal = (props) => {
  const [state, setState] = useState({
    batchAdvances: null,
  });
  const {
    toggleBatchItemsModal,
    handleShowBatchItemsModal,
    activeBatch,
  } = props;

  const getAdvances = () => {
    axios
      .get(`${environment.baseUrl}/advance_batch/${activeBatch.id}/report`)
      .then((res) => {
        setState({
          ...state,
          batchAdvances: res.data,
          toggleAddNewBatchModal: false,
          toggleBatchItemsModal: false,
        });
      });
  };

  useEffect(() => {
    getAdvances();
  }, []);

  return (
    <>
      <Modal isOpen={toggleBatchItemsModal} size="lg">
        <ModalHeader>Advance Batch Items</ModalHeader>
        <ModalBody>
          {Array.isArray(state.batchAdvances) && state.batchAdvances.length ? (
            <Table striped>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Amount Requested</th>
                  <th>Fee</th>
                  <th>Status</th>
                  <th>Requested By</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {state.batchAdvances.map((advance) => {
                  return (
                    <tr key={advance.id}>
                      <td>{advance.id}</td>
                      <td>{advance.amount_requested}</td>
                      <td>{advance.fee}</td>
                      <td>{advance.status}</td>
                      <td>{advance.reason}</td>
                      <td>{advance.source_type}</td>
                      <td>{advance.source_id}</td>
                    </tr>
                  );
                })}{' '}
              </tbody>
            </Table>
          ) : (
            <p>This batch has no linked advances</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleShowBatchItemsModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

AdvanceBatchItemsModal.defaultProps = {
  activeBatch: null,
};

AdvanceBatchItemsModal.propTypes = {
  toggleBatchItemsModal: PropTypes.func.isRequired,
  handleShowBatchItemsModal: PropTypes.func.isRequired,
  activeBatch: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default AdvanceBatchItemsModal;
