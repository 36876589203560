import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Table } from 'reactstrap';
import moment from 'moment';
import { environment } from '../../../../enviroment/enviroment';
import StatusBadge from '../../Advances/StatusBadge';
import CustomToolTip from 'components/Tooltip/CustomToolTip';
import copyTargetText from 'utils/clipboard';
import removeLeadingTrailingChar from 'utils/removeLeadingTrailingChar';
import CapitalizeFirstLetter from 'utils/CapitalizeFirstLetter';

const AdvanceRequests = ({ employeeId }) => {
  const [advanceRequests, setAdvanceRequests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAdvanceRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdvanceRequests = () => {
    setLoading(true);
    axios
      .get(`${environment.baseUrl}/advancerequests/`, {
        params: { employee: employeeId },
      })
      .then((res) => {
        setAdvanceRequests(res.data);
      });
    setLoading(false);
  };

  return loading ? (
    <Col className="d-flex justify-content-center mt-3">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </Col>
  ) : (
    <>
      <CustomToolTip id={'tooltip-id'}>
        <p>
          For transactions made using{' '}
          <span style={{ fontWeight: 'bold' }}>INSTANT PAYMENT</span>
        </p>
      </CustomToolTip>
      <CustomToolTip id={'tooltip-status'}>
        <p>
          For transactions in the{' '}
          <span style={{ fontWeight: 'bold' }}>FAILED</span> or{' '}
          <span style={{ fontWeight: 'bold' }}>REJECTED</span> state
        </p>
      </CustomToolTip>

      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">UUID</th>
            <th scope="col" id="tooltip-id">
              Stitch ID <i class="fa fa-question-circle" aria-hidden="true" />
            </th>
            <th scope="col">Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Reason Code</th>
            <th scope="col" id="tooltip-status">
              Status Reason{' '}
              <i class="fa fa-question-circle" aria-hidden="true" />
            </th>
            <th scope="col">Status</th>
            <th scope="col">Risky Reason</th>
            <th scope="col">Date Added</th>
          </tr>
        </thead>
        <tbody>
          {advanceRequests.map((advance) => (
            <tr key={advance.uuid}>
              <td>{advance.uuid}</td>
              <td
                onClick={copyTargetText}
                style={{
                  textAlign: advance.source_id ? 'left' : 'center',
                  overflowX: 'scroll',
                }}
              >
                <div style={{ width: '200px' }}>{advance.source_id || '-'}</div>
              </td>
              <td>{advance.instant_payment ? 'Instant' : 'Standard'}</td>
              <td>R {advance.amount_requested}</td>
              <td>{advance.reason}</td>
              <td
                style={{ textAlign: advance.status_reason ? 'left' : 'center' }}
              >
                {advance.status_reason || '-'}
              </td>
              <td>
                <StatusBadge status={advance.status} />
              </td>
              <td
                style={{
                  textAlign: advance.is_risky ? 'left' : 'center',
                }}
              >
                {advance.is_risky ? (
                  <ul>
                    {removeLeadingTrailingChar(advance.risky_reasons, ',')
                      .split(',')
                      .map((reason, index) => (
                        <li key={index}>{CapitalizeFirstLetter(reason)}</li>
                      ))}
                  </ul>
                ) : (
                  '-'
                )}
              </td>
              <td>
                {moment(advance.date_created).format('MMMM Do YYYY, h:mm:ss a')}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default AdvanceRequests;
