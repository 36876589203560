import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Card, CardHeader, Table, Container, Row, Col } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import { environment } from '../../../enviroment/enviroment';
import Pagination from '../../../components/Pagination';
import debounce from 'lodash.debounce';

let cancelToken;

class EmployeeData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      employers: [],
      employer: null,
      loading: false,
      page: 1,
      query: '',
    };
  }

  componentDidMount() {
    this.getEmployees();
    this.getEmployers();
  }

  getEmployees = debounce((params) => {
    // Show loading
    this.setState({ loading: true });
    // Check if there are any previous pending requests
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    // Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    axios
      .get(`${environment.baseUrl}/employees/`, {
        params: {
          employer: this.state.employer,
          page: this.state.page,
          pagination: true,
          ...params,
        },
        cancelToken: cancelToken.token,
      })
      .then((res) => {
        this.setState({
          employees: res.data.results,
          pages: res.data.pages,
          loading: false,
        });
      });
  }, 500);

  getEmployers() {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({
        employers: res.data.filter((emp) => emp.status !== 'deleted'),
      });
    });
  }

  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      query: value,
    });

    this.getEmployees({ search: value });
  };

  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    const arrayCopy = [...this.state.employees];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ employees: arrayCopy });
  }

  setPage = (page) => {
    this.setState({ page }, () => this.getEmployees());
  };

  render() {
    const { employees, employers, loading, page, pages } = this.state;
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6">
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Employees
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col-12 mt-3">
              <Card className="shadow">
                <CardHeader>
                  <Row>
                    <Col style={{ display: 'flex' }}>
                      <input
                        style={{ flex: 1 }}
                        type="text"
                        className="form-control mr-2"
                        placeholder="Search Employees"
                        onChange={this.onChange}
                      />
                      <div style={{ flex: 1 }}>
                        <Select
                          options={
                            employers && [
                              { value: null, label: 'Show all employers' },
                              ...employers.map((employer) => ({
                                value: employer.id,
                                label: employer.company_name,
                              })),
                            ]
                          }
                          defaultValue={{
                            value: null,
                            label: 'Show all employers',
                          }}
                          onChange={(obj) =>
                            this.setState({ employer: obj.value }, () =>
                              this.getEmployees(),
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col>
                      <Link
                        to="/admin/employees/add"
                        className="btn btn-primary float-right"
                      >
                        Add New
                      </Link>
                      <Link
                        to="/admin/employees/import"
                        className="btn float-right mr-2"
                      >
                        Import
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                {loading ? (
                  <Row className="my-3">
                    <Col className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Table
                    className="align-items-center table-flush table-striped"
                    size={'sm'}
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th
                          scope="col"
                          onClick={() => this.sortBy('first_name')}
                        >
                          Name
                        </th>
                        <th scope="col" onClick={() => this.sortBy('employer')}>
                          Employer
                        </th>
                        <th scope="col" onClick={() => this.sortBy('status')}>
                          Status
                        </th>
                        <th
                          scope="col"
                          onClick={() => this.sortBy('pay_cycle')}
                        >
                          Pay cycle
                        </th>
                        <th
                          scope="col"
                          onClick={() => this.sortBy('fee_value')}
                        >
                          Fee
                        </th>
                        <th
                          scope="col"
                          onClick={() => this.sortBy('employee_no')}
                        >
                          Employee No
                        </th>
                        <th
                          scope="col"
                          onClick={() => this.sortBy('date_created')}
                        >
                          Date Added
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {employees.map((employee) => (
                        <tr>
                          <td>{`${employee.first_name} ${employee.last_name}`}</td>
                          <td>{`${
                            employee.employer && employee.employer.company_name
                          }`}</td>
                          <td>
                            {employee.smartwage_status === 'active' && (
                              <span className="badge badge-success">
                                active
                              </span>
                            )}
                            {employee.smartwage_status === 'suspended' && (
                              <span className="badge badge-warning">
                                suspended
                              </span>
                            )}
                            {employee.smartwage_status === 'deleted' && (
                              <span className="badge badge-danger">
                                deleted
                              </span>
                            )}
                          </td>
                          <td>
                            {employee.employee_pay_cycle
                              ? employee.employee_pay_cycle.name
                              : 'No paycycle'}
                          </td>
                          <td>
                            {employee.fee_type === 'amount' && 'R'}
                            {employee.fee_value}
                            {employee.fee_type === 'percent' && '%'}
                          </td>
                          <td>{employee.employee_no}</td>
                          <td>
                            {moment(employee.date_created).format(
                              'MMMM Do YYYY, h:mm:ss a',
                            )}
                          </td>
                          <td>
                            <button
                              type={'button'}
                              className={'btn btn-primary float-right'}
                              onClick={() =>
                                this.props.history.push(
                                  `/admin/employees/${employee.id}`,
                                )
                              }
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card>
            </div>
          </Row>
          <Pagination page={page} pages={pages} setPage={this.setPage} />
        </Container>
      </>
    );
  }
}

export default EmployeeData;
