import React,{useState} from 'react';
import styled from 'styled-components';
import { Tooltip } from 'reactstrap';

const StyledTooltip = styled(Tooltip)`
.tooltip-inner{
  background-color:#535F7F ;
  max-width: 250px;
  text-align: left;
}

.arrow::before{
  border-bottom-color: #535F7F !important;
}
`;


const  CustomToolTip =  ({id, children})=> {
    const [tooltipOpen, setTooltipOpen] = useState(false);
  
    return (
      <StyledTooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={id}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        {children}
      </StyledTooltip>
    )
    
  };

export default CustomToolTip;