import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { environment } from '../../../enviroment/enviroment';
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap';
import moment from 'moment';
import Pagination from '../../../components/Pagination';
import { Context } from '../../../state/GlobalStore';
import SupportModal from './Modals/SupportModal';

class SupportTicketList extends React.Component {
  constructor() {
    super();
    this.state = {
      tickets: [],
      employers: [],
      loading: false,
      showAddEmployee: null,
      createEmployee: {},
      selectedTicket: null,
      query: '',
      status: 'new',
      page: 1,
    };
  }

  static contextType = Context;

  componentDidMount() {
    this.getSupportTickets();
  }

  getSupportTickets(params) {
    this.setState({ loading: true });
    return axios
      .get(`${environment.baseUrl}/supporttickets/`, {
        params: {
          status: this.state.status,
          ...params,
        },
      })
      .then((res) => {
        this.setState({
          tickets: res.data,
          pages: res.data.pages,
          loading: false,
        });
      });
  }

  setPage = (page) => {
    this.setState({ page: page }, () => this.getMessages());
  };

  selectTicket = (data) => {
    this.setState({ selectedTicket: data });
  };

  onModalClose = (reload = true) => {
    if (reload) {
      this.getSupportTickets();
    }
    this.setState({ selectedTicket: null });
  };

  render() {
    const {
      tickets,
      status,
      loading,
      page,
      pages,
      selectedTicket,
    } = this.state;
    return (
      <>
        {selectedTicket && (
          <SupportModal
            supportTicket={selectedTicket}
            onClose={this.onModalClose}
          />
        )}
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg={'6'}>
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Support tickets
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <Col className={'mt-3'}>
              <Card className="shadow">
                <CardHeader>
                  <div style={{ maxWidth: '150px' }}>
                    <select
                      className="form-control mx-2"
                      onChange={(e) =>
                        this.getSupportTickets({
                          status:
                            e.target.value !== 'all' ? e.target.value : null,
                        })
                      }
                    >
                      <option value="all" selected={status === 'all'}>
                        All statuses
                      </option>
                      <option value="new" selected={status === 'new'}>
                        New
                      </option>
                      <option
                        value="in_progress"
                        selected={status === 'in_progress'}
                      >
                        In progress
                      </option>
                      <option value="resolved" selected={status === 'resolved'}>
                        Resolved
                      </option>
                    </select>
                  </div>
                </CardHeader>
                {loading ? (
                  <Row className={'my-3'}>
                    <Col className={'d-flex justify-content-center'}>
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Table
                    className="align-items-center table-flush "
                    style={{
                      backgroundColor: 'white',
                      borderBottom: '0.5pt solid #f7fafc',
                    }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Message</th>
                        <th scope="col">Employee</th>
                        <th scope="col">Status</th>
                        <th scope="col">Note</th>
                        <th scope="col">Date created</th>
                        <th scope="col">Date updated</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.map((ticket) => (
                        <>
                          <tr>
                            <td>{ticket.text_content}</td>
                            <td>
                              <Link
                                to={`/admin/employees/${ticket.employee.id}`}
                              >
                                {ticket.employee.first_name}{' '}
                                {ticket.employee.last_name}
                              </Link>
                            </td>
                            <td>
                              {ticket.status === 'new' && (
                                <span className={'badge badge-success'}>
                                  New
                                </span>
                              )}
                              {ticket.status === 'in_progress' && (
                                <span className={'badge badge-warning'}>
                                  In progress
                                </span>
                              )}
                              {ticket.status === 'resolved' && (
                                <span className={'badge'}>Resolved</span>
                              )}
                            </td>
                            <td>
                              {ticket.note
                                ? ticket.note.slice(0, 30)
                                : 'No note'}
                              {ticket.note && ticket.note.length > 30 && '...'}
                            </td>
                            <td>
                              {moment(ticket.date_created).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </td>
                            <td>
                              {moment(ticket.date_updated).format(
                                'MMMM Do YYYY, h:mm:ss a',
                              )}
                            </td>
                            <td align={'right'}>
                              <>
                                <Button
                                  className={'btn btn-secondary'}
                                  onClick={() => this.selectTicket(ticket)}
                                >
                                  Select
                                </Button>
                              </>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card>
            </Col>
          </Row>
          <Pagination page={page} pages={pages} setPage={this.setPage} />
        </Container>
      </>
    );
  }
}

export default SupportTicketList;
