import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const MaintenancePage = () => {
  return (
    <div
      style={{ margin: '25vh auto', maxWidth: '400px', textAlign: 'center' }}
    >
      <FontAwesomeIcon size="7x" icon={faTools} />
      <h1 style={{ marginTop: '28px', fontSize: '32px' }}>
        We'll be back soon!
      </h1>
      <p style={{ marginBottom: '0px' }}>Sorry, we're down for maintenance.</p>
      <small>We'll be back shortly</small>
    </div>
  );
};

export default MaintenancePage;
