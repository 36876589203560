import React from 'react';
import { useEnabledFeatureFlag } from 'utils/feature-flagging/FeatureFlagProvider';
import FeatureFlags from 'utils/feature-flagging/FeatureFlags';

const FlaggedDeleteButton = (props: any) => {
  const flagStatus = useEnabledFeatureFlag(
    FeatureFlags['ADMIN_PORTAL_DELETE_EMPLOYER_FEATURE_FLAG_TEMP'],
  );

  return flagStatus && <button {...props}>Delete</button>;
};

export default FlaggedDeleteButton;
