import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import FeatureFlags from 'utils/feature-flagging/FeatureFlags';
import { useEnabledFeatureFlag } from 'utils/feature-flagging/FeatureFlagProvider';

import axios from 'axios';
import { Row, Col, Input } from 'reactstrap';
import { environment } from 'enviroment/enviroment';
import EmployeePayslipUpdate from './EmployeePayslipUpdate';
import EmployeePayslipUploadModal from './EmployeePayslipUploadModal';
import apiResponseHandler from 'utils/apiResponseHandler';
import Swal from 'sweetalert2';

const ManageEmployeePayslips = (props) => {
  const flagReuploadPayslipsTemp = useEnabledFeatureFlag(
    FeatureFlags.ADMIN_PORTAL_REUPLOAD_PAYSLIPS_FEATURE_FLAG_TEMP,
  );

  const [employeePayslips, setEmployeePayslips] = useState([]);

  const [displayPayslips, setDisplayPayslips] = useState([]);

  const [displaySelectOption, setDisplaySelectOption] = useState();

  const [selectedPayslip, setSelectedPayslip] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const updateModalState = (isOpen, isRefresh = false) => {
    showUpdatedPayslip(isRefresh);
    setShowUploadModal(isOpen);
  };

  const getEmployeePayslips = useCallback((employeeId, isRefresh = false) => {
    setIsLoading(true);
    setSelectedPayslip(null);
    setDisplaySelectOption('hidden');
    setEmployeePayslips([]);
    setDisplayPayslips([]);

    const dateTimeOptions = {
      day: '2-digit',
      year: 'numeric',
      month: '2-digit',
      hour: '2-digit',
      hour12: true,
      minute: '2-digit',
      second: '2-digit',
    };

    axios
      .get(
        `${environment.baseUrl}/employee_payslips/?employee_id=${employeeId}&verification_status=success`,
      )
      .then((res) => {
        setEmployeePayslips(res.data.results);

        const payslips = res.data.results.map((payslip) => ({
          label: `\u00A0 📅 \u00A0 ${
            payslip.date_issued
          }  \u00A0  \u00A0 \u00A0  \u00A0 ⏰ \u00A0 \u00A0${new Date(
            payslip.date_of_distribution,
          )
            .toLocaleDateString('en-ZA', dateTimeOptions)
            .replace(/\//g, '-')}`,
          value: payslip.id,
        }));

        setDisplayPayslips(payslips);

        if (isRefresh && res.data.results.length > 0) {
          const _displayPayslips = res.data.results.map((payslip) => ({
            value: payslip.id,
            label: payslip.date_issued,
          }));

          const _payslip = res.data.results[0];

          setDisplaySelectOption(_displayPayslips[0]);

          setSelectedPayslip(_payslip);
        }

        setIsLoading(false);
      });
  }, []);

  const viewPayslip = (payslipId) => {
    setSelectedPayslip(null);
    setIsLoading(true);
    const _payslip = employeePayslips.find(
      (eP) => eP.id === parseInt(payslipId),
    );
    setSelectedPayslip(_payslip);
    setIsLoading(false);
  };

  const showUpdatedPayslip = async (isRefresh) => {
    if (isRefresh) {
      getEmployeePayslips(props.employeeData.id, isRefresh);
    }
  };

  const refreshPayslipPasswords = () => {
    setIsLoading(true);
    axios
      .post(
        `${environment.baseUrl}/employees/${props.employeeData.id}/refresh_payslip_passwords/`,
      )

      .then(() => {
        Swal.fire({
          title: 'Payslip Passwords Refreshed',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getEmployeePayslips(props.employeeData.id);
  }, [props, getEmployeePayslips]);

  return (
    <div style={{ padding: '16px' }}>
      {showUploadModal && (
        <EmployeePayslipUploadModal
          isCreateNew={true}
          payslipId={null}
          employeeData={props.employeeData}
          updateModalState={updateModalState}
        />
      )}

      <Row>
        <Col lg="6">
          <Input
            placeholder="Select Payslip"
            value={displaySelectOption}
            onChange={(obj) => {
              viewPayslip(obj.target.value);
              setDisplaySelectOption(obj.target.value);
            }}
            type="select"
            disabled={isLoading}
          >
            <option value="" hidden>
              {displayPayslips.length === 0
                ? 'No Payslip Available'
                : 'Select Payslip'}
            </option>
            {displayPayslips.map((paydisplay) => (
              <option value={paydisplay.value}> {paydisplay.label}</option>
            ))}
          </Input>
        </Col>

        <Col lg="6">
          <button
            type="submit"
            disabled={isLoading}
            className="btn btn-success float-right"
            style={{
              minWidth: '200px',
              marginBottom: '3px',
              marginRight: '8px',
            }}
            onClick={() => setShowUploadModal(true)}
          >
            Upload New Payslip
          </button>
        </Col>
      </Row>

      {flagReuploadPayslipsTemp && !selectedPayslip && (
        <Row className="float-right">
          <Col lg={6}>
            <button
              disabled={isLoading}
              type="submit"
              className="btn btn-primary"
              style={{
                minWidth: '200px',
                marginTop: '8px',
                marginBottom: '3px',
                marginRight: '8px',
              }}
              onClick={refreshPayslipPasswords}
            >
              Refresh Passwords
            </button>
          </Col>
        </Row>
      )}
      {isLoading && (
        <Row className="my-3">
          <Col className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </Col>
        </Row>
      )}

      <div style={{ padding: '4px' }}>
        {!isLoading && selectedPayslip && (
          <EmployeePayslipUpdate
            showUpdatedPayslip={showUpdatedPayslip}
            employeeData={props.employeeData}
            selectedPayslip={selectedPayslip}
            getEmployeePayslips={getEmployeePayslips}
            employeeId={props.employeeData.id}
          />
        )}
      </div>
    </div>
  );
};

export default ManageEmployeePayslips;
