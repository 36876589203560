import React from 'react';
import axios from 'axios';
import { environment } from '../../../enviroment/enviroment';
import { Card, Table, Container, Row, Col } from 'reactstrap';
import { TableCellHighlight } from '../../../components/styled';
import Swal from 'sweetalert2';
import apiResponseHandler from 'utils/apiResponseHandler';

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getPermissionData();
  }

  getPermissionData = () => {
    this.setState({ loading: true });
    axios.get(`${environment.baseUrl}/permissions/`).then((res) => {
      this.setState({ users: res.data, loading: false });
    });
  };

  onChangePermissionActive = (userId, permissionId, active) => {
    axios
      .patch(`${environment.baseUrl}/permissions/`, {
        id: permissionId,
        user_id: userId,
        active: active,
      })
      .then((res) => {
        Swal.fire({
          title: `Permissions updated`,
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.getPermissionData();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  render() {
    const { users, loading } = this.state;
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg={'6'}>
                  <h6 className="h2 text-white d-inline-block mb-0">
                    User permissions
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className={'mt--7'} fluid>
          <Row>
            <div className="col-12 mt-3">
              <Card className="shadow">
                {loading ? (
                  <Row className={'my-3'}>
                    <Col className={'d-flex justify-content-center'}>
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Table
                    className="table align-items-center table-flush table-bordered"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th scope={'col'}>Name</th>
                        {users.length > 0 &&
                          users[0].user_permissions.map((perm) => (
                            <th className={'text-center'} scope={'col'}>
                              {perm.name}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {users &&
                        users.map((user) => (
                          <tr>
                            <td>{`${user.first_name} ${user.last_name}`}</td>
                            {user.user_permissions.map((perm) => (
                              <TableCellHighlight
                                onClick={() =>
                                  this.onChangePermissionActive(
                                    user.id,
                                    perm.id,
                                    !perm.active,
                                  )
                                }
                              >
                                {perm.active ? (
                                  <i className="fas fa-check-circle text-lg text-success"></i>
                                ) : (
                                  <i className="fas fa-times-circle text-lg text-warning"></i>
                                )}
                              </TableCellHighlight>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default UserList;
