import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, Input, Table, Alert } from 'reactstrap';
import axios from 'axios';
import { environment } from '../../../enviroment/enviroment';
import Swal from 'sweetalert2';
import apiResponseHandler from 'utils/apiResponseHandler';

const validateInputs = (data, setValidation, validation) => {
  const errors = [];

  if (data?.fee_type === '' || data?.fee_type === undefined) {
    errors.push('Fee Type is a required field.');
  }

  if (!data?.fee_percentage || data?.fee_percentage === undefined) {
    errors.push('Percentage is a required field.');
  }
  if (!data?.fee_amount || data?.fee_amount === undefined) {
    errors.push('Amount is a required field.');
  }

  setValidation({
    ...validation,
    errors: errors,
    formError: errors.length > 0,
    triedSubmitting: true,
  });

  return errors.length < 1;
};

const TransactionFee = ({
  transactionFee,
  getTransactionFees,
  employerId,
  validation,
  setValidation,
}) => {
  const [edit, setEdit] = useState(false);
  const [saveData, setSaveData] = useState({});

  useEffect(() => {
    setSaveData(transactionFee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = () => {
    if (validateInputs(saveData, setValidation, validation)) {
      axios
        .patch(`${environment.baseUrl}/transactionfees/${transactionFee.id}/`, {
          ...saveData,
        })
        .then(() => {
          Swal.fire({
            title: 'Successfully saved transaction fee',
            icon: 'success',
          });
          getTransactionFees();
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
      setEdit(false);
    }
  };

  const makeDefault = () => {
    axios
      .post(
        `${environment.baseUrl}/transactionfees/${transactionFee.id}/setDefault/`,
      )
      .then(() => {
        Swal.fire({
          title: 'Successfully saved transaction fee',
          icon: 'success',
        });
        getTransactionFees();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
    setEdit(false);
  };

  const deleteTransactionFee = (id) => {
    axios
      .delete(`${environment.baseUrl}/transactionfees/${id}/`)
      .then(() => {
        Swal.fire({
          title: 'Successfully deleted transaction fee',
          icon: 'success',
        });
        getTransactionFees();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  const onChange = (e) => {
    const newData = { ...saveData };
    newData[e.target.name] = e.target.value;
    setSaveData(newData);
  };

  return (
    <tr key={transactionFee.id}>
      <td>{transactionFee.id}</td>
      <td>
        {transactionFee.is_default ? (
          <span className={'badge badge-success'}>Yes</span>
        ) : (
          <span className={'badge badge-danger'}>No</span>
        )}
      </td>
      <td>
        {edit ? (
          <Input
            id="fee_type"
            name="fee_type"
            type="select"
            size={'sm'}
            style={{ width: '150px' }}
            disabled
          >
            <option
              value="percent"
              selected={transactionFee.fee_type === 'percent'}
            >
              Percent
            </option>
            <option
              value="amount"
              selected={transactionFee.fee_type === 'amount'}
            >
              Fixed Amount
            </option>
            <option
              value="percentage_and_amount"
              selected={transactionFee.fee_type === 'percentage_and_amount'}
            >
              Percent and amount (hybrid)
            </option>
          </Input>
        ) : (
          `${saveData.fee_type}`
        )}
      </td>
      <td>
        {edit ? (
          <Input
            size={'sm'}
            type="number"
            step=".01"
            value={saveData.fee_percentage}
            name={'fee_percentage'}
            onChange={onChange}
            invalid={validation.triedSubmitting && !saveData?.fee_percentage}
          />
        ) : (
          `${transactionFee.fee_percentage}%`
        )}
      </td>
      <td>
        {edit ? (
          <Input
            size={'sm'}
            type="number"
            step=".01"
            value={saveData.fee_amount}
            name={'fee_amount'}
            onChange={onChange}
            invalid={validation.triedSubmitting && !saveData?.fee_amount}
          />
        ) : (
          `R${transactionFee.fee_amount}`
        )}
      </td>
      <td align={'right'}>
        {edit ? (
          <button className={'btn btn-sm btn-primary'} onClick={() => save()}>
            Save
          </button>
        ) : (
          <button
            className={'btn btn-sm btn-primary'}
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        )}

        {!transactionFee.is_default && (
          <button
            className={'btn btn-sm btn-primary'}
            onClick={() => makeDefault()}
          >
            Make default
          </button>
        )}
        <button
          className={'btn btn-sm btn-danger'}
          onClick={() => deleteTransactionFee(transactionFee.id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

const TransactionFeesTable = ({ employerId }) => {
  const [transactionFees, setTransactionFees] = useState([]);
  const [addNewTransactionFees, setAddNewTransactionFees] = useState(false);
  const [createData, setCreateData] = useState();
  const [validation, setValidation] = useState({
    triedSubmitting: false,
    formError: false,
    errors: [],
  });

  useEffect(() => {
    getTransactionFees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTransactionFees = () => {
    axios
      .get(`${environment.baseUrl}/transactionfees/`, {
        params: { employer_id: employerId },
      })
      .then((res) => {
        setTransactionFees(res.data);
      });
  };

  const createNewTransactionFee = () => {
    if (validateInputs(createData, setValidation, validation)) {
      axios
        .post(`${environment.baseUrl}/transactionfees/`, {
          ...createData,
          employer_id: employerId,
        })
        .then(() => {
          Swal.fire({
            title: 'Successfully created transaction fee',
            icon: 'success',
          });
          getTransactionFees();
          setAddNewTransactionFees(false);
          setCreateData({});
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
    }
  };

  return (
    <Card className="shadow">
      <CardHeader>
        <strong>Transaction fees</strong>
        <Button
          color="primary"
          onClick={() => {
            setAddNewTransactionFees(true);
            setCreateData({
              employer: employerId,
              fee_type: '',
              fee_percentage: 0,
              fee_amount: 0,
            });
          }}
          className="btn btn-primary btn-sm float-right"
        >
          Add transaction fee
        </Button>
      </CardHeader>

      {validation.formError && validation.errors && (
        <Alert color="danger">
          {validation.errors.map((error) => {
            return <li key={error}>{error}</li>;
          })}
        </Alert>
      )}

      <Table responsive striped size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Default</th>
            <th className={addNewTransactionFees ? 'required-input' : ''}>
              Fee type
            </th>
            <th className={addNewTransactionFees ? 'required-input' : ''}>
              Percentage
            </th>
            <th className={addNewTransactionFees ? 'required-input' : ''}>
              Amount
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {transactionFees.map((transactionFee) => (
            <TransactionFee
              transactionFee={transactionFee}
              getTransactionFees={getTransactionFees}
              validation={validation}
              setValidation={setValidation}
            />
          ))}
          {addNewTransactionFees && (
            <tr>
              <td />
              <td />
              <td>
                <Input
                  id="fee_type"
                  name="fee_type"
                  type="select"
                  size={'sm'}
                  style={{ width: '150px' }}
                  onChange={(e) =>
                    setCreateData({
                      ...createData,
                      fee_type: e.target.value,
                    })
                  }
                  invalid={validation.triedSubmitting && !createData?.fee_type}
                >
                  <option disabled selected>
                    Choose Fee type
                  </option>
                  <option value="percent">Percent</option>
                  <option value="amount">Fixed Amount</option>
                  <option value="percentage_and_amount">
                    Percent and amount (hybrid)
                  </option>
                </Input>
              </td>
              <td>
                <Input
                  size={'sm'}
                  type="number"
                  step=".01"
                  defaultValue={0}
                  onChange={(e) =>
                    setCreateData({
                      ...createData,
                      fee_percentage: e.target.value,
                    })
                  }
                  invalid={
                    validation.triedSubmitting && !createData?.fee_percentage
                  }
                />
              </td>
              <td>
                <Input
                  size={'sm'}
                  type="number"
                  step=".01"
                  defaultValue={0}
                  onChange={(e) =>
                    setCreateData({
                      ...createData,
                      fee_amount: e.target.value,
                    })
                  }
                  invalid={
                    validation.triedSubmitting && !createData?.fee_amount
                  }
                />
              </td>
              <td>
                <button
                  className={'btn btn-sm btn-primary'}
                  onClick={() => createNewTransactionFee()}
                >
                  Create
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Card>
  );
};

export default TransactionFeesTable;
