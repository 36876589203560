import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  CardBody,
  Collapse,
  CardHeader,
  FormGroup,
  Col,
  Label,
  Input,
  Container,
  Row,
  Alert,
} from 'reactstrap';
import Swal from 'sweetalert2';
import InputToggle from '../../../components/InputToggle';
import { environment } from '../../../enviroment/enviroment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import FeatureFlags from '../../../utils/feature-flagging/FeatureFlags';
import { useEnabledFeatureFlag } from '../../../utils/feature-flagging/FeatureFlagProvider';

const INVOICE_TYPES = ['odp', 'subscription']; // TODO: Change subscription to hrc
const ACCOUNT_IDS = [
  { value: 201, name: '201 - EWA Transaction Fee Revenue' },
  { value: 202, name: '202 - Payslip Revenue' },
  { value: 615, name: '615 - Employer Receivable' },
];

const validateInputs = (data, setValidation, validation) => {
  const errors = [];

  if (data?.customer_name?.trim() === '' || data?.customer_name === undefined) {
    errors.push('Employer Name is a required field.');
  }

  if (!data?.billing_start_date || data?.billing_start_date === undefined) {
    errors.push('Billing Start Date is a required field.');
  }
  if (!data?.xero_account_id || data?.xero_account_id === undefined) {
    errors.push('Account is a required field.');
  }
  if (!data?.invoice_day || data?.invoice_day === undefined) {
    errors.push('Invoice Day is a required field.');
  }
  if (!data?.grace_period_days || data?.grace_period_days === undefined) {
    errors.push('Grace Period Days is a required field.');
  }
  if (!data?.base_cost || data?.base_cost === undefined) {
    errors.push('Base Cost is a required field.');
  }

  setValidation({
    ...validation,
    errors: errors,
    formError: errors.length > 0,
    triedSubmitting: true,
  });

  return errors.length < 1;
};

const InvoiceForm = ({ invoice, invoiceType, employerId }) => {
  const [invoiceNewData, setInvoiceNewData] = useState({});
  const [openEditCollapse, setOpenEditCollapse] = useState(false);
  const [disableButton, setButtonDisabled] = useState(false);
  const [validation, setValidation] = useState({
    triedSubmitting: false,
    formError: false,
    errors: [],
  });

  const createInvoiceConfig = () => {
    if (validateInputs(invoiceNewData, setValidation, validation)) {
      setButtonDisabled(true);
      axios
        .post(`${environment.baseUrl}/employer_invoice_config/`, {
          ...invoiceNewData,
          employer: employerId,
          enabled: false,
          tax_inclusive: invoiceNewData.tax_inclusive
            ? invoiceNewData.tax_inclusive
            : false,
        })
        .then((res) => {
          Swal.fire({
            title: 'Successfully created employer configuration',
            icon: 'success',
          });
          setButtonDisabled(false);
          window.location.reload();
        })
        .catch((e) => {
          setButtonDisabled(false);
        });
    }
  };

  const updateInvoiceConfig = ({ invoiceConfigId }) => {
    if (validateInputs(invoiceNewData, setValidation, validation)) {
      setButtonDisabled(true);
      delete invoiceNewData.invoice_type;
      delete invoiceNewData.enabled;
      axios
        .put(
          `${environment.baseUrl}/employer_invoice_config/${invoiceConfigId}/`,
          {
            ...invoiceNewData,
            employer_id: employerId,
          },
        )
        .then((res) => {
          Swal.fire({
            title: 'Successfully updated employer configuration',
            icon: 'success',
          });
          setButtonDisabled(false);
        })
        .catch((e) => {
          setButtonDisabled(false);
        });
    }
  };

  useEffect(() => {
    if (invoice) {
      setInvoiceNewData(invoice);
    }
  }, [invoice]);

  useEffect(() => {
    if (invoiceType) {
      setInvoiceNewData((i) => ({ ...i, invoice_type: invoiceType }));
    }
  }, [invoiceType]);

  return (
    invoiceNewData && (
      <CardBody>
        <Container
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Button
            outline
            onClick={() => setOpenEditCollapse(!openEditCollapse)}
            size="lg"
          >
            {invoice ? (
              <FontAwesomeIcon
                icon={openEditCollapse ? faChevronUp : faChevronDown}
                size="lg"
                onClick={() => setOpenEditCollapse(!openEditCollapse)}
              />
            ) : (
              <FontAwesomeIcon
                icon={openEditCollapse ? faMinus : faPlus}
                size="lg"
                onClick={() => setOpenEditCollapse(!openEditCollapse)}
              />
            )}
          </Button>
        </Container>
        <Collapse isOpen={openEditCollapse}>
          {validation.formError && validation.errors && (
            <Alert color="danger">
              {validation.errors.map((error) => {
                return <li key={error}>{error}</li>;
              })}
            </Alert>
          )}

          <Row>
            <Col>
              <FormGroup>
                <Label for="customerName">Employer Name (On Xero)</Label>
                <Input
                  required
                  invalid={
                    validation.triedSubmitting && !invoiceNewData.customer_name
                  }
                  id="customerName"
                  type="text"
                  name="customer_name"
                  value={invoiceNewData.customer_name}
                  onChange={(value) =>
                    setInvoiceNewData({
                      ...invoiceNewData,
                      customer_name: value.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Employer Name (On Xero)"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="billingStartDate">Billing Start Date</Label>
                <Input
                  required
                  invalid={
                    validation.triedSubmitting &&
                    !invoiceNewData.billing_start_date
                  }
                  id="billingStartDate"
                  type="date"
                  name="billing_start_date"
                  value={invoiceNewData.billing_start_date}
                  onChange={(value) =>
                    setInvoiceNewData({
                      ...invoiceNewData,
                      billing_start_date: value.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Billing Start Date"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="accountType">Account</Label>
                <Input
                  required
                  invalid={
                    validation.triedSubmitting &&
                    !invoiceNewData.xero_account_id
                  }
                  id="accountType"
                  type="select"
                  name="account"
                  value={invoiceNewData.xero_account_id}
                  onChange={(value) =>
                    setInvoiceNewData({
                      ...invoiceNewData,
                      xero_account_id: value.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Account"
                >
                  <option value={null} />
                  {ACCOUNT_IDS.map((account) => (
                    <option value={account.value}>{account.name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="invoiceDay">Invoice Day</Label>
                <Input
                  required
                  invalid={
                    validation.triedSubmitting && !invoiceNewData.invoice_day
                  }
                  id="invoiceDay"
                  type="number"
                  min="0"
                  name="invoice_day"
                  value={invoiceNewData.invoice_day}
                  onChange={(value) =>
                    setInvoiceNewData({
                      ...invoiceNewData,
                      invoice_day: value.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Invoice Day"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="gracePeriodDays">Grace Period Days</Label>
                <Input
                  required
                  invalid={
                    validation.triedSubmitting &&
                    !invoiceNewData.grace_period_days
                  }
                  id="gracePeriodDays"
                  type="number"
                  min="0"
                  name="grace_period_days"
                  value={invoiceNewData.grace_period_days}
                  onChange={(value) =>
                    setInvoiceNewData({
                      ...invoiceNewData,
                      grace_period_days: value.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Grace Period Days"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="baseCost">Base Cost</Label>
                <Input
                  required
                  invalid={
                    validation.triedSubmitting && !invoiceNewData.base_cost
                  }
                  id="baseCost"
                  type="number"
                  name="base_cost"
                  value={invoiceNewData.base_cost}
                  onChange={(value) =>
                    setInvoiceNewData({
                      ...invoiceNewData,
                      base_cost: value.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Base Cost (VAT Exclusive)"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Col>
                  <Label for="taxInclusive">Tax Inclusive</Label>
                </Col>
                <Col>
                  <InputToggle
                    id="taxInclusive"
                    checked={invoiceNewData.tax_inclusive || false}
                    onChange={() =>
                      setInvoiceNewData({
                        ...invoiceNewData,
                        tax_inclusive: !invoiceNewData.tax_inclusive,
                      })
                    }
                    offstyle="btn-danger"
                    onstyle="btn-success"
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {invoice ? (
                <Button
                  disabled={disableButton}
                  loa
                  onClick={() =>
                    updateInvoiceConfig({ invoiceConfigId: invoice.id })
                  }
                >
                  Update Invoice
                </Button>
              ) : (
                <Button
                  disabled={disableButton}
                  loa
                  onClick={() => createInvoiceConfig()}
                >
                  Create Invoice
                </Button>
              )}
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    )
  );
};

const InvoiceConfig = ({ employerId }) => {
  const [invoiceConfig, setInvoiceConfig] = useState();
  const [toggleState, setToggleState] = useState();
  const [missingInvoiceTypes, setMissingInvoiceTypes] = useState([]);

  const flagAutomatedInvoicingTemp = useEnabledFeatureFlag(
    FeatureFlags.ADMIN_PORTAL_AUTOMATED_INVOICING_FEATURE_FLAG_TEMP,
  );

  const getMissingInvoicesArr = () => {
    let invoiceTypeArr = invoiceConfig.map((invoice) => invoice.invoice_type);
    let missingTypes = [];
    INVOICE_TYPES.forEach((element) => {
      const hasType = invoiceTypeArr.indexOf(element);
      if (hasType < 0) {
        missingTypes.push(element);
      }
    });
    setMissingInvoiceTypes(missingTypes);
  };

  const getEmployerInvoices = () => {
    axios
      .get(`${environment.baseUrl}/employer_invoice_config/`, {
        params: { employer: employerId },
      })
      .then((res) => {
        setInvoiceConfig(res.data);
      });
  };

  const setInvoiceEnabled = ({ invoiceConfigId, value, togglePosition }) => {
    axios
      .patch(
        `${environment.baseUrl}/employer_invoice_config/${invoiceConfigId}/`,
        {
          enabled: value,
        },
      )
      .then((res) => {
        let newToggles = [...toggleState];
        newToggles[togglePosition] = value;
        setToggleState(newToggles);
      });
  };

  useEffect(() => {
    getEmployerInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoiceConfig) {
      getMissingInvoicesArr();
      let togglesArr = [];
      for (let index = 0; index < invoiceConfig.length; index++) {
        togglesArr[index] = invoiceConfig[index].enabled;
      }
      setToggleState(togglesArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceConfig]);

  return flagAutomatedInvoicingTemp && invoiceConfig && toggleState ? (
    <Row>
      <Col>
        {invoiceConfig
          .sort((a, b) => a.invoice_type.localeCompare(b.invoice_type))
          .map((invoice, i) => (
            <Row className="mb-4">
              <Col>
                <Card className="shadow">
                  <CardHeader>
                    <Row
                      className="ml-1 mr-1"
                      style={{ justifyContent: 'space-between' }}
                    >
                      <strong>
                        {invoice.invoice_type.toUpperCase()} Invoice
                        Configuration
                      </strong>
                      <InputToggle
                        checked={toggleState[i]}
                        onChange={() => {
                          setInvoiceEnabled({
                            invoiceConfigId: invoice.id,
                            value: !toggleState[i],
                            togglePosition: i,
                          });
                        }}
                        offstyle="btn-danger"
                        onstyle="btn-success"
                      />
                    </Row>
                  </CardHeader>
                  <InvoiceForm invoice={invoice} employerId={employerId} />
                </Card>
              </Col>
            </Row>
          ))}
        {missingInvoiceTypes &&
          missingInvoiceTypes.length > 0 &&
          missingInvoiceTypes.map((missingInvoice) => (
            <Row className="mb-4">
              <Col>
                <Card className="shadow">
                  <CardHeader>
                    <strong>
                      {missingInvoice.toUpperCase()} Invoice Configuration
                    </strong>
                  </CardHeader>
                  <InvoiceForm
                    invoiceType={missingInvoice}
                    employerId={employerId}
                  />
                </Card>
              </Col>
            </Row>
          ))}
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default InvoiceConfig;
