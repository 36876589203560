const ADMIN_PORTAL_AUTOMATED_INVOICING_FEATURE_FLAG_TEMP =
  'adminportal.automatedinvoicing.temp';
const ADMIN_PORTAL_REUPLOAD_PAYSLIPS_FEATURE_FLAG_TEMP =
  'adminportal.reuploadpayslips.temp';

const ADMIN_PORTAL_DELETE_EMPLOYER_FEATURE_FLAG_TEMP =
  'adminportal.delete_employer.temp';

const ADMIN_PORTAL_TOOLS_FEATURE_FLAG_TEMP = 'adminportal.tools.temp';
const ADMIN_PORTAL_TOOLS_MERGE_PROFILES_FEATURE_FLAG_TEMP =
  'adminportal.tools_merge_profiles.temp';

export default {
  ADMIN_PORTAL_AUTOMATED_INVOICING_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_REUPLOAD_PAYSLIPS_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_DELETE_EMPLOYER_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_TOOLS_FEATURE_FLAG_TEMP,
  ADMIN_PORTAL_TOOLS_MERGE_PROFILES_FEATURE_FLAG_TEMP,
};
