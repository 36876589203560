import React from 'react';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';
import { environment } from '../../../enviroment/enviroment';
import { Context } from '../../../state/GlobalStore';

class OtpLogIn extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      loginType: null,
      loading: false,
      failed: false,
    };
  }

  loginWithTimeBasedOtp = () => {
    this.setState({ loading: true });
    axios
      .post(
        `${environment.baseUrl}/auth/login/totp/`,
        { username: this.state.username, totp_code: this.state.otp },
        { withCredentials: true },
      )
      .then((res) => {
        const { logInUser } = this.context;
        logInUser(res.data);
        this.props.history.push('/admin/employers');
      })
      .catch((error) => {
        this.setState({ loading: false, failed: true });
        console.log(error);
      });
  };

  render() {
    const { loading, failed, loginType, otp, username } = this.state;
    return (
      <div>
        <>
          <section className="dash-login">
            <Container>
              <Row>
                <Col className="logo">
                  <img alt="" src={require('../../../assets/img/logo.png')} />
                </Col>
              </Row>
              {failed && (
                <Row className="my-2">
                  <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <small style={{ color: 'red' }}>Login failed</small>
                  </Col>
                </Row>
              )}
              {loginType === null && (
                <Row className="pt-2">
                  <Col>
                    <button
                      className="btn btn-primary"
                      style={{ width: '100%' }}
                      onClick={(_e) => this.setState({ loginType: 'totp' })}
                      type="button"
                    >
                      Login with TOTP
                    </button>
                  </Col>
                </Row>
              )}
              {loginType === 'totp' && (
                <Row>
                  <Col md="12" className="m-auto login-form">
                    <Row>
                      <Col md="12">
                        <input
                          type="text"
                          className="form-control"
                          value={username}
                          onChange={(event) =>
                            this.setState({ username: event.target.value })
                          }
                          name="username"
                          placeholder="User Name"
                        />
                      </Col>
                    </Row>
                    <Row className="pt-3">
                      <Col md="12">
                        <input
                          type="number"
                          className="form-control"
                          value={otp}
                          onChange={(event) =>
                            this.setState({ otp: event.target.value })
                          }
                          name="otp"
                          placeholder="Time based OTP"
                        />
                      </Col>
                    </Row>
                    <Row className="pt-2">
                      <Col md="12">
                        <button
                          className="btn btn-primary"
                          style={{ width: '100%' }}
                          onClick={this.loginWithTimeBasedOtp}
                          type="button"
                        >
                          {loading ? (
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            'Login'
                          )}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
        </>
      </div>
    );
  }
}

export default OtpLogIn;
