/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Card, CardHeader, Table, Container, Row, Col } from 'reactstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import { environment } from '../../../enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';

class EmployersList extends React.Component {
  state = {
    employers: [],
    loading: false,
    query: '',
    show_deleted: false,
  };

  componentDidMount() {
    this.getEmployers();
    this.search('');
  }

  deleteEmployer(id, name, deleting = true) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to ${
        deleting ? 'delete' : 'undelete'
      } ${name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: `Yes, ${deleting ? 'delete' : 'undelete'} it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${environment.baseUrl}/employers/${id}/`)
          .then((res) => {
            Swal.fire({
              title: `${name} ${deleting ? 'deleted' : 'undeleted'}`,
              icon: 'success',
            });
            this.getEmployers();
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  }

  getEmployers() {
    this.setState({ loading: true });
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    axios.get(`${environment.baseUrl}/employers/`, { headers }).then((res) => {
      this.setState({
        employers: res.data,
        loading: false,
      });
    });
  }

  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      query: value,
    });

    this.search(value);
  };

  search = (query) => {
    axios
      .get(`${environment.baseUrl}/employers/`, { params: { search: query } })
      .then((res) => {
        this.setState({
          employers: res.data,
          loading: false,
        });
      });
  };

  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    const arrayCopy = [...this.state.employers];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ employers: arrayCopy });
  }

  // eslint-disable-next-line consistent-return
  getDefaultPayRoll = (employerPayCycles) => {
    if (employerPayCycles.length) {
      const defaultPayCycle = employerPayCycles.find(
        (payCycle) => payCycle.is_default,
      );

      if (defaultPayCycle) {
        return <td key={defaultPayCycle.id}>{defaultPayCycle.name}</td>;
      }
    }
    return <td>{''}</td>;
  };

  getDefaultFeeModel = (subscriptions, transactionFees) => {
    const defaultSubscription = subscriptions.find((sub) => sub.default);
    if (defaultSubscription) return <td>{defaultSubscription.name}</td>;

    const defaultTransactionFee = transactionFees.find((txn) => txn.is_default);
    if (defaultTransactionFee)
      return (
        <td>{`(${defaultTransactionFee.id}) ${defaultTransactionFee.fee_type} - R${defaultTransactionFee.fee_amount} + ${defaultTransactionFee.fee_percentage}%`}</td>
      );

    return <td>{''}</td>;
  };

  render() {
    const { employers, loading, show_deleted } = this.state;

    return (
      <>
        <>
          <div className="header bg-primary pb-6">
            <Container fluid>
              <div className="header-body">
                <Row className="align-items-center py-4">
                  <Col lg="6">
                    <h6 className="h2 text-white d-inline-block mb-0">
                      Employers
                    </h6>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container fluid className="mt--7">
            <Row>
              <Col className="mt-3">
                <Card className="shadow">
                  <CardHeader>
                    <Link
                      to="/admin/employers/add"
                      className="btn btn-primary float-right"
                    >
                      Add New
                    </Link>
                    <div style={{ width: '20%' }}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Employers"
                        onChange={this.onChange}
                      />
                    </div>
                  </CardHeader>
                  {loading ? (
                    <Row className="my-3">
                      <Col className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Table
                      className="align-items-center table-flush table-striped"
                      size={'sm'}
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th
                            scope="col"
                            onClick={() => this.sortBy('company_name')}
                          >
                            Company
                          </th>
                          <th scope="col">Status</th>
                          <th scope="col">Default fee Model</th>
                          <th scope="col">Default pay cycle</th>
                          <th scope="col">Default risk metric</th>
                          <th scope="col">Date created</th>
                          <th scope="col" align="right">
                            <button
                              className="btn btn-sm btn-secondary float-right"
                              onClick={() =>
                                this.setState({
                                  show_deleted: !this.state.show_deleted,
                                })
                              }
                            >
                              {show_deleted ? 'Hide' : 'Show'} deleted
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {employers
                          .filter((employer) => {
                            if (show_deleted) {
                              return employer;
                              // eslint-disable-next-line no-else-return
                            } else {
                              return employer.status === 'active';
                            }
                          })
                          .map((employer) => (
                            <tr key={employer.id}>
                              <td>{employer.company_name}</td>
                              <td>{employer.status}</td>

                              {this.getDefaultFeeModel(
                                employer.subscriptions,
                                employer.transaction_fees,
                              )}

                              {this.getDefaultPayRoll(employer.pay_cycles)}

                              <td>{employer.default_risk_metric}%</td>
                              <td>
                                {moment(employer.date_created).format(
                                  'MMM Do YYYY, h:mm a',
                                )}
                              </td>
                              <td className="d-flex justify-content-end">
                                <button
                                  type={'button'}
                                  className={'btn btn-primary float-right'}
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admin/employers/${employer.id}`,
                                    )
                                  }
                                >
                                  View
                                </button>

                                <button
                                  type={'button'}
                                  className={'btn btn-primary float-right'}
                                  onClick={() =>
                                    this.props.history.push(
                                      '/admin/advance_batches_configurations',
                                    )
                                  }
                                >
                                  Batch Configuration
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      </>
    );
  }
}

export default EmployersList;
