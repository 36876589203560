/* eslint-disable camelcase */
import React from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import UserEmployerAndRoleSelect from 'components/Users/UserEmployerAndRoleSelect';
import apiResponseHandler from 'utils/apiResponseHandler';
import UserTagsSelect from 'components/Users/UserTagsSelect';
import UserFeatureSelected from 'components/Users/UserFeatureSelected';
import { environment } from '../../../enviroment/enviroment';

class UserAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      availableRoles: [],
      employers: [],
      employer_features: [],
      user_features: [],
      user_tags: [],
      groups: [],
      categories: [],
    };
  }

  componentDidMount() {
    this.getEmployers();
    this.getAvailableRoles();
  }

  getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({ employers: res.data });
    });
  };

  onEmployerChanged = (event) => {
    this.handleinputchange(event);
  };

  onRolesChanged = (roleIds) => {
    this.setState({
      groups: roleIds,
    });
  };

  getEmployerTagsByCategory = () => {
    if (this.state.employer) {
      const url = `${environment.baseUrl}/employertag/categories/`;
      axios
        .get(url, {
          params: {
            employer_id: this.state.employer,
          },
        })
        .then((res) => {
          if (res.data) {
            this.setState({ employer_tags: res.data });
          }
        });
    }
  };

  getEmployerFeatures = () => {
    if (this.state.employer) {
      const url = `${environment.baseUrl}/employer_features?enabled=true`;
      axios
        .get(url, {
          params: {
            employer_id: this.state.employer,
          },
        })
        .then((res) => {
          if (res.data) {
            const employer_features = res.data.map((employerFeature) => {
              return {
                feature: employerFeature,
              };
            });
            this.setState({ employer_features });
          }
        });
    }
  };

  getAvailableRoles = () => {
    let rolesFor = 'admin';
    if (this.state.employer) {
      rolesFor = 'all';
    }
    axios
      .get(`${environment.baseUrl}/user_roles?roles_for=${rolesFor}`)
      .then((res) => {
        this.setState({ availableRoles: res.data });
      });
  };

  afterSetStateFinished(eventName) {
    if (eventName === 'employer') {
      this.getAvailableRoles();
      this.getEmployerTagsByCategory();
      this.getEmployerFeatures();
    }
  }

  onTagsChanged = (tags) => {
    this.setState({
      user_tags: tags,
    });
  };

  updatedUserFeatures = (features) => {
    this.setState({
      user_features: features,
    });
  };

  handleinputchange = (event) => {
    event.preventDefault();
    const eventName = event.target.name;
    let eventValue = event.target.value;
    // this.form.validateFields(event.target);
    if (eventName === 'employer' && eventValue === 'NO_EMPLOYER') {
      eventValue = undefined;
    }
    if (
      event.target.name === 'bank_account_number' &&
      !/^\d+$/.test(event.target.value)
    ) {
      return null;
    }
    this.setState(
      {
        [eventName]: eventValue,
      },
      () => {
        this.afterSetStateFinished(eventName);
      },
    );
  };

  saveData = () => {
    const {
      username,
      first_name,
      last_name,
      email,
      mobile_number,
      groups,
      employer,
    } = this.state;

    const payload = {
      username,
      first_name,
      last_name,
      email,
      mobile_number,
      employer,
      groups,
      features: this.state.user_features.map((uF) => uF.feature.id),
      tags: this.state.user_tags.map((ut) => ut.tag.id),
    };

    const addUserUrl = `${environment.baseUrl}/users/`;
    axios
      .post(addUserUrl, payload)
      .then((res) => {
        apiResponseHandler.handleApiSuccess('User Created');
        this.props.history.push('/admin/users');
      })
      .catch((error) =>
        apiResponseHandler.handleApiError('Error while creating user', error),
      );
  };

  render() {
    const {
      loading,
      first_name,
      last_name,
      username,
      email,
      mobile_number,
      employers,
    } = this.state;
    return (
      <section className="dashboard">
        <>
          <div className="header bg-primary pb-6">
            <Container fluid>
              <div className="header-body">
                <Row className="align-items-center py-4">
                  <Col lg="6">
                    <h6 className="h2 text-white d-inline-block mb-0">
                      Create new user
                    </h6>
                    <br />
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container fluid className="mt--7">
            <Row className="pt-5 emp-edit-data">
              <Col>
                {loading ? (
                  <Row className="my-3">
                    <Col className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col lg={{ size: '6', offset: '3' }}>
                        <Row>
                          <Col>
                            <Card className="shadow">
                              <CardHeader>
                                <Row className="align-items-center">
                                  <Col lg="8">
                                    <h3 className="mb-0">Edit user</h3>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="first_name">First name</Label>
                                      <Input
                                        type="text"
                                        name="first_name"
                                        value={first_name}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label for="last_name">Last name</Label>
                                      <Input
                                        type="text"
                                        name="last_name"
                                        value={last_name}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="username">Username</Label>
                                      <Input
                                        type="text"
                                        name="username"
                                        value={username}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="email">Email</Label>
                                      <Input
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="mobile_number">
                                        Mobile number
                                      </Label>
                                      <Input
                                        type="text"
                                        name="mobile_number"
                                        value={mobile_number}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <UserEmployerAndRoleSelect
                                  onEmployerChanged={this.onEmployerChanged}
                                  onRolesChanged={this.onRolesChanged}
                                  employers={employers}
                                  availableRoles={this.state.availableRoles}
                                  employerSelectedId={this.state.employer?.id}
                                  selectedRoleIds={this.state.groups}
                                />
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="features">Features</Label>
                                      <UserFeatureSelected
                                        userFeatures={this.state.user_features}
                                        employerFeatures={
                                          this.state.employer_features
                                        }
                                        updatedUserFeatures={
                                          this.updatedUserFeatures
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="employerTag">Tags</Label>
                                      <UserTagsSelect
                                        employerTagsByCategory={
                                          this.state.employer_tags
                                        }
                                        userTags={this.state.user_tags}
                                        onTagsChanged={this.onTagsChanged}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <button
                                      type="button"
                                      className="btn btn-primary float-right"
                                      onClick={this.saveData}
                                    >
                                      Save
                                    </button>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </>
      </section>
    );
  }
}

export default UserAdd;
