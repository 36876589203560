import React from 'react';

type Props = {
  row: any;
};

const ProfileDetailRow: React.FC<Props> = ({ row }) => {
  return (
    <tr>
      <td className="p-2" style={{ fontSize: '14px' }}>
        {row[0] + ': '}
        <span style={{ fontWeight: 600 }}>{`${row[1]}`}</span>
      </td>
    </tr>
  );
};

export default ProfileDetailRow;
