/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Table } from 'reactstrap';
import moment from 'moment';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import Pagination from '../../../components/Pagination';
// eslint-disable-next-line import/no-unresolved
import { environment } from '../../../enviroment/enviroment';
import SmsLog from './Components/SmsLog';
import AdvanceRequests from './Components/AdvanceRequests';
import SupportTickets from './Components/SupportTickets';
import EmployeeEditForm from './EmployeeEditForm';
import Subscriptions from './Components/Subscriptions';
import EmployeeAdvanceAdd from './Components/EmployeeAdvanceAdd';
import apiResponseHandler from 'utils/apiResponseHandler';
import ManageEmployeePayslips from './EmployeePayslips/ManageEmployeePayslips';

const EmployeeDataSideBar = ({ data }) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [transactionFeeData, setTransactionFeeData] = useState(null);

  useEffect(() => {
    if (data.subscription) {
      getSubscriptionData();
    }
    if (data.transaction_fee) {
      getTransactionFeeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubscriptionData = () => {
    axios
      .get(`${environment.baseUrl}/subscriptions/${data.subscription}/`)
      .then((res) => {
        setSubscriptionData(res.data);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        console.log('Subscription data fetch error');
      });
  };

  const getTransactionFeeData = () => {
    axios
      .get(`${environment.baseUrl}/transactionfees/${data.transaction_fee}/`)
      .then((res) => {
        setTransactionFeeData(res.data);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        console.log('Transaction fee data fetch error');
      });
  };

  return (
    <Container style={{ fontSize: '0.8rem' }}>
      <hr className={'my-2'} />
      <Row>
        <Col className={'m-2'}>
          <strong>Mobile number:</strong>
        </Col>
        <Col className={'m-2'}>{data.mobile_number || 'No data'}</Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>Email:</strong>
        </Col>
        <Col className={'m-2'}>{data.email || 'No data'}</Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>ID Number:</strong>
        </Col>
        <Col className={'m-2'}>{data.id_number || 'No data'}</Col>
      </Row>
      <hr className={'my-2'} />
      <Row>
        <Col className={'m-2'}>
          <strong>Status:</strong>
        </Col>
        <Col className={'m-2'}>{data.status || 'No data'}</Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>SmartWage Status:</strong>
        </Col>
        <Col className={'m-2'}>{data.smartwage_status || 'No data'}</Col>
      </Row>
      {data.status_reason && (
        <Row>
          <Col className={'m-2'}>
            <strong>Status Reason:</strong>
          </Col>
          <Col className={'m-2'}>{data.status_reason || 'No data'}</Col>
        </Row>
      )}
      <Row>
        <Col className={'m-2'}>
          <strong>Agreed T&Cs:</strong>
        </Col>
        <Col className={'m-2'}>
          {data.term_cond ? (
            <span className={'badge badge-success'}>Yes</span>
          ) : (
            <span className={'badge badge-danger'}>No</span>
          )}
        </Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>Risky Profile:</strong>
        </Col>
        <Col className={'m-2'}>
          <Row>
            <Col>
              {data.risky ? (
                <span className={'badge badge-danger'}>Yes</span>
              ) : (
                <span className={'badge badge-success'}>No</span>
              )}
            </Col>
            {data.risky_reasons && (
              <Col>
                <i
                  data-tip
                  data-for="riskReasonsTip"
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <hr className={'my-2'} />
      {!!data.subscription && subscriptionData && (
        <>
          <Row>
            <Col className={'m-2'}>
              <strong>Fee model:</strong>
            </Col>
            <Col className={'m-2'}>Subscription</Col>
          </Row>
          <Row>
            <Col className={'m-2'}>
              <strong>Subscription name:</strong>
            </Col>
            <Col className={'m-2'}>{subscriptionData.name}</Col>
          </Row>
          <Row>
            <Col className={'m-2'}>
              <strong>Employee amount:</strong>
            </Col>
            <Col className={'m-2'}>R{subscriptionData.employee_amount}</Col>
          </Row>
          <Row>
            <Col className={'m-2'}>
              <strong>Employer amount:</strong>
            </Col>
            <Col className={'m-2'}>R{subscriptionData.employer_amount}</Col>
          </Row>
        </>
      )}
      {!!data.transaction_fee && transactionFeeData && (
        <>
          <Row>
            <Col className={'m-2'}>
              <strong>Fee model:</strong>
            </Col>
            <Col className={'m-2'}>Transaction</Col>
          </Row>
          <Row>
            <Col className={'m-2'}>
              <strong>Fee type:</strong>
            </Col>
            <Col className={'m-2'}>{transactionFeeData.fee_type}</Col>
          </Row>
          <Row>
            <Col className={'m-2'}>
              <strong>Amount:</strong>
            </Col>
            <Col className={'m-2'}>{`R${transactionFeeData.fee_amount}`}</Col>
          </Row>
          <Row>
            <Col className={'m-2'}>
              <strong>Percentage:</strong>
            </Col>
            <Col
              className={'m-2'}
            >{`${transactionFeeData.fee_percentage}%`}</Col>
          </Row>
        </>
      )}
      <hr className={'my-2'} />
      <Row>
        <Col className={'m-2'}>
          <strong>Salary:</strong>
        </Col>
        <Col className={'m-2'}>R{data.salary || 'No data'}</Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>Paycycle:</strong>
        </Col>
        <Col className={'m-2'}>
          {(data.employee_pay_cycle && data.employee_pay_cycle.name) ||
            'No data'}
        </Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>Branch:</strong>
        </Col>
        <Col className={'m-2'}>
          {(data.employee_branch && data.employee_branch.name) || 'No data'}
        </Col>
      </Row>
      <hr className={'my-2'} />
      <Row>
        <Col className={'m-2'}>
          <strong>Bank Name:</strong>
        </Col>
        <Col className={'m-2'}>{data.bank_name || 'No data'}</Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>Bank account number:</strong>
        </Col>
        <Col className={'m-2'}>{data.bank_account_number || 'No data'}</Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>Bank branch:</strong>
        </Col>
        <Col className={'m-2'}>{data.bank_branch_name || 'No data'}</Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>Bank branch code:</strong>
        </Col>
        <Col className={'m-2'}>{data.bank_branch_code || 'No data'}</Col>
      </Row>
      <hr className={'my-2'} />
      <Row>
        <Col className={'m-2'}>
          <strong>Employee number:</strong>
        </Col>
        <Col className={'m-2'}>{data.employee_no || 'No data'}</Col>
      </Row>
      <Row>
        <Col className={'m-2'}>
          <strong>SmartWage ID:</strong>
        </Col>
        <Col className={'m-2'}>{data.smartwage_id || 'No data'}</Col>
      </Row>
      {data.risky_reasons && (
        <ReactTooltip id="riskReasonsTip" place="right" effect="solid">
          Reasons:
          {data.risky_reasons &&
            data.risky_reasons.reasons.map(function (name) {
              return <li>{name}</li>;
            })}
        </ReactTooltip>
      )}
    </Container>
  );
};

const MenuItem = (props) => {
  return (
    <div
      className={'py-3 px-4'}
      style={{
        borderBottom: props.active && 'blue 4px solid',
        cursor: 'pointer',
      }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

class EmployeeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      employeeData: null,
      employer: {},
      menuItem: 'advances',
      employeeAuditLogs: [],
      employeeAuditLogsPage: 1,
      employeeAuditLogsDisplayPages: null,
      auditLogColumn: 'smartwage_status',
    };
  }

  filterColumns = [
    'smartwage_status',
    'mobile_number',
    'salary',
    'bank_account_number',
    'id_number',
    'employee_pay_cycle',
    'subscription',
    'transaction_fee',
    'risk_metric',
    'employer',
    'branch',
    'bank_branch_code',
  ];
  componentDidMount() {
    this.getEmployeeData();
    this.getEmployers();
    this.getEmployeeAuditLogs();
  }

  getEmployeeData = () => {
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/employees/${this.props.match.params.empId}/`)
      .then((res) => {
        const employeeData = {
          ...res.data,
          employer: res.data.employer,
          employee_pay_cycle: res.data.employee_pay_cycle,
        };
        this.setState({
          ...res.data,
          employeeData,
          loading: false,
        });
      });
  };

  getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({ employers: res.data });
    });
  };

  getEmployeeAuditLogs = () => {
    axios
      .get(`${environment.baseUrl}/auditlogs/`, {
        params: {
          model: 'employee',
          column: this.state.auditLogColumn,
          object_id: this.props.match.params.empId,
          page: this.state.employeeAuditLogsPage,
          pagination: true,
        },
      })
      .then((res) => {
        this.setState({
          employeeAuditLogsDisplayPages: res.data.pages,
          employeeAuditLogs: res.data.results,
        });
      });
  };

  setPage = (employeeAuditLogsPage) => {
    this.setState({ employeeAuditLogsPage }, () => this.getEmployeeAuditLogs());
  };

  sendOnboardingSms = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to send an onboarding sms?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, do it!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${environment.baseUrl}/sms/`, {
            employee_id: this.props.match.params.empId,
          })
          .then((_res) => {
            Swal.fire({
              title: 'SMS Sent',
              icon: 'success',
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getSmsLogData();
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  deleteEmployee = () => {
    Swal.fire({
      title: 'Delete employee?',
      text: `Are you sure you want to delete this employee?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete them!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${environment.baseUrl}/employees/${this.props.match.params.empId}/delete/`,
          )
          .then((res) => {
            Swal.fire({
              title: 'Employee was deleted',
              icon: 'success',
            });
            this.getEmployeeData();
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  suspendEmployee = () => {
    Swal.fire({
      title: 'Suspend employee?',
      text: `Are you sure you want to suspend this employee?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, suspend them!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${environment.baseUrl}/employees/${this.props.match.params.empId}/suspend/`,
          )
          .then((res) => {
            Swal.fire({
              title: 'Employee was suspended',
              icon: 'success',
            });
            this.getEmployeeData();
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  activateEmployee = () => {
    Swal.fire({
      title: 'Activate employee?',
      text: `Are you sure you want to activate this employee?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, activate them!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${environment.baseUrl}/employees/${this.props.match.params.empId}/activate/`,
          )
          .then((res) => {
            Swal.fire({
              title: 'Employee was activated',
              icon: 'success',
            });
            this.getEmployeeData();
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  getChanges = (event) => {
    this.setState({ ...this.state, auditLogColumn: event.target.value }, () =>
      this.getEmployeeAuditLogs(),
    );
  };

  render() {
    const {
      loading,
      first_name,
      last_name,
      smartwage_status,
      employer,
      employeeData,
      menuItem,
      employeeAuditLogs,
      employeeAuditLogsPage,
      employeeAuditLogsDisplayPages,
    } = this.state;
    const { match } = this.props;
    const empId = match.params.empId;
    return (
      <section className="dashboard bg-white border-left">
        <Row>
          <Col>
            {loading ? (
              <Row className="my-3">
                <Col className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col lg={3} className={'border-right'}>
                    <div
                      className={'d-flex align-items-center flex-column my-4'}
                    >
                      <h6 className="h2 d-inline-block mb-0">
                        {`${first_name} ${last_name}`}
                      </h6>{' '}
                      <Link to={`/admin/employers/${employer.id}`}>
                        {employer.company_name}
                      </Link>
                    </div>
                    {employeeData && (
                      <EmployeeDataSideBar data={employeeData} />
                    )}
                  </Col>
                  <Col lg={9}>
                    <Row style={{ backgroundColor: '#f6f6f6' }}>
                      <MenuItem
                        onClick={() => this.setState({ menuItem: 'advances' })}
                        active={menuItem === 'advances'}
                      >
                        Advances
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.setState({ menuItem: 'createEmployeeAdvance' })
                        }
                        active={menuItem === 'createEmployeeAdvance'}
                      >
                        Create Advance
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.setState({ menuItem: 'subscription' })
                        }
                        active={menuItem === 'subscription'}
                      >
                        Subscription
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.setState({ menuItem: 'sms' })}
                        active={menuItem === 'sms'}
                      >
                        SMS
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.setState({ menuItem: 'support' })}
                        active={menuItem === 'support'}
                      >
                        Support tickets
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.setState({ menuItem: 'edit' })}
                        active={menuItem === 'edit'}
                      >
                        Edit
                      </MenuItem>

                      <MenuItem
                        onClick={() => this.setState({ menuItem: 'changes' })}
                        active={menuItem === 'changes'}
                      >
                        Employee Changes
                      </MenuItem>

                      <MenuItem
                        onClick={() => this.setState({ menuItem: 'payslips' })}
                        active={menuItem === 'payslips'}
                      >
                        Payslips
                      </MenuItem>



                    </Row>
                    <Row>
                      <Col className={'p-0'}>

                      {menuItem === 'payslips' && (
                          <>
                            <ManageEmployeePayslips employeeData={employeeData}/>
                          </>
                        )}


                        {menuItem === 'advances' && (
                          <AdvanceRequests employeeId={empId} />
                        )}
                        {menuItem === 'createEmployeeAdvance' && (
                          <EmployeeAdvanceAdd employeeId={empId} />
                        )}
                        {menuItem === 'subscription' && (
                          <>
                            <Subscriptions employeeId={empId} />
                          </>
                        )}
                        {menuItem === 'sms' && <SmsLog employeeId={empId} />}
                        {menuItem === 'support' && (
                          <SupportTickets employeeId={empId} />
                        )}
                        {menuItem === 'edit' && employeeData && (
                          <EmployeeEditForm
                            data={employeeData}
                            getEmployeeData={this.getEmployeeData}
                          />
                        )}
                        {menuItem === 'changes' && (
                          <>
                            <Row style={{ display: 'flex', padding: '24px' }}>
                              <Col lg={'5'} className="mt-3">
                                <select
                                  className="form-control"
                                  name="column"
                                  onChange={this.getChanges}
                                >
                                  {this.filterColumns &&
                                    this.filterColumns.map((filterColumn) => (
                                      <option value={filterColumn}>
                                        {filterColumn
                                          .toUpperCase()
                                          .replace(/_/g, ' ')}
                                      </option>
                                    ))}
                                </select>
                              </Col>
                            </Row>

                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Date updated</th>
                                  <th scope="col">Updated By</th>
                                  <th scope="col">Change</th>
                                  <th scope="col">Reason</th>
                                </tr>
                              </thead>
                              <tbody>
                                {employeeAuditLogs.map((employeeAuditLog) => (
                                  <tr>
                                    <td>
                                      {moment(
                                        employeeAuditLog.timestamp,
                                      ).format('MMM Do YYYY, h:mm a')}
                                    </td>
                                    <td>
                                      {employeeAuditLog.actor
                                        ? employeeAuditLog.actor.first_name +
                                          ' ' +
                                          employeeAuditLog.actor.last_name
                                        : ''}
                                    </td>
                                    <td>
                                      <pre>
                                        {employeeAuditLog.action.replace(
                                          /(?:;)/g,
                                          '\n',
                                        )}{' '}
                                      </pre>
                                    </td>
                                    <td>
                                      {employeeAuditLog.additional_data
                                        ? employeeAuditLog.additional_data.change_reason_category
                                            .toUpperCase()
                                            .replace(/_/g, ' ') +
                                          ' ' +
                                          employeeAuditLog.additional_data
                                            .change_reason_text
                                        : ''}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                            <Pagination
                              page={employeeAuditLogsPage}
                              pages={employeeAuditLogsDisplayPages}
                              setPage={this.setPage}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </section>
    );
  }
}

export default EmployeeDetail;
