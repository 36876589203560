import React from "react";
import {
    Col,
    Row,
    Label,
    FormGroup,
  } from 'reactstrap';

import Select from 'react-select'

class UserEmployerAndRoleSelect extends React.Component{

    render(){
        return (
            <Row>
                <Col lg={'12'}>
                    <FormGroup>
                        <Label for="employer">Employer</Label>
                        {
                            this.props.employers?.length > 0 ?
                            <select
                                name="employer"
                                className={'form-control'}
                                onChange={(selected) => this.props.onEmployerChanged(selected)}
                                >
                                <option key="NO_EMPLOYER" value="NO_EMPLOYER">No Employer</option>
                                {
                                    this.props.employers.map((employer) => (
                                    <option
                                        value={employer.id}
                                        key={employer.id}
                                        selected={
                                            this.props.employerSelectedId === employer.id
                                        }
                                    >
                                        {employer.company_name}
                                    </option>
                                    ))}
                            </select>
                            :
                            <p>Loading employers...</p>
                        }
                    </FormGroup>
                </Col>
                <Col lg={'12'}>
                    <FormGroup>
                        <Label for="role">Roles</Label>
                        {
                            <Select 
                            onChange={(selected) => {
                                let roleIds = [];
                                if(selected)
                                    roleIds = selected.map(s => s.value);
                                this.props.onRolesChanged(roleIds);
                            }}
                            value={this.props.availableRoles.filter(availableRole => this.props.selectedRoleIds.includes(availableRole.id)).map(role => {
                                return {
                                    value: role.id,
                                    label: role.name
                                }
                            })}
                            options={this.props.availableRoles.map(role => {
                                return {
                                    value: role.id,
                                    label: role.name
                                }
                            })} 
                            isMulti={true} />
                        }
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default UserEmployerAndRoleSelect;