/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Form,
  Input,
  Label,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';

import Swal from 'sweetalert2';
import moment from 'moment';
import { formatGetLocalTime, formatDateForInput } from 'utils/formatters';

import axios from 'axios';
import { environment } from '../../../enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';

/**
 * @summary AddNewAdvanceBatchModal component
 *
 * @param {Props} props
 * @component
 */

const getToday = (dateOnly = false) => {
  let d = new Date();
  return dateOnly ? new Date(d).toDateString() : d.toISOString().slice(0, 10);
};

const checkIfEndDateInTheFuture = ({ selectedDay }) => {
  const dayDate = new Date(selectedDay);
  const today = new Date();
  if (today.getDate() < dayDate.getDate()) {
    return false;
  }
  return true;
};

const checkIfEndTimeInTheFuture = ({ selectedDay, selectedTime }) => {
  const dayDate = new Date(selectedDay);
  const today = new Date();
  if (today.getDate() > dayDate.getDate()) {
    return true;
  }
  const timeSelectedDay = new Date(`${selectedDay} ${selectedTime}`);

  if (today < timeSelectedDay) {
    return false;
  }
  if (today > timeSelectedDay) {
    return true;
  }
  return true;
};

const AddNewAdvanceBatchModal = (props) => {
  const [state, setState] = useState({
    employers: [],
    payrollOptions: [],
    activeEmployer: null,
    newAdvanceBatchPayload: {},
    lastPayrollBatch: null,
    selectedPayrollId: null,
  });

  const [trySubmit, setTriedSubmitting] = useState(false);
  const {
    toggleAddNewBatchModal,
    handleShowAddNewBatchModal,
    getAdvanceBatches,
  } = props;

  const getEmployers = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    axios.get(`${environment.baseUrl}/employers/`, { headers }).then((res) => {
      setState({
        ...state,
        employers: res.data,
        loading: false,
      });
    });
  };

  useEffect(() => {
    getEmployers();
  }, []);

  const handlePayrollChange = (event) => {
    const selectedPayrollId = event.target.value;

    axios
      .get(`${environment.baseUrl}/advance_batch/payroll/${selectedPayrollId}/`)
      .then((res) => {
        setState({
          ...state,
          lastPayrollBatch: res.data,
          loading: false,
          selectedPayrollId,
        });
      });
  };

  const handleEmployerChange = (event) => {
    event.preventDefault();

    const activeEmployerId = event.target.value;

    const matchingEmployers = state.employers.filter((employer) => {
      return employer.id === parseInt(activeEmployerId, 10);
    });
    setState({
      ...state,
      activeEmployer: matchingEmployers[0],
    });
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    setState({
      ...state,
      newAdvanceBatchPayload: {
        ...state.newAdvanceBatchPayload,
        [name]: value,
      },
    });
  };

  const handleSubmit = () => {
    setTriedSubmitting(true);
    const currentUserId = JSON.parse(localStorage.getItem('USER')).id;
    const {
      lastPayrollBatch,
      newAdvanceBatchPayload,
      activeEmployer,
      selectedPayrollId,
    } = state;

    const payload = {
      /** set start date of new batch to the end date of the last batch */
      start_date: lastPayrollBatch
        ? moment(`${lastPayrollBatch.end_datetime}`).utc().format()
        : moment(
            `${newAdvanceBatchPayload.startDate} ${newAdvanceBatchPayload.startDateTime}`,
          ).format(),

      end_date: moment(
        `${newAdvanceBatchPayload.endDate} ${newAdvanceBatchPayload.endDateTime}`,
      ).format(),
      employer: activeEmployer.id,
      payroll: selectedPayrollId,
      requested_by: currentUserId,
    };
    const url = `${environment.baseUrl}/advance_batch_job/`;

    if (
      !(
        state.newAdvanceBatchPayload.endDateTime &&
        state.newAdvanceBatchPayload.endDate &&
        checkIfEndTimeInTheFuture({
          selectedDay: state.newAdvanceBatchPayload.endDate,
          selectedTime: state.newAdvanceBatchPayload.endDateTime,
        })
      )
    ) {
      Swal.fire({
        title: 'End Date and time can not be in the future.',
        // text: 'Please change the end date and time.',
        icon: 'warning',
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      return;
    }
    axios
      .post(url, payload)
      .then(() => {
        Swal.fire({
          title: 'Advance Batch Job successfully submitted.',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
        });
        handleShowAddNewBatchModal();
        getAdvanceBatches();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError(
          'Error submitting Advance Batch Job',
          error,
        );
        setState({ ...state, containerState: 'STATE_ERROR' });
      });
  };

  return (
    <>
      <Modal isOpen={toggleAddNewBatchModal}>
        <ModalHeader>Generate New Advance Batch</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="exampleSelect">Employer</Label>

              <Input
                type="select"
                name="employerSelect"
                id="employerSelect"
                onChange={(e) => handleEmployerChange(e)}
              >
                <option />

                {!!state.employers &&
                  state.employers.map((employer) => {
                    return (
                      <option key={employer.id} value={employer.id}>
                        {employer.company_name}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="exampleSelect">Payroll</Label>
              <Input
                type="select"
                name="selectedPayroll"
                id="selectedPayroll"
                onChange={(e) => handlePayrollChange(e)}
              >
                <option />

                {!!state.activeEmployer &&
                  state.activeEmployer.pay_cycles &&
                  state.activeEmployer.pay_cycles.map((payCycle) => {
                    return (
                      <option key={payCycle.id} value={payCycle.id}>
                        {payCycle.name}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>

            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                placeholder="Batch Start Date"
                onChange={(e) => handleInputChange(e)}
                required
                readOnly={
                  !!state.lastPayrollBatch &&
                  state.lastPayrollBatch.last_batch_id
                }
                value={
                  state.lastPayrollBatch
                    ? formatDateForInput(state.lastPayrollBatch.end_datetime)
                    : null
                }
              />
            </FormGroup>

            <FormGroup>
              <Label for="startDateTime">Start Date Time</Label>
              <Input
                type="time"
                name="startDateTime"
                id="startDateTime"
                onChange={(e) => handleInputChange(e)}
                readOnly={
                  !!state.lastPayrollBatch &&
                  state.lastPayrollBatch.last_batch_id
                }
                value={
                  state.lastPayrollBatch
                    ? formatGetLocalTime(state.lastPayrollBatch.end_datetime)
                    : null
                }
              />
            </FormGroup>

            <FormGroup>
              <Label for="endDate">End Date</Label>
              <Input
                type="date"
                name="endDate"
                id="endDate"
                max={getToday()}
                onChange={(e) => handleInputChange(e)}
                invalid={
                  trySubmit &&
                  !(
                    state.newAdvanceBatchPayload.endDate &&
                    checkIfEndDateInTheFuture({
                      selectedDay: state.newAdvanceBatchPayload.endDate,
                    })
                  )
                }
              />
            </FormGroup>

            <FormGroup>
              <Label for="endDateTime">End Date Time</Label>
              <Input
                type="time"
                name="endDateTime"
                id="endDateTime"
                onChange={(e) => handleInputChange(e)}
                invalid={
                  trySubmit &&
                  !(
                    state.newAdvanceBatchPayload.endDateTime &&
                    state.newAdvanceBatchPayload.endDate &&
                    checkIfEndTimeInTheFuture({
                      selectedDay: state.newAdvanceBatchPayload.endDate,
                      selectedTime: state.newAdvanceBatchPayload.endDateTime,
                    })
                  )
                }
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>{' '}
          <Button color="secondary" onClick={handleShowAddNewBatchModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

AddNewAdvanceBatchModal.defaultProps = {};

AddNewAdvanceBatchModal.propTypes = {
  toggleAddNewBatchModal: PropTypes.func.isRequired,
  handleShowAddNewBatchModal: PropTypes.func.isRequired,
  getAdvanceBatches: PropTypes.func.isRequired,
};

export default AddNewAdvanceBatchModal;
