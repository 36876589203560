import { Document, Page, pdfjs } from 'react-pdf';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col } from 'reactstrap';
import EmployeePayslipUploadModal from './EmployeePayslipUploadModal';
import axios from 'axios';
import { environment } from 'enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';
import Swal from 'sweetalert2';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const removeEmployeePayslip = ({
  payslipId,
  getEmployeePayslips,
  employeeId,
  setRemovePayslipLoading,
}) => {
  Swal.fire({
    title: 'Remove Payslip?',
    text: `Are you sure you want to remove this payslip?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d63030',
    confirmButtonText: 'Confirm!',
  }).then((result) => {
    if (result.isConfirmed) {
      setRemovePayslipLoading(true);
      axios
        .patch(
          `${environment.baseUrl}/employee_payslips/${payslipId}/remove_employee_payslip`,
        )
        .then((res) => {
          getEmployeePayslips(employeeId);
          setRemovePayslipLoading(false);
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
          setRemovePayslipLoading(false);
        });
    }
  });
};

const EmployeePayslipUpdate = (props) => {
  const [payslipUrl, setPayslipUrl] = useState();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [removePayslipLoading, setRemovePayslipLoading] = useState(false);
  const updateModalState = (isOpen, isRefresh) => {
    props.showUpdatedPayslip(props.selectedPayslip.id, isRefresh);
    setShowUploadModal(isOpen);
  };

  useEffect(() => {
    setPayslipUrl(props.selectedPayslip.payslip_file);
  }, [props]);

  return (
    <div>
      {showUploadModal && (
        <EmployeePayslipUploadModal
          isCreateNew={false}
          payslipId={props.selectedPayslip.id}
          employeeData={props.employeeData}
          updateModalState={updateModalState}
          selectedPayslip={props.selectedPayslip}
        />
      )}
      <Row className="float-right">
        <Col lg={6}></Col>
        <Col lg={6} className="float-right">
          <button
            type="submit"
            className="btn btn-primary float-right"
            style={{
              marginBottom: '6px',
              marginRight: '3px',
              minWidth: '200px',
            }}
            onClick={() => setShowUploadModal(true)}
          >
            Update Payslip
          </button>

          <button
            type="submit"
            className="btn btn-danger float-right"
            disabled={removePayslipLoading}
            load
            style={{
              marginBottom: '6px',
              marginRight: '3px',
              minWidth: '200px',
            }}
            onClick={() =>
              removeEmployeePayslip({
                payslipId: props.selectedPayslip.id,
                getEmployeePayslips: props.getEmployeePayslips,
                employeeId: props.employeeId,
                setRemovePayslipLoading,
              })
            }
          >
            {removePayslipLoading ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              'Remove Payslip'
            )}
          </button>
        </Col>
      </Row>

      <Row style={{ width: '100%' }}>
        <div style={{ overflow: 'scroll', maxHeight: '500px' }}>
          {payslipUrl && (
            <Document file={payslipUrl}>
              <Page pageNumber={1} />
            </Document>
          )}
        </div>
      </Row>
    </div>
  );
};

export default EmployeePayslipUpdate;
