import React from 'react';
import axios from 'axios';
import { environment } from '../../../enviroment/enviroment';
import { Card, Table, Container, Row, Col } from 'reactstrap';
import moment from 'moment';
import Pagination from '../../../components/Pagination';

class UserAuditLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      auditLogs: [],
      page: 1,
      loading: false,
    };
  }

  componentDidMount() {
    this.getAuditLogs();
  }

  getAuditLogs = async (params) => {
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/auditlogs/`, {
        params: { ...params, page: this.state.page },
      })
      .then((res) => {
        this.setState({
          auditLogs: res.data.results,
          pages: res.data.pages,
          loading: false,
        });
      });
  };

  setPage = (page) => {
    this.setState({ page: page }, () => this.getAuditLogs());
  };

  render() {
    const { auditLogs, page, pages, loading } = this.state;
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg={'6'}>
                  <h6 className="h2 text-white d-inline-block mb-0">
                    User logs
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className={'mt--7'} fluid>
          <Row>
            <div className="col-12 mt-3">
              <Card className="shadow">
                {loading ? (
                  <Row className={'my-3'}>
                    <Col className={'d-flex justify-content-center'}>
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Table
                    className="align-items-center table-flush "
                    responsive
                    style={{
                      backgroundColor: 'white',
                      borderBottom: '0.5pt solid #f7fafc',
                    }}
                  >
                    <thead className={'thead-light'}>
                      <tr>
                        <th scope={'col'}>User</th>
                        <th scope={'col'}>Action</th>
                        <th scope={'col'}>Date Occurred</th>
                      </tr>
                    </thead>
                    <tbody>
                      {auditLogs &&
                        auditLogs.map((auditLog) => (
                          <tr>
                            <td>
                              {auditLog.actor
                                ? `${auditLog.actor.first_name} ${auditLog.actor.last_name}`
                                : 'System'}
                            </td>
                            <td>
                              <pre>
                                {auditLog.action.replace(/(?:;)/g, '\n')}
                              </pre>
                            </td>
                            <td>
                              {auditLog.timestamp &&
                                moment(auditLog.timestamp).format(
                                  'MMMM Do YYYY, h:mm:ss a',
                                )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                )}
                <Pagination page={page} pages={pages} setPage={this.setPage} />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default UserAuditLog;
