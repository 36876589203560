import React, { useState } from 'react';
import axios from 'axios';
import { Col, Table } from 'reactstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { environment } from '../../../../enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';

const SmsLog = ({ employeeId }) => {
  const [smsLogData, setSmsLogData] = useState([]);
  const [loading] = useState(false);

  const getSmsLogData = () => {
    axios
      .get(`${environment.baseUrl}/smslogs/`, {
        params: { employee: employeeId },
      })
      .then((res) => {
        setSmsLogData(res.data);
      });
  };

  const confirmSendOnboardingSms = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to send an onboarding sms?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Yes, do it!',
    }).then((result) => {
      if (result.isConfirmed) {
        sendOnboardingSms();
      }
    });
  };

  const sendOnboardingSms = () => {
    axios
      .post(`${environment.baseUrl}/sms/`, {
        employee_id: employeeId,
      })
      .then((res) => {
        Swal.fire({
          title: 'SMS Sent',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        getSmsLogData();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  return loading ? (
    <Col className='d-flex justify-content-center mt-3'>
      <div className='spinner-border' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
    </Col>
  ) : (
    <>
      <div className={'m-3 d-flex justify-content-end'}>
        <button className={'btn btn-primary btn-sm'} onClick={() => confirmSendOnboardingSms()}>Send onboarding SMS</button>
      </div>
      <Table className='align-items-center table-flush' responsive>
        <thead className='thead-light'>
        <tr>
          <th scope='col'>Message</th>
          <th scope='col'>Category</th>
          <th scope='col'>Successful</th>
          <th scope='col'>Mobile number</th>
          <th scope='col'>Date Added</th>
        </tr>
        </thead>
        <tbody>
        {smsLogData.map((smsLog) => (
          <tr>
            <td>{smsLog.message.slice(0, 30)}...</td>
            <td>{smsLog.category}</td>
            <td>
              {smsLog.successful_send ? (
                <span className='badge badge-success'>Yes</span>
              ) : (
                <span className='badge badge-danger'>no</span>
              )}
            </td>
            <td>{smsLog.mobile_number}</td>
            <td>
              {moment(smsLog.date_created).format('MMMM Do YYYY, h:mm:ss a')}
            </td>
          </tr>
        ))}
        </tbody>
      </Table></>
  );
};

export default SmsLog;
