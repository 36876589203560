import React from "react";
import Select from 'react-select'

class UserTagsSelect extends React.Component{

    getSelectOptions = () => {
        if(!this.props.employerTagsByCategory || this.props.employerTagsByCategory.length === 0){
            return [];
        }
        return this.props.employerTagsByCategory.map((it) => ({
            label: it.category,
            options: it.tags.map((it1) => ({
              label: it1.name,
              value: it1.id,
            })),
        }));
    }

    getSelectValues(){
        if(!this.props.userTags || this.props.userTags.length === 0){
            return [];
        }
        return this.props.userTags.map((userTag) => ({
            label: userTag.tag.name,
            value: userTag.tag.id,
        }))
    }

    render(){
        return (
            <Select
                isMulti
                onChange={(tags) => {
                    let selected = [];
                    if(tags != null){
                        const allTags = this.props.employerTagsByCategory.flatMap(category => category.tags);
                        selected = allTags.filter(tag => tags.map(t => t.value).includes(tag.id));
                    }
                    //convert to how user tags came into this component
                    selected = selected.map(selectedTag => {
                        return {
                            tag: selectedTag,
                            enabled: true
                        }
                    });
                    this.props.onTagsChanged(selected);
                }}
                value={
                    this.getSelectValues()
                }
                options={
                    this.getSelectOptions()
                }
                name="employerTag"
            />
        )
    }
}

export default UserTagsSelect;