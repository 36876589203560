import React from 'react';
import ReactFileReader from 'react-file-reader';
import { Card, CardHeader, Row, Col, CardFooter } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { environment } from 'enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';
import { Document, Page } from 'react-pdf';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class EmployeePayslipUploadModal extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      employer: null,
      file: null,
      issueDate: null,
      distributionTime: null,
    };
  }

  componentDidMount() {}

  selectIssueDate = (issueDate) => {
    this.setState({ issueDate: issueDate });
  };

  selectDistributionTime = (distributionTime) => {
    this.setState({ distributionTime: distributionTime });
  };

  handleFiles = (files) => {
    this.setState({ loading: true });
    const reader = new FileReader();
    reader.onload = (e) => {
      // Use reader.result
      this.setState({
        loading: false,
        file: files[0],
      });
    };
    reader.readAsDataURL(files[0]);
  };

  confirmUpload = async (isCreateNew = false) => {
    const action = isCreateNew ? 'Create ' : 'Update';
    const result = await Swal.fire({
      title: `${action} Payslip`,
      text: `Are you sure you want to \n${action} payslip for \n${this.props.employeeData.first_name} ${this.props.employeeData.last_name} with file ${this.state.file.name} ?`,
      icon: 'warning',
      showCancelButton: true,
      focusCancel: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, upload it!',
    });

    return result.isConfirmed;
  };

  getOffSetDateTime = (dateTime) => {
    const offSet = dateTime.getTimezoneOffset();
    const offSetDateTime = new Date(dateTime.getTime() - offSet * 60 * 1000);
    return offSetDateTime.toISOString();
  };

  createNewPayslip = async () => {
    const continueUpload = await this.confirmUpload(true);

    if (continueUpload) {
      Swal.fire({
        title: 'Uploading file',
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      };
      const { file } = this.state;
      const bodyFormData = new FormData();

      bodyFormData.append('employee', this.props.employeeData.id);

      bodyFormData.append('payslip_file', file);

      bodyFormData.append(
        'date_issued',
        this.getOffSetDateTime(this.state.issueDate).split('T')[0],
      );
      bodyFormData.append('verification_status', 'success');

      bodyFormData.append(
        'date_of_distribution',
        this.state.distributionTime.toISOString(),
      );

      axios
        .post(`${environment.baseUrl}/employee_payslips/`, bodyFormData, {
          headers,
        })
        .then((_res) => {
          Swal.fire({
            title: 'File uploaded',
            showConfirmButton: false,
            icon: 'success',
            timer: 3000,
          });
          this.props.updateModalState(false, true);
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
    }
  };

  updatePayslip = async () => {
    const continueUpload = await this.confirmUpload();

    if (continueUpload) {
      Swal.fire({
        title: 'Uploading file',
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const headers = {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      };
      const { file } = this.state;
      const bodyFormData = new FormData();

      bodyFormData.append('payslip_file', file);

      bodyFormData.append('employee', this.props.selectedPayslip.employee);
      bodyFormData.append(
        'date_issued',
        this.props.selectedPayslip.date_issued,
      );
      bodyFormData.append(
        'verification_status',
        this.props.selectedPayslip.verification_status,
      );

      bodyFormData.append(
        'date_of_distribution',
        this.props.selectedPayslip.date_of_distribution,
      );

      axios
        .put(
          `${environment.baseUrl}/employee_payslips/${this.props.payslipId}/`,
          bodyFormData,
          {
            headers,
          },
        )
        .then((_res) => {
          Swal.fire({
            title: 'File uploaded',
            showConfirmButton: false,
            icon: 'success',
            timer: 3000,
          });
          this.props.updateModalState(false, true);
        })
        .catch((error) => {
          apiResponseHandler.handleApiError('Error', error);
        });
    }
  };

  render() {
    return (
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: 0,
          top: 0,
          zIndex: 10,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(100,100,100,0.4)',
        }}
      >
        <Card
          style={{
            maxWidth: '100vw',
            width: '95vw',
            maxHeight: '95vh',
            margin: 'auto',
            backgroundColor: 'white',
          }}
        >
          <CardHeader>
            <Row>
              {this.props.isCreateNew && (
                <Col style={{ display: 'flex' }} lg={'9'}>
                  <Col style={{ display: 'flex' }} lg={'6'}>
                    <div style={{ marginRight: '12px', width: '50%' }}>
                      Issue Date / Pay Period{' '}
                    </div>
                    <div>
                      <DatePicker
                        selected={this.state.issueDate}
                        onChange={(date) => this.selectIssueDate(date)}
                        dateFormat="MMM d, yyyy"
                      />
                    </div>
                  </Col>

                  <Col style={{ display: 'flex' }} lg={'6'}>
                    <div style={{ marginRight: '12px' }}>
                      Distribution Time{' '}
                    </div>
                    <div>
                      <DatePicker
                        timeIntervals={5}
                        selected={this.state.distributionTime}
                        onChange={(date) => this.selectDistributionTime(date)}
                        showTimeSelect
                        dateFormat="MMM d, yyyy h:mm aa"
                      />
                    </div>
                  </Col>
                </Col>
              )}

              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {this.props.isCreateNew && (
                  <button
                    className="btn btn-success"
                    onClick={this.createNewPayslip}
                    disabled={
                      !this.state.file ||
                      !this.state.issueDate ||
                      !this.state.distributionTime
                    }
                  >
                    Create New Payslip
                  </button>
                )}

                {!this.props.isCreateNew && (
                  <button
                    className="btn btn-primary"
                    onClick={this.updatePayslip}
                    disabled={!this.state.file}
                  >
                    Update Payslip
                  </button>
                )}

                <button
                  className="btn btn-secondary"
                  onClick={() => this.props.updateModalState(false, false)}
                >
                  Close
                </button>
              </Col>
            </Row>
          </CardHeader>

          <Row className="my-3">
            <Col
              className="justify-content-left"
              lg="3"
              style={{ marginLeft: '32px', borderRight: '1px dotted' }}
            >
              <Row>
                <div style={{ margin: 'auto' }}>
                  <h2>Employee Details</h2>
                </div>
              </Row>

              <Row>
                <Col lg="6">
                  <strong>Id number</strong>
                </Col>
                <Col>
                  <label>{this.props.employeeData.id_number}</label>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <strong>Employee number</strong>
                </Col>
                <Col>
                  <label>{this.props.employeeData.employee_no}</label>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <strong>Employee name</strong>
                </Col>
                <Col>
                  <label>{`${this.props.employeeData.first_name} ${this.props.employeeData.last_name}`}</label>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <strong>Bank</strong>
                </Col>
                <Col>
                  <label>{this.props.employeeData.bank_name}</label>
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  <strong>Account Number</strong>
                </Col>
                <Col>
                  <label>{this.props.employeeData.bank_account_number}</label>
                </Col>
              </Row>
            </Col>

            <Col className=" justify-content-center" lg="6">
              {!this.state.file && (
                <div className="align-items-center">
                  <strong>
                    <i>Please upload a file to update the payslip</i>
                  </strong>
                </div>
              )}

              {this.state.file && (
                <div>
                  <div>
                    <strong>
                      <i>
                        The current payslip will be replaced with the following
                        one
                      </i>
                    </strong>
                  </div>

                  <div style={{ overflow: 'scroll', maxHeight: '500px' }}>
                    {this.state.file && (
                      <Document file={this.state.file}>
                        <Page pageNumber={1} />
                      </Document>
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <CardFooter
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <ReactFileReader
                multipleFiles={false}
                fileTypes={['.pdf']}
                handleFiles={this.handleFiles}
              >
                <button className="btn btn-primary">Choose file</button>
              </ReactFileReader>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default EmployeePayslipUploadModal;
