import React from "react";
import flagsmith from "flagsmith";
import { FlagsmithProvider, useFlags } from 'flagsmith/react';

const FeatureFlagProvider = ({ children }) => (
    <FlagsmithProvider
        options={{
          environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_KEY || '',
        }}
        flagsmith={flagsmith}>
            {
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                {children}
                </>
            }
    </FlagsmithProvider>
)

export default FeatureFlagProvider;

export const useEnabledFeatureFlag = (featureName) => {
    const auth =  JSON.parse(localStorage.getItem('USER'))

    if(auth?.email){
        flagsmith.identify(auth.email);
    }
    const flags = useFlags([featureName]);
    if(!flags){
        return false;
    }
    if(!flags[featureName]){
        return false;
    }
    return flags[featureName].enabled;
}
