import React from 'react';
import { Table, Container } from 'reactstrap';
import moment from 'moment';
import { environment } from '../../../enviroment/enviroment';

const AdvanceBatchList = (props) => {
  const { advanceBatches, handleShowBatchItemsModal } = props;

  if (Array.isArray(advanceBatches) && advanceBatches.length) {
    return (
      <Container fluid>
        <div className='card'>
          <div className='card-body'>
            <Table striped>
              <thead>
              <tr>
                <th>ID</th>
                <th>Employer</th>
                <th>Payroll</th>
                <th>Date Range</th>
                <th>Requested By</th>
                <th>Date Created</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {advanceBatches.map((batch) => {
                return (
                  <tr key={batch.id}>
                    <td>{batch.id}</td>
                    <td>{batch.employer.companyName}</td>
                    <td>{batch.payroll.name}</td>
                    <td>{`${moment(batch.startDate).format(
                      'DD/MM/Y, h:mm a',
                    )} - ${moment(batch.endDate).format('DD/MM/Y, h:mm a')}`}</td>
                    <td>{batch.requestedBy.username}</td>
                    <td>{`${moment(batch.dateCreated).format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )}`}</td>
                    <td>{batch.status}</td>
                    <td>
                      <button
                        type='button'
                        className='btn btn-secondary btn-sm '
                        onClick={() => handleShowBatchItemsModal(batch.id)}
                      >
                        View Advances
                      </button>

                      {batch.status === 'READY' && (
                        <button
                          type='button'
                          className='btn btn-primary btn-sm'
                          onClick={() =>
                            window.open(
                              `${environment.baseUrl}/advance_batch/${batch.id}`,
                            )
                          }
                        >
                          Download CSV
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}{' '}
              </tbody>
            </Table>
          </div>
        </div>
      </Container>
    );
  }
  return <p>No advance batches </p>;
};

AdvanceBatchList.propTypes = {};

AdvanceBatchList.defaultProps = {
  advanceBatches: [],
  handleShowBatchItemsModal: () => {
  },
};

export default AdvanceBatchList;
