import React from "react";
import axios from "axios";
import { environment } from "../../../enviroment/enviroment";
import { Card, Col, Container, Row, Table } from "reactstrap";
import moment from "moment";
import Pagination from "../../../components/Pagination";

class SmsList extends React.Component {
  state = {
    smsLogs: [],
    page: 1,
  };

  componentDidMount() {
    this.getSmsLogs();
  }

  getSmsLogs = () => {
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/smslogs/`, {
        params: { page: this.state.page, pagination: true },
      })
      .then((res) => {
        this.setState({
          smsLogs: res.data.results,
          pages: res.data.pages,
          loading: false,
        });
      });
  };

  setPage = (page) => {
    this.setState({ page: page }, () => this.getSmsLogs());
  };

  render() {
    const { smsLogs, loading, page, pages } = this.state;
    return (
      <>
        <>
          <div className="header bg-primary pb-6">
            <Container fluid>
              <div className="header-body">
                <Row className="align-items-center py-4">
                  <Col lg={"6"}>
                    <h6 className="h2 text-white d-inline-block mb-0">
                      SMS logs
                    </h6>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container className={"mt--7"} fluid>
            <Row>
              <Col className={"mt-3"}>
                <Card className="shadow">
                  {/*<CardHeader>*/}
                  {/*  <Row>*/}
                  {/*    <Col lg={"3"}>*/}
                  {/*      <input*/}
                  {/*        type="text"*/}
                  {/*        className="form-control mx-2"*/}
                  {/*        placeholder="Search SMS logs"*/}
                  {/*        onChange={this.onChange}*/}
                  {/*      />*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</CardHeader>*/}
                  {loading ? (
                    <Row className={"my-3"}>
                      <Col className={"d-flex justify-content-center"}>
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className={"thead-light"}>
                        <tr>
                          <th scope={"col"}>Message</th>
                          <th scope={"col"}>Category</th>
                          <th scope={"col"}>Successful</th>
                          <th scope={"col"}>Mobile number</th>
                          <th scope={"col"}>Date Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        {smsLogs.map((smsLog) => (
                          <tr>
                            <td>{smsLog.message.slice(0, 30)}...</td>
                            <td>{smsLog.category}</td>
                            <td>
                              {smsLog.successful_send ? (
                                <span className={"badge badge-success"}>
                                  Yes
                                </span>
                              ) : (
                                <span className={"badge badge-danger"}>no</span>
                              )}
                            </td>
                            <td>{smsLog.mobile_number}</td>
                            <td>
                              {moment(smsLog.date_created).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Card>
              </Col>
            </Row>
            <Pagination page={page} pages={pages} setPage={this.setPage} />
          </Container>
        </>
      </>
    );
  }
}

export default SmsList;
