import React from 'react';
import { Table } from 'reactstrap';

import Payslip, { TypePayslip } from './Payslip';
import ProfileDetailRow from './ProfileDetailRow';

type Props = {
  title: string;
  detail: any;
  payslips: TypePayslip[];
};

const ProfileTable: React.FC<Props> = ({ title, detail, payslips }) => {
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th scope="col">{title}</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(detail || {}).map((row, index) => (
            <ProfileDetailRow row={row} key={index} />
          ))}
        </tbody>
      </Table>

      <div>
        <h4>Payslips</h4>
        {payslips
          .sort((a: TypePayslip, b: TypePayslip) =>
            a.date_issued.localeCompare(b.date_issued),
          )
          .map((payslip: TypePayslip, index) => (
            <Payslip
              key={index}
              id={payslip.id}
              date_of_distribution={payslip.date_of_distribution}
              date_issued={payslip.date_issued}
            />
          ))}
      </div>
    </div>
  );
};

export default ProfileTable;
