import React from 'react';
import axios from 'axios';
import { environment } from '../../../enviroment/enviroment';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Input,
  FormGroup,
} from 'reactstrap';
import Swal from 'sweetalert2';
import UserEmployerAndRoleSelect from 'components/Users/UserEmployerAndRoleSelect';
import apiResponseHandler from 'utils/apiResponseHandler';
import UserTagsSelect from 'components/Users/UserTagsSelect';
import UserFeatureSelected from 'components/Users/UserFeatureSelected';

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advancerequests: [],
      loading: false,
      save_data: {},
      employer: {},
      employer_features: [],
      user_features: [],
      user_tags: [],
      employers: [],
      availableRoles: [],
      smsLogs: [],
      groups: [],
    };
  }

  componentDidMount() {
    this.getUserData();
    this.getEmployers();
    this.getAvailableRoles();
  }

  getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({ employers: res.data });
    });
  };

  getAvailableRoles = () => {
    let rolesFor = 'admin';
    if (this.state.employer) {
      rolesFor = 'all';
    }
    axios
      .get(`${environment.baseUrl}/user_roles?roles_for=${rolesFor}`)
      .then((res) => {
        this.setState({ availableRoles: res.data });
      });
  };

  getUserData = () => {
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/users/${this.props.match.params.userId}/`)
      .then((res) => {
        this.setState({ ...res.data, loading: false }, () => {});
      });
  };

  getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      this.setState({ employers: res.data });
    });
  };

  afterSetStateFinished(eventName) {
    if (eventName === 'employer') {
      this.getAvailableRoles();
    }
  }

  onEmployerChanged = (event) => {
    this.handleinputchange(event);
  };

  onRolesChanged = (roleIds) => {
    this.setState({
      groups: roleIds,
    });
  };

  updatedUserFeatures = (features) => {
    this.setState({
      user_features: features,
    });
  };

  onTagsChanged = (tags) => {
    this.setState({
      user_tags: tags,
    });
  };

  handleinputchange = (event) => {
    event.preventDefault();
    const eventName = event.target.name;
    let eventValue = event.target.value;
    // this.form.validateFields(event.target);
    if (eventName === 'employer' && eventValue === 'NO_EMPLOYER') {
      eventValue = undefined;
    }
    if (
      event.target.name === 'bank_account_number' &&
      !/^\d+$/.test(event.target.value)
    ) {
      return null;
    }

    this.setState(
      {
        [eventName]: eventValue,
      },
      () => {
        this.afterSetStateFinished(eventName);
      },
    );
  };

  saveData = () => {
    let employerId;
    if (typeof this.state.employer == 'object') {
      employerId = this.state.employer.id;
    } else {
      employerId = this.state.employer;
    }

    const payload = {
      ...this.state,
      employer: employerId,
      features: this.state.user_features.map((uF) => uF.feature.id),
      tags: this.state.user_tags.map((ut) => ut.tag.id),
    };

    axios
      .patch(
        `${environment.baseUrl}/users/${this.props.match.params.userId}/`,
        payload,
      )
      .then((res) => {
        apiResponseHandler.handleApiSuccess('User Saved');
        this.getUserData();
      })
      .catch((error) =>
        apiResponseHandler.handleApiError('Error while saving user', error),
      );
  };

  changeActive = (state) => {
    axios
      .patch(
        `${environment.baseUrl}/users/${this.props.match.params.userId}/`,
        { is_active: 'state' },
      )
      .then((res) => {
        apiResponseHandler.handleApiSuccess('User Saved', res);
        this.getUserData();
      })
      .catch(error => apiResponseHandler.handleApiError('Error while saving user', error));
  };

  deleteUser = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this user?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d63030',
      confirmButtonText: 'Yes, delete them!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${environment.baseUrl}/users/${this.props.match.params.userId}/`,
          )
          .then((res) => {
            Swal.fire({
              title: `User deleted`,
              icon: 'success',
              toast: true,
              position: 'top',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.props.history.push('/admin/users');
          })
          .catch((error) => {
            apiResponseHandler.handleApiError('Error', error);
          });
      }
    });
  };

  render() {
    const {
      loading,
      first_name,
      last_name,
      username,
      email,
      employers,
      mobile_number,
      is_active,
    } = this.state;
    return (
      <section className="dashboard">
        <>
          <div className="header bg-primary pb-6">
            <Container fluid>
              <div className="header-body">
                <Row className="align-items-center py-4">
                  <Col lg={'6'}>
                    <h6 className="h2 text-white d-inline-block mb-0">
                      {`${first_name} ${last_name} (${username})`}
                    </h6>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container fluid className={'mt--7'}>
            <Row className="pt-5 emp-edit-data">
              <Col>
                {loading ? (
                  <Row className={'my-3'}>
                    <Col className={'d-flex justify-content-center'}>
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col lg={{ size: '6', offset: '3' }}>
                        <Row>
                          <Col>
                            <Card className={'shadow'}>
                              <CardHeader>
                                <Row className="align-items-center">
                                  <Col lg={'8'}>
                                    <h3 className="mb-0">Edit user</h3>
                                  </Col>
                                  <Col
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    <button
                                      className={'btn btn-primary btn-sm'}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.changeActive(!is_active);
                                      }}
                                    >
                                      Make {is_active ? 'Inactive' : 'Active'}
                                    </button>
                                    <button
                                      className={'btn btn-sm btn-danger'}
                                      onClick={this.deleteUser}
                                    >
                                      Delete
                                    </button>
                                  </Col>
                                </Row>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="first_name">First name</Label>
                                      <Input
                                        type="text"
                                        name="first_name"
                                        value={first_name}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label for="last_name">Last name</Label>
                                      <Input
                                        type="text"
                                        name="last_name"
                                        value={last_name}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="username">Username</Label>
                                      <Input
                                        type="text"
                                        name="username"
                                        value={username}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="email">Email</Label>
                                      <Input
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="mobile_number">
                                        Mobile number
                                      </Label>
                                      <Input
                                        type="tel"
                                        name="mobile_number"
                                        value={mobile_number}
                                        onChange={this.handleinputchange}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <UserEmployerAndRoleSelect
                                  onEmployerChanged={this.onEmployerChanged}
                                  onRolesChanged={this.onRolesChanged}
                                  employers={employers}
                                  availableRoles={this.state.availableRoles}
                                  employerSelectedId={this.state.employer?.id}
                                  selectedRoleIds={this.state.groups}
                                />
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="features">Features</Label>
                                      <UserFeatureSelected
                                        userFeatures={this.state.user_features.filter(
                                          (e) => e.enabled,
                                        )}
                                        employerFeatures={this.state.employer_features.filter(
                                          (e) => e.enabled,
                                        )}
                                        updatedUserFeatures={
                                          this.updatedUserFeatures
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <FormGroup>
                                      <Label for="employerTag">Tags</Label>
                                      <UserTagsSelect
                                        employerTagsByCategory={
                                          this.state.employer_tags
                                        }
                                        userTags={this.state.user_tags}
                                        onTagsChanged={this.onTagsChanged}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <button
                                      className={'btn btn-primary float-right'}
                                      onClick={this.saveData}
                                    >
                                      Save
                                    </button>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </>
      </section>
    );
  }
}

export default UserDetail;
