import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Input, Table } from 'reactstrap';
import axios from 'axios';
import { environment } from '../../../enviroment/enviroment';
import Swal from 'sweetalert2';
import apiResponseHandler from 'utils/apiResponseHandler';
import FeatureFlags from '../../../utils/feature-flagging/FeatureFlags'
import { useEnabledFeatureFlag } from "../../../utils/feature-flagging/FeatureFlagProvider";

const FeaturePrice = ({ featurePrice, getFeaturePrices, employerId }) => {
  const [edit, setEdit] = useState(false);
  const [saveData, setSaveData] = useState({});

  useEffect(() => {
    setSaveData(featurePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featurePrice]);

  const cancelOverride = () => {
    axios
      .put(`${environment.baseUrl}/employer_features/cancel_price_override/`, {
        feature_id: featurePrice.id,
        employer_id: employerId,
      })
      .then(() => {
        Swal.fire({
          title: 'Successfully reverted price override',
          icon: 'success',
        });
        getFeaturePrices();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  const save = () => {
    axios
      .put(`${environment.baseUrl}/employer_features/update_price/`, {
        feature_id: featurePrice.id,
        employer_id: employerId,
        unit_price: saveData.unit_price,
        unit_calculation_mechanism: saveData.unit_calculation_mechanism,
      })
      .then(() => {
        Swal.fire({
          title: 'Successfully changed feature price',
          icon: 'success',
        });
        setEdit(false);
        getFeaturePrices();
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      });
  };

  const onChange = (e) => {
    const newData = { ...saveData };
    newData[e.target.name] = e.target.value;
    setSaveData(newData);
  };

  return (
    <tr key={featurePrice.id}>
      <td>{featurePrice.id}</td>
      <td>{featurePrice.name}</td>
      <td>
        {edit ? (
          <Input
            size={'sm'}
            type="number"
            min="0"
            value={saveData.unit_price}
            name={'unit_price'}
            onChange={(e) => onChange(e)}
          />
        ) : (
          saveData.unit_price
        )}
      </td>
      <td>
        {edit ? (
          <Input
            size={'sm'}
            type="select"
            value={saveData.unit_calculation_mechanism}
            name={'unit_calculation_mechanism'}
            onChange={(e) => onChange(e)}
          >
            <option>fixed</option>
            <option>KU</option>
            <option>registered</option>
          </Input>
        ) : (
          saveData.unit_calculation_mechanism
        )}
      </td>
      <td>
        {featurePrice.price_overridden ? (
          <span className={'badge badge-danger'}>Overriden</span>
        ) : (
          <span className={'badge badge-success'}>Standard</span>
        )}
      </td>
      <td align={'left'}>
        {!featurePrice.price_overridden && !edit ? (
          <button
            className={'btn btn-sm btn-primary'}
            onClick={() => setEdit(true)}
            style={{minWidth: '75px'}}
          >
            Override
          </button>
        ) : (
          <>
            {edit ? (
              <>
                <button
                  className={'btn btn-sm btn-danger'}
                  onClick={() => setEdit(false)}
                  style={{minWidth: '75px'}}
                >
                  Stop Edit
                </button>
                <button
                  className={'btn btn-sm btn-primary'}
                  onClick={() => save()}
                  style={{minWidth: '75px'}}
                >
                  Save
                </button>
              </>
            ) : (
              <>
                <button
                  className={'btn btn-sm btn-danger'}
                  onClick={() => cancelOverride()}
                  style={{minWidth: '75px'}}
                >
                  Cancel
                </button>
                <button
                  className={'btn btn-sm btn-primary'}
                  onClick={() => setEdit(true)}
                  style={{minWidth: '75px'}}
                >
                  Edit
                </button>
              </>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

const FeaturePricesTable = ({ employerId }) => {
  const [featurePrices, setFeaturePrices] = useState([]);
  const flagAutomatedInvoicingTemp =
  useEnabledFeatureFlag(
    FeatureFlags.ADMIN_PORTAL_AUTOMATED_INVOICING_FEATURE_FLAG_TEMP
  );
  useEffect(() => {
    getFeaturePrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFeaturePrices = () => {
    axios
      .get(`${environment.baseUrl}/employer_features/`, {
        params: { employer_id: employerId, enabled: true },
      })
      .then((res) => {
        setFeaturePrices(res.data);
      });
  };

  return flagAutomatedInvoicingTemp ? (
    <Card className="shadow">
      <CardHeader>
        <strong>Feature Prices</strong>
      </CardHeader>

      <Table responsive striped size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Unit Cost</th>
            <th>Unit Calculation Mechanism</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {featurePrices.map((featurePrice) => (
            <FeaturePrice
              featurePrice={featurePrice}
              employerId={employerId}
              getFeaturePrices={getFeaturePrices}
            />
          ))}
        </tbody>
      </Table>
    </Card>
  ) : <></>;
};

export default FeaturePricesTable;
