import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ErrorMessage, Field, Formik } from 'formik';
import Select from 'react-select';
import { environment } from '../../../../enviroment/enviroment';
import { Context } from '../../../../state/GlobalStore';
import apiResponseHandler from 'utils/apiResponseHandler';

const RegistrationModal = ({ registration, onClose, props }) => {
  const [employers, setEmployers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [tab, setTab] = useState(null);
  const { fetchStats } = useContext(Context);

  useEffect(() => {
    getEmployers();
    getEmployees();
  }, []);

  const getEmployers = () => {
    axios.get(`${environment.baseUrl}/employers/`).then((res) => {
      setEmployers(
        res.data.filter((employer) => employer.status !== 'deleted'),
      );
    });
  };

  const getEmployees = () => {
    axios
      .post(`${environment.baseUrl}/graphql/`, {
        query:
          'query{allEmployees{id,firstName,lastName,mobileNumber,idNumber,employer{companyName}}}',
      })
      .then((res) => {
        setEmployees(res.data.data.allEmployees);
      });
  };

  const customFilter = (candidate, input) => {
    const candidateString = `${candidate.data.mobileNumber} ${candidate.data.identificationNumber}`.toLowerCase();
    const candidateLabel = candidate.label.toLowerCase();

    return (
      // eslint-disable-next-line no-underscore-dangle
      candidate.data.__isNew__ ||
      candidateLabel.includes(input.toLowerCase()) ||
      candidateString.includes(input.toLowerCase())
    );
  };

  const setRegistrationStatus = async (
    id,
    status,
    status_reason = null,
    employee_id = null,
  ) => {
    return axios
      .patch(`${environment.baseUrl}/registrations/${id}/`, {
        status,
        status_reason,
        employee: employee_id,
      })
      .then((res) => fetchStats());
  };

  const RenderTabButton = ({ text, tabLabel, ...props }) => {
    return (
      <button
        className={`btn btn-sm ${tab === tabLabel && ' btn-primary'}`}
        onClick={() => setTab(tabLabel)}
      >
        {props.children}
      </button>
    );
  };

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        top: 0,
        zIndex: 10,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(100,100,100,0.4)',
      }}
    >
      <Card
        style={{
          width: '800px',
          maxHeight: '100vh',
          margin: 'auto',
          backgroundColor: 'white',
        }}
      >
        <CardHeader
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <span style={{ cursor: 'pointer' }} onClick={() => onClose(false)}>
            X
          </span>
        </CardHeader>
        <CardBody style={{ overflowY: 'scroll' }}>
          <div className="d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <strong>Name</strong>
              </div>
              <div>{registration.name}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <strong>Company</strong>
              </div>
              <div>{registration.company}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <strong>Mobile number</strong>
              </div>
              <div>{registration.mobile_number}</div>
            </div>
          </div>
          <hr className="mb-2" />
          <RenderTabButton tabLabel="create">Create employee</RenderTabButton>
          <RenderTabButton tabLabel="update">Update employee</RenderTabButton>
          <RenderTabButton tabLabel="change_status">
            Change status
          </RenderTabButton>
          <hr className="mt-2" />
          {tab === 'create' && (
            <Formik
              initialValues={{
                firstName: registration.name,
                lastName: '',
                employer: null,
                mobileNumber: registration.mobile_number,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.firstName) {
                  errors.firstName = 'Required';
                }
                if (!values.lastName) {
                  errors.lastName = 'Required';
                }
                if (!values.employer) {
                  errors.employer = 'Employer is required';
                }
                if (
                  values.mobileNumber &&
                  !/^[0-9]{11,15}$/i.test(values.mobileNumber)
                ) {
                  errors.mobileNumber = 'Invalid mobile number';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                axios
                  .post(`${environment.baseUrl}/employees/`, {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    mobile_number: values.mobileNumber,
                    id_number: values.idNumber,
                    employer: values.employer,
                    employee_no: values.employeeNo,
                    salary: values.salary,
                    pay_date: values.payDate,
                    employee_pay_cycle: values.payCycle,
                    bank_account_number: values.bankAccountNumber,
                    bank_name: values.bankName,
                    bank_branch_name: values.bankBranchName,
                    bank_branch_code: values.bankBranchCode,
                    status: values.status,
                    risk_metric: values.riskMetric,
                  })
                  .then((res) =>
                    setRegistrationStatus(
                      registration.id,
                      'created',
                      null,
                      res.data.id,
                    ),
                  )
                  .then((res) => {
                    Swal.fire({
                      title: 'Employee created successfully',
                      icon: 'success',
                      toast: true,
                      position: 'top',
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                    onClose();
                  })
                  .catch((error) => {
                    apiResponseHandler.handleApiError('Error', error);
                    setSubmitting(false);
                  });
              }}
            >
              {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <h4>Personal</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Field
                        name="firstName"
                        className="form-control"
                        placeholder="First name"
                      />
                      <ErrorMessage
                        name="firstName"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <Field
                        name="lastName"
                        className="form-control"
                        placeholder="Last name"
                      />
                      <ErrorMessage
                        name="lastName"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name="mobileNumber"
                        className="form-control"
                        placeholder="Mobile number"
                      />
                      <ErrorMessage
                        name="mobileNumber"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name="idNumber"
                        className="form-control"
                        placeholder="ID number"
                      />
                      <ErrorMessage
                        name="idNumber"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col>
                      <h4>Company</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Field
                        name="employer"
                        className="form-control"
                        placeholder="Employer"
                        as="select"
                      >
                        <option disabled selected>
                          Employer
                        </option>
                        {employers &&
                          employers.map((employer) => (
                            <option value={employer.id}>
                              {employer.company_name}
                            </option>
                          ))}
                      </Field>
                      <ErrorMessage
                        name="employer"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name="employeeNo"
                        className="form-control"
                        placeholder="Employee number"
                      />
                      <ErrorMessage
                        name="employeeNo"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col>
                      <h4 className="d-inline">Pay roll</h4>
                      <span
                        className="float-right"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (values.employer) {
                            const employer = employers.filter(
                              (emp) => emp.id.toString() === values.employer,
                            )[0];
                            setFieldValue(
                              'riskMetric',
                              employer.default_risk_metric,
                            );
                          }
                        }}
                      >
                        Set defaults from employer
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Field
                        name="salary"
                        className="form-control"
                        placeholder="Salary"
                      />
                      <ErrorMessage
                        name="salary"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <Field
                        name="payDate"
                        className="form-control"
                        placeholder="Pay date"
                      />
                      <ErrorMessage
                        name="payDate"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <Field
                        name="payCycle"
                        placeholder="Pay cycle"
                        className="form-control"
                        as="select"
                      >
                        {values.employer &&
                          employers
                            .filter((it) =>
                              Object.is(it.id.toString(), values.employer),
                            )[0]
                            ?.pay_cycles.map((it) => (
                              <option
                                key={`${it.frequency}-${it.id}`}
                                value={it.id}
                              >
                                {it.frequency}
                              </option>
                            ))}
                      </Field>
                      <ErrorMessage
                        name="payCycle"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col>
                      <h4>Bank</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Field
                        name="bankAccountNumber"
                        className="form-control"
                        placeholder="Bank account number"
                      />
                      <ErrorMessage
                        name="bankAccountNumber"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <Field
                        name="bankName"
                        className="form-control"
                        placeholder="Bank name"
                      />
                      <ErrorMessage
                        name="bankName"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name="bankBranchName"
                        className="form-control"
                        placeholder="Bank branch name"
                      />
                      <ErrorMessage
                        name="bankBranchName"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        name="bankBranchCode"
                        className="form-control"
                        placeholder="Bank branch number"
                      />
                      <ErrorMessage
                        name="bankBranchCode"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                  </Row>
                  <Row className="pt-4">
                    <Col>
                      <h4>SmartWage</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Field
                        name="status"
                        placeholder="Status"
                        className="form-control"
                        as="select"
                      >
                        <option value="unregistered">Unregistered</option>
                        <option value="registered">Registered</option>
                        <option value="suspended">Suspended</option>
                        <option value="deleted">Deleted</option>
                      </Field>
                      <ErrorMessage
                        name="status"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col>
                      <Field
                        name="riskMetric"
                        placeholder="Risk metric"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="riskMetric"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col>
                      <button
                        className="btn btn-primary float-right"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Create employee
                      </button>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          )}
          {tab === 'update' && (
            <Formik
              initialValues={{
                employee: null,
                mobileNumber: registration.mobile_number,
                currentMobileNumber: null,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.employee) {
                  errors.employee = 'Please choose an employee';
                }
                if (
                  values.mobileNumber &&
                  !/^[0-9]{11,15}$/i.test(values.mobileNumber)
                ) {
                  errors.mobileNumber = 'Invalid mobile number';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                const employeeUpdate = axios.patch(
                  `${environment.baseUrl}/employees/${values.employee}/`,
                  {
                    mobile_number: values.mobileNumber,
                  },
                );
                const registrationUpdate = setRegistrationStatus(
                  registration.id,
                  'updated',
                  null,
                  values.employee,
                );
                Promise.all([employeeUpdate, registrationUpdate]).then(() => {
                  Swal.fire({
                    title: 'Employee updated successfully',
                    icon: 'success',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  getEmployees();
                  onClose();
                });
              }}
            >
              {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <h4>Employee to update</h4>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Select
                        onChange={(obj) => {
                          setFieldValue('employee', obj.value);
                          setFieldValue(
                            'currentMobileNumber',
                            obj.mobileNumber,
                          );
                        }}
                        options={employees.map((employee) => ({
                          value: employee.id,
                          mobileNumber: employee.mobileNumber,
                          identificationNumber: employee.idNumber,
                          label: `${employee.firstName} ${employee.lastName} (${
                            employee.employer && employee.employer.companyName
                          })`,
                        }))}
                        filterOption={customFilter}
                      />
                      <ErrorMessage
                        name="employee"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <h4>Current mobile number</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            name="currentMobileNumber"
                            className="form-control"
                            placeholder="Mobile number"
                            disabled
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <h4>New mobile number</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Field
                            name="mobileNumber"
                            className="form-control"
                            placeholder="Mobile number"
                          />
                          <ErrorMessage
                            name="mobileNumber"
                            className="text-danger"
                            component="small"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <button
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          )}
          {tab === 'change_status' && (
            <Formik
              initialValues={{
                status: null,
                statusReason: null,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.statusReason) {
                  errors.statusReason = 'Please choose a reason';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setRegistrationStatus(
                  registration.id,
                  values.status,
                  values.statusReason,
                )
                  .then((res) => {
                    Swal.fire({
                      title: 'Registration status change successfully',
                      icon: 'success',
                      toast: true,
                      position: 'top',
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                    onClose();
                  })
                  .catch((error) => {
                    apiResponseHandler.handleApiError('Error', error);
                    setSubmitting(false);
                  });
              }}
            >
              {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <h4>Reject reason</h4>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Field
                        name="status"
                        className="form-control"
                        placeholder="Status reason"
                        as="select"
                      >
                        <option disabled selected>
                          Status
                        </option>
                        <option value="pending">Pending</option>
                        <option value="rejected">Rejected</option>
                      </Field>
                      <ErrorMessage
                        name="status"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                    <Col>
                      <Field
                        name="statusReason"
                        className="form-control"
                        placeholder="Status reason"
                        as="select"
                      >
                        <option disabled selected>
                          Reason
                        </option>
                        {values.status === 'rejected' ? (
                          <>
                            <option value="employer_no_smartwage">
                              Employer isn't a SmartWage client
                            </option>
                            <option value="duplicate">
                              Duplicate registration/employee
                            </option>
                          </>
                        ) : (
                          <option value="confirm_employment">
                            Confirm employment
                          </option>
                        )}
                      </Field>
                      <ErrorMessage
                        name="statusReason"
                        className="text-danger"
                        component="small"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <button
                        className="btn btn-primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default RegistrationModal;
