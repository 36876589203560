import React from 'react';
import {
  Row,
  Table,
  Col,
  Input,
  Container,
} from 'reactstrap';
import axios from 'axios';
import { Context } from '../../../state/GlobalStore';
import { environment } from '../../../enviroment/enviroment';
import moment from 'moment';

class ColdRegistrationAttempts extends React.Component {
  static contextType = Context;

  constructor() {
    super();
    this.state = {
      coldRegistrationAttempts: null,
      loading: true,
      queryParam: 'mobileNumber',
      number: null,
      searchQuery: null,
    };
  }

  componentDidMount() {
    this.getColdRegistrationAttempts();
  }

  /**
   * Get a list of all cold registration attempts
   */
  getColdRegistrationAttempts = () => {
    axios.get(`${environment.baseUrl}/coldregistrationattempt/`).then((res) => {
      this.setState({
        loading: false,
        coldRegistrationAttempts: res.data,
      });
    });
  };

  /**
   * handle the change in input and update the state
   * @param {*} event
   */
  inputChangeHandler = (event) => {
    let value = event.target.value;
    this.setState({ [event.target.name]: value });
  };

  /**
   * select which field to mark a cold registartion as safe with
   * @param {*} event
   */
  markSafeBy = (event) => {
    const { value } = event.target;
    this.setState({ queryParam: value });
  };

  onSearchQuery = () => {
    this.setState({ loading: true });
    axios
      .get(`${environment.baseUrl}/coldregistrationattempt/`, {
        params: { q: this.state.searchQuery },
      })
      .then((res) => {
        this.setState({
          loading: false,
          coldRegistrationAttempts: res.data,
        });
      });
  };

  /**
   * Update cold registration attempts by mobile_number or id_number as safe
   * @param {*} number
   * @param {*} mobileNumber
   */
  handleMarkAsSafeSubmit = (number, mobileNumber = false) => {
    const query = mobileNumber
      ? `mobile_number=${number}`
      : `id_number=${number}`;
    this.setState({ loading: true });

    axios
      .patch(
        `${environment.baseUrl}/coldregistrationattempt/markColdRegistrationAttemptsAsSafe/?${query}`,
        {
          safe: true,
        },
      )
      .then((res) => {
        this.getColdRegistrationAttempts();
      });
  };

  /**
   * Display a loader while fetching cold registration attempts
   * @returns {Component}
   */
  loadingComponent = () => {
    return (
      <Row className="my-3">
        <Col className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Col>
      </Row>
    );
  };

  render() {
    const { coldRegistrationAttempts, loading } = this.state;
    return (
      <>
        <div className="header bg-primary pb-6">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg={'6'}>
                  <h6 className="h2 text-white d-inline-block mb-0">
                    Cold Registration Attempts
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid className={'mt--5'}>
          <div className="card">
            <div className="card-header">
              <div className={'m-2'}>
                <Row>
                  <Col size={4}>
                    <Input
                      name="searchQuery"
                      type={'text'}
                      onChange={this.inputChangeHandler}
                    />
                  </Col>
                  <Col>
                    <button
                      type={'button'}
                      className={'btn btn-primary ml-2'}
                      onClick={this.onSearchQuery}
                    >
                      Search
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="card-body">
              {loading ? (
                this.loadingComponent()
              ) : (
                <Table striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Mobile Number</th>
                      <th>Id Number</th>
                      <th>Safe</th>
                      <th>Date created</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {coldRegistrationAttempts.map((coldRegistrationAttempt) => {
                      return (
                        <tr key={coldRegistrationAttempt.id}>
                          <td>{coldRegistrationAttempt.id}</td>

                          <td>{coldRegistrationAttempt.mobile_number}</td>

                          <td>{coldRegistrationAttempt.id_number}</td>

                          <td>
                            {coldRegistrationAttempt.safe ? 'True' : 'False'}
                          </td>
                          <td>
                            {moment(
                              coldRegistrationAttempt.date_created,
                            ).format('MMMM Do YYYY, h:mm:ss a')}
                          </td>
                          <td align={'right'}>
                            <button
                              className={'btn btn-primary'}
                              type={'button'}
                              onClick={() =>
                                this.handleMarkAsSafeSubmit(
                                  coldRegistrationAttempt.mobile_number,
                                  true,
                                )
                              }
                            >
                              Mark mobile number as safe
                            </button>
                            <button
                              className={'btn btn-primary'}
                              type={'button'}
                              onClick={() =>
                                this.handleMarkAsSafeSubmit(
                                  coldRegistrationAttempt.id_number,
                                  false,
                                )
                              }
                            >
                              Mark ID number as safe
                            </button>
                          </td>
                        </tr>
                      );
                    })}{' '}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default ColdRegistrationAttempts;
