import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import { environment } from '../../../../enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';

const REASONS = [
  {
    value: 1,
    label: '1. Transport',
  },
  {
    value: 2,
    label: '2. Groceries',
  },
  {
    value: 3,
    label: '3. Bills',
  },
  {
    value: 4,
    label: '4. Family/Kids',
  },
  {
    value: 5,
    label: '5. Shopping/entertainment',
  },
  {
    value: 6,
    label: '6. Emergency medical/repair expense',
  },
  {
    value: 7,
    label: '7. Other',
  },
];

const EmployeeAdvanceAdd = ({ employeeId }) => {
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeeAdvanceData, setEmployeeAdvanceData] = useState();
  const [amountRequested, setAmountRequested] = useState();
  const [status, setStatus] = useState();
  const [reason, setReason] = useState();

  useEffect(() => {
    getEmployeeAdvanceData(employeeId);
    getEmployeeData(employeeId);
  }, [employeeId]);

  const getEmployeeData = (id) => {
    setLoading(true);
    axios.get(`${environment.baseUrl}/employees/${id}`).then((res) => {
      setEmployee(res.data);
      setLoading(false);
    });
  };

  const getEmployeeAdvanceData = (employeeId) => {
    setLoading(true);
    axios
      .get(`${environment.baseUrl}/employees/${employeeId}/advances/`)
      .then((res) => {
        setEmployeeAdvanceData(res.data);
        setLoading(false);
      }).catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
      })
  };


  const createAdvance = () => {
    setIsSubmitting(true);
    axios
      .post(`${environment.baseUrl}/advancerequests/`, {
        employee: employeeId,
        amount_requested: amountRequested,
        status: status,
        reason: reason,
        source_type: 'manual',
      })
      .then((res) => {
        Swal.fire({
          title: 'Advance request saved',
          icon: 'success',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setIsSubmitting(false);
      })
      .catch((error) => {
        apiResponseHandler.handleApiError('Error', error);
        setIsSubmitting(false);
      });
  };

  return loading ? (
    <Col className="d-flex justify-content-center mt-3">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </Col>
  ) : (
    <div className={'p-3'}>
      <Row>
        <Col md="8" className="m-auto">
          <Card className="shadow">
            <CardBody>
              <Row className="mt-4">
                <Col>
                  <Row>
                    <Col>
                      <h3>
                        {employee
                          ? `${employee.first_name} ${employee.last_name}`
                          : '-'}
                      </h3>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h3>
                        R{' '}
                        {employeeAdvanceData &&
                          (employeeAdvanceData.available_advance_balance
                            ? employeeAdvanceData.available_advance_balance
                            : '-')}
                      </h3>
                      <small>Available advance balance</small>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h4>Confirm details</h4>
                      Bank account number (last 4 digits):{' '}
                      <span className="h3">
                        {employee
                          ? employee.bank_account_number.slice(
                              employee.bank_account_number.length - 4,
                              employee.bank_account_number.length,
                            )
                          : '-'}
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  {employeeAdvanceData &&
                    !employeeAdvanceData.is_advance_permitted && (
                      <div className="alert alert-warning" role="alert">
                        <strong>Warning!</strong>{' '}
                        {employeeAdvanceData.permission_reason}
                      </div>
                    )}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="shadow mt-3">
            <CardBody>
              <Row>
                <Col>
                  <span className="has-float-label">
                    <input
                      type="number"
                      name="amount_requested"
                      placeholder="Amount"
                      onChange={(event) =>
                        setAmountRequested(event.target.value)
                      }
                      className="form-control"
                      id="amount_requested"
                      required
                    />
                  </span>
                </Col>
                <Col>
                  <select
                    className="form-control"
                    name="status"
                    onChange={(event) => setStatus(event.target.value)}
                    onBlur={(event) => setStatus(event.target.value)}
                    required
                  >
                    <option value="pending" selected>
                      Pending
                    </option>
                    <option value="rejected">Rejected</option>
                    <option value="accepted">Accepted</option>
                    <option value="paid">Paid</option>
                  </select>
                </Col>
                <Col>
                  <select
                    className="form-control"
                    name="reason"
                    onChange={(event) => setReason(event.target.value)}
                    onBlur={(event) => setReason(event.target.value)}
                    required
                  >
                    {REASONS.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                    onClick={createAdvance}
                    disabled={
                      !amountRequested || !status || !reason || isSubmitting || !employeeAdvanceData.is_advance_permitted
                    }
                  >
                    Add advance
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeAdvanceAdd;
