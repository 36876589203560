import React from 'react';
import axios from 'axios';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { Formik, Field, ErrorMessage } from 'formik';
import { environment } from '../../../enviroment/enviroment';
import apiResponseHandler from 'utils/apiResponseHandler';

class EmployeeAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employers: [],
      employer: null,
      feeModel: null,
      feeModelId: null,
    };
  }

  componentDidMount() {
    this.getEmployers();
  }

  getEmployers = () => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    axios.get(`${environment.baseUrl}/employers/?`, { headers }).then((res) => {
      this.setState({
        employers: res.data.filter((employer) => employer.status === 'active'),
      });
    });
  };

  // on employer change, update pay cycle options
  handleEmployerChange = (event) => {
    this.setState({ employer: event.target.value });
  };

  render() {
    const { employers, employer, feeModel } = this.state;

    const employerObj = employers.filter(
      (e) => e.id === parseInt(employer, 10),
    )[0];

    return (
      <>
        <div className='header bg-primary pb-6'>
          <Container fluid>
            <div className='header-body'>
              <Row className='align-items-center py-4'>
                <Col lg='6'>
                  <h6 className='h2 text-white d-inline-block mb-0'>
                    Employee add
                  </h6>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container fluid className='mt--7'>
          <Row class='pt-5 emp-edit-data'>
            <Col lg='8' md='12' className='mx-auto'>
              <Card className='shadow'>
                <CardBody>
                  <Row>
                    <Col>
                      <Formik
                        initialValues={{
                          firstName: '',
                          lastName: '',
                          employer: this.state.employer,
                        }}
                        validate={(values) => {
                          const errors = {};
                          if (!values.firstName) {
                            errors.firstName = 'Required';
                          }
                          if (!values.lastName) {
                            errors.lastName = 'Required';
                          }
                          if (!values.employer) {
                            errors.employer = 'Employer is required';
                          }
                          if (
                            values.mobileNumber &&
                            !/^[0-9]{11,15}$/i.test(values.mobileNumber)
                          ) {
                            errors.mobileNumber = 'Invalid mobile number';
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          let payload = {
                            first_name: values.firstName,
                            last_name: values.lastName,
                            mobile_number: values.mobileNumber,
                            id_number: values.idNumber,
                            employer: this.state.employer,
                            employee_no: values.employeeNo,
                            salary: values.salary,
                            employee_pay_cycle: values.employeePayRollId,
                            employee_branch: values.employeeBranchId,
                            bank_account_number: values.bankAccountNumber,
                            bank_name: values.bankName,
                            bank_branch_name: values.bankBranchName,
                            bank_branch_code: values.bankBranchCode,
                            status: values.status,
                            risk_metric: values.riskMetric,
                            smartwage_status: values.smartwageStatus,
                          };

                          if (values.feeModel === 'subscription') {
                            payload['subscription'] = values.feeModelId;
                          } else if (values.feeModel === 'transactionFee') {
                            payload['transaction_fee'] = values.feeModelId;
                          }

                          axios
                            .post(`${environment.baseUrl}/employees/`, payload)
                            .then((res) => {
                              Swal.fire({
                                title: 'Employee created successfully',
                                icon: 'success',
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                              });
                              this.props.history.push(
                                `/admin/employees/${res.data.id}/`,
                              );
                            })
                            .catch((error) => {
                              apiResponseHandler.handleApiError('Error', error);
                              setSubmitting(false);
                            });
                        }}
                      >
                        {({
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                          }) => (
                          <form onSubmit={handleSubmit}>
                            <Row>
                              <Col>
                                <h4>Personal</h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} className='mb-3'>
                                <Field
                                  name='firstName'
                                  className='form-control'
                                  placeholder='First name'
                                />
                                <ErrorMessage
                                  name='firstName'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                              <Col md={6} className='mb-3'>
                                <Field
                                  name='lastName'
                                  className='form-control'
                                  placeholder='Last name'
                                />
                                <ErrorMessage
                                  name='lastName'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                              <Col md={6}>
                                <Field
                                  name='mobileNumber'
                                  className='form-control'
                                  placeholder='Mobile number'
                                />
                                <ErrorMessage
                                  name='mobileNumber'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                              <Col md={6}>
                                <Field
                                  name='idNumber'
                                  className='form-control'
                                  placeholder='ID number'
                                />
                                <ErrorMessage
                                  name='idNumber'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                            </Row>
                            <Row className='pt-4'>
                              <Col>
                                <h4>Company</h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Field
                                  name='employer'
                                  className='form-control'
                                  placeholder='Employer'
                                  as='select'
                                  onChange={(e) => {
                                    this.handleEmployerChange(e);
                                    setFieldValue('employer', e.target.value);
                                  }}
                                >
                                  <option disabled selected>
                                    Select Employer
                                  </option>
                                  {employers &&
                                  employers.map((employer) => (
                                    <option value={employer.id}>
                                      {employer.company_name}
                                    </option>
                                  ))}
                                </Field>
                                <ErrorMessage
                                  name='employer'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>

                              <Col md={6}>
                                <Field
                                  name='employeeBranchId'
                                  className='form-control'
                                  as='select'
                                >
                                  <option disabled selected>
                                    Branch
                                  </option>
                                  {!!employerObj &&
                                  employerObj.branches &&
                                  employerObj.branches.map((branch) => {
                                    return (
                                      <option
                                        value={branch.id}
                                        key={branch.id}
                                      >
                                        {branch.name}
                                      </option>
                                    );
                                  })}{' '}
                                </Field>
                                <ErrorMessage
                                  name='employee branch'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col md={6}>
                                <Field
                                  name='employeeNo'
                                  className='form-control'
                                  placeholder='Employee number'
                                />
                                <ErrorMessage
                                  name='employeeNo'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                            </Row>

                            <br />
                            <Row>
                              <Col md={6} className='mb-3'>
                                <Field
                                  name='salary'
                                  className='form-control'
                                  placeholder='Salary'
                                />
                                <ErrorMessage
                                  name='salary'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>

                              <Col md={6} className='mb-3'>
                                <Field
                                  name='employeePayRollId'
                                  placeholder='Pay roll cycle'
                                  className='form-control'
                                  as='select'
                                >
                                  <option>Select Payroll</option>
                                  {!!employerObj &&
                                  employerObj.pay_cycles &&
                                  employerObj.pay_cycles.map((payCycle) => {
                                    return (
                                      <option
                                        value={payCycle.id}
                                        key={payCycle.id}
                                      >
                                        {payCycle.name}
                                      </option>
                                    );
                                  })}{' '}
                                </Field>
                                <ErrorMessage
                                  name='payCycle'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                            </Row>
                            <Row className='pt-4'>
                              <Col>
                                <h4>Bank</h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} className='mb-3'>
                                <Field
                                  name='bankAccountNumber'
                                  className='form-control'
                                  placeholder='Bank account number'
                                />
                                <ErrorMessage
                                  name='bankAccountNumber'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                              <Col md={6} className='mb-3'>
                                <Field
                                  name='bankName'
                                  className='form-control'
                                  placeholder='Bank name'
                                />
                                <ErrorMessage
                                  name='bankName'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                              <Col md={6}>
                                <Field
                                  name='bankBranchName'
                                  className='form-control'
                                  placeholder='Bank branch name'
                                />
                                <ErrorMessage
                                  name='bankBranchName'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                              <Col md={6}>
                                <Field
                                  name='bankBranchCode'
                                  className='form-control'
                                  placeholder='Bank branch number'
                                />
                                <ErrorMessage
                                  name='bankBranchCode'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                            </Row>
                            <Row className='pt-4'>
                              <Col>
                                <h4>SmartWage</h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Field
                                  name='riskMetric'
                                  placeholder='Risk metric'
                                  className='form-control'
                                />
                                <ErrorMessage
                                  name='riskMetric'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                              <Col md={6}>
                                <Field
                                  name='smartwageStatus'
                                  placeholder='Smartwage Status'
                                  className='form-control'
                                  as='select'
                                >
                                  <option value='active'>Active</option>
                                  <option value='suspended'>Suspended</option>
                                  <option value='deleted'>Deleted</option>
                                </Field>
                                <ErrorMessage
                                  name='smartwageStatus'
                                  className='text-danger'
                                  component='small'
                                />
                              </Col>
                            </Row>
                            <Row className='pt-4'>
                              <Col>
                                <h4>Fee Model</h4>
                              </Col>
                            </Row>

                            <Row className={'mt-3'}>
                              <Col md={6}>
                                <Field
                                  name='feeModel'
                                  placeholder='Fee Model'
                                  className='form-control'
                                  as='select'
                                  onChange={(e) => {
                                    if (e.target.value === 'subscription') {
                                      this.setState({
                                        feeModel: e.target.value,
                                      });
                                      setFieldValue('feeModel', e.target.value);
                                      setFieldValue(
                                        'feeModelId',
                                        employerObj.subscriptions[0].id + '',
                                      );
                                    } else if (
                                      e.target.value === 'transactionFee'
                                    ) {
                                      this.setState({
                                        feeModel: e.target.value,
                                      });
                                      setFieldValue('feeModel', e.target.value);
                                      setFieldValue(
                                        'feeModelId',
                                        employerObj.transaction_fees[0].id + '',
                                      );
                                    } else {
                                      this.setState({
                                        feeModel: null,
                                      });
                                      setFieldValue('feeModel', null);
                                    }
                                  }}
                                >
                                  <option disabled selected>
                                    Select Fee Model
                                  </option>
                                  <option>None</option>

                                  <option
                                    value='subscription'
                                    selected={
                                      this.state.feeModel === 'subscription'
                                    }
                                  >
                                    Subscription
                                  </option>
                                  <option
                                    value='transactionFee'
                                    selected={
                                      this.state.feeModel === 'transactionFee'
                                    }
                                  >
                                    Transaction Fee
                                  </option>
                                </Field>
                              </Col>

                              <Col md={6}>
                                <Field
                                  name='feeModelId'
                                  className='form-control'
                                  as='select'
                                  onChange={(e) => {
                                    this.setState({
                                      feeModelId: e.target.value,
                                    });
                                    setFieldValue('feeModelId', e.target.value);
                                  }}
                                >
                                  {!!employerObj &&
                                  employerObj.subscriptions &&
                                  feeModel === 'subscription' &&
                                  employerObj.subscriptions.map(
                                    (subscription) => {
                                      return (
                                        <option
                                          value={subscription.id}
                                          key={subscription.id}
                                        >
                                          {subscription.name}
                                        </option>
                                      );
                                    },
                                  )}{' '}
                                  {!!employerObj &&
                                  employerObj.transaction_fees &&
                                  feeModel === 'transactionFee' &&
                                  employerObj.transaction_fees.map(
                                    (transactionFee) => {
                                      return (
                                        <option
                                          value={transactionFee.id}
                                          key={transactionFee.id}
                                        >
                                          {`(${transactionFee.id}) ${transactionFee.fee_type} - R${transactionFee.fee_amount} + ${transactionFee.fee_percentage}%`}
                                        </option>
                                      );
                                    },
                                  )}{' '}
                                </Field>
                              </Col>
                            </Row>
                            <Row className='pt-3'>
                              <Col>
                                <button
                                  className='btn btn-primary float-right'
                                  type='submit'
                                  disabled={isSubmitting}
                                >
                                  Create employee
                                </button>
                              </Col>
                            </Row>
                          </form>
                        )}
                      </Formik>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EmployeeAdd;
